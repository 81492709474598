export const sanitizeString = (input: string | null) => {
    if (input) {
        // eslint-disable-next-line no-useless-escape
        const sanitizedInput = input.replace(/[^a-zA-Z0-9áéíóúñü ]/gim, "");
        return sanitizedInput.trim();
    }
    return "";
};

export const removeWhiteSpace = (input: string | null) => {
    if (input) {
        return input.replace(/\s/g, "");
    }
    return "";
};

export const validateString = (input: string | null, fallBackString = "") => {
    return input ?? fallBackString;
};
