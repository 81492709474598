import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routePath } from "../../route-paths";
import {
    PreferencesPage,
    OrdersPage,
    SearchPage,
    ItemDetailsPage,
    ShipmentDetailsPage,
} from "@pages";

export default function AppRoutes(): JSX.Element {
    return (
        <Routes>
            <Route
                path={routePath.SHIPMENT_DETAILS}
                element={<ShipmentDetailsPage />}
            />
            <Route
                path={routePath.ITEM_DETAILS}
                element={<ItemDetailsPage />}
            />
            <Route path={routePath.ORDER_SEARCH} element={<SearchPage />} />
            <Route path={routePath.ORDERS} element={<OrdersPage />} />
            <Route path={routePath.PREFERENCES} element={<PreferencesPage />} />
            <Route
                path="*"
                element={<Navigate to={routePath.ORDERS} replace />}
            />
        </Routes>
    );
}
