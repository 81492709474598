import { RootState } from "..";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ProductLine } from "@models/product-line";
import config from "@config";
import {
    ApiOptionsServiceKey,
    PersonalizationRecord,
    PersonalizationService,
    PersonalizationServiceKey,
    ServiceLocator,
} from "@services";
import { ApplicationStatus } from "@models/data-store";
import { FetchAndReturnJson } from "@utilities/api";
import { ApiOptionsService } from "@services/api-options";

type payloadTypes = PersonalizationRecord | "[]" | undefined;

type ProductLineStatus = ApplicationStatus | "init";

export type ProductlinesSliceState = {
    applicationStatus: ApplicationStatus;
    authorizedProductLineStatus: ProductLineStatus;
    userSelectedProductLineStatus: ProductLineStatus;
    error: string | null;
    authorizedProductLineList: ProductLine[];
    userSelectedProductLineList: ProductLine[];
};

type ErrorResponse = {
    status: ProductLineStatus;
    error: string;
};

const initialState: ProductlinesSliceState = {
    applicationStatus: "loading",
    authorizedProductLineList: [],
    userSelectedProductLineList: [],
    error: null,
    authorizedProductLineStatus: "init",
    userSelectedProductLineStatus: "init",
};

function isPayloadPersonalizationRecord(
    payload: payloadTypes,
): payload is PersonalizationRecord {
    return (payload as PersonalizationRecord).key !== undefined;
}

// Obtains the Authorized Product lines of the user requesting them.
export const getAuthorizedProductLines = createAsyncThunk(
    "productlines/getProductLines",
    async () => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const apiResponse = await FetchAndReturnJson(
            config.api.productlines.url,
            options,
        );
        return apiResponse;
    },
);

// create the async thunk to obtain the personalization items contained in userProductLines for the user.
export const getUserProductLines = createAsyncThunk(
    "productlines/getUserProductLines",
    async () => {
        const personalizationService =
            ServiceLocator.get<PersonalizationService>(
                PersonalizationServiceKey,
            );
        const getUserProductLines =
            await personalizationService.getPersonalizationByKey(
                "userProductLines",
            );

        if (getUserProductLines.data === null) {
            return [];
        }

        return getUserProductLines;
    },
);

export function onAuthorizedProductLinesFulfilled(
    state: ProductlinesSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    const { payload } = action;
    if (payload.status !== undefined && payload.status >= 400) {
        const { status, error } =
            ResolveErrorResponseStatusForAuthorizedProductLines(payload.status);
        state.authorizedProductLineStatus = status;
        state.error = error;
    } else if (payload.status === 200) {
        ProcessAuthorizedProductLines(state, payload);
    } else {
        state.authorizedProductLineStatus = "error";
        state.error = "Error getting Product Lines valid for the user.";
    }
}

export function onSelectedProductLinesFullfilled(
    state: ProductlinesSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    const { payload } = action;
    if (payload.error !== undefined) {
        state.userSelectedProductLineStatus = "error";
    } else {
        const productLinesFromStorage: ProductLine[] =
            payload.data.length === 0
                ? []
                : JSON.parse(
                      isPayloadPersonalizationRecord(payload.data)
                          ? payload.data.value
                          : [],
                  );

        state.userSelectedProductLineList = productLinesFromStorage;
        state.userSelectedProductLineStatus =
            productLinesFromStorage.length === 0 ? "onboarding" : "ready";
    }
}

export function ResolveErrorResponseStatusForAuthorizedProductLines(
    status: number,
): ErrorResponse {
    if (status === 400) {
        return {
            status: "error",
            error: "A value on the request is not valid.",
        };
    }
    if (status === 401) {
        return { status: "unAuthorized", error: "You are not authorized." };
    }
    if (status === 404) {
        return { status: "error", error: "No Product Lines were found." };
    }
    return { status: "error", error: "Error getting Product Lines." };
}

export function ProcessAuthorizedProductLines(
    state: ProductlinesSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
) {
    state.authorizedProductLineList = payload.content;
    state.authorizedProductLineStatus =
        payload.content.length === 0 ? "unAuthorized" : "ready";
    state.error =
        payload.content.length === 0
            ? "You dont have productlines assigned to you."
            : "";
}

export const ProductLinesSlice = createSlice({
    name: "productlines",
    initialState,
    reducers: {
        updateUserProductLines(state, action) {
            const personalizationService =
                ServiceLocator.get<PersonalizationService>(
                    PersonalizationServiceKey,
                );
            state.userSelectedProductLineList = action.payload.data;
            state.authorizedProductLineStatus = "ready";
            personalizationService.savePersonalizationByKey(
                "userProductLines",
                JSON.stringify(action.payload.data),
            );
            state.userSelectedProductLineStatus = "ready";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAuthorizedProductLines.pending, (state) => {
            state.authorizedProductLineStatus = "loading";
            state.error = null;
        });

        builder.addCase(
            getAuthorizedProductLines.fulfilled,
            onAuthorizedProductLinesFulfilled,
        );

        builder.addCase(getAuthorizedProductLines.rejected, (state) => {
            state.authorizedProductLineStatus = "error";
            state.error = "Error getting Product Lines";
        });

        builder.addCase(getUserProductLines.pending, (state) => {
            state.userSelectedProductLineStatus = "loading";
        });

        builder.addCase(
            getUserProductLines.fulfilled,
            onSelectedProductLinesFullfilled,
        );

        builder.addCase(getUserProductLines.rejected, (state) => {
            state.userSelectedProductLineStatus = "error";
            state.error = "Error getting User Selected Product Lines";
        });
    },
});

export const { updateUserProductLines } = ProductLinesSlice.actions;

export const selectApplicationStatus = (
    state: RootState,
): ApplicationStatus => {
    if (
        state.ProductLines.authorizedProductLineStatus === "ready" &&
        state.ProductLines.userSelectedProductLineStatus === "onboarding"
    ) {
        return "onboarding";
    }

    if (
        state.ProductLines.userSelectedProductLineStatus === "ready" &&
        state.ProductLines.authorizedProductLineStatus === "ready"
    ) {
        return "ready";
    }

    if (state.ProductLines.authorizedProductLineStatus === "unAuthorized") {
        return "unAuthorized";
    }
    if (state.ProductLines.authorizedProductLineStatus === "error") {
        return "error";
    }

    if (state.ProductLines.userSelectedProductLineStatus === "error") {
        return "error";
    }

    return "loading";
};

export const selectAuthorizedProductLines = (state: RootState) =>
    state.ProductLines.authorizedProductLineList;
export const selectUserProductLines = (state: RootState) =>
    state.ProductLines.userSelectedProductLineList;
export const selectCheckedUserProductLines = (state: RootState) => {
    const selectedProducts: string[] = [];
    state.ProductLines.userSelectedProductLineList.map((item) => {
        if (item.selected === true) {
            selectedProducts.push(item.name);
        }
    });
    return selectedProducts;
};
export const selectCheckedUserProductLinesAsString = (state: RootState) => {
    const selectedProductsArray: string[] = [];
    state.ProductLines.userSelectedProductLineList.map((item) => {
        if (item.selected === true) {
            selectedProductsArray.push(item.name);
        }
    });

    const selectedProducts = selectedProductsArray.join();
    return selectedProducts === undefined ? "" : selectedProducts;
};

export default ProductLinesSlice.reducer;
