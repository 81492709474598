import React from "react";
import { Colors, Pill, Tooltip } from "@cpchem/covalence-ui";
import { DefaultStatusFilterItems } from "../pages/orders-page/data";

export function RenderPill(value: string): JSX.Element {
    const tooltipContent = DefaultStatusFilterItems.find(
        (item) => item.name === value,
    );
    return (
        <Tooltip
            content={tooltipContent?.tooltip}
            className="tooltip-ecom"
            enterDelay="medium"
        >
            {" "}
            <Pill text={value} variant="subtle" color={colorStatus(value)} />
        </Tooltip>
    );
}

function colorStatus(statusValue: string): Colors {
    switch (statusValue) {
        case "Order Received":
            return "purple";
        case "Awaiting Shipment":
            return "blue";
        case "Partially Shipped":
            return "green";
        case "Shipped":
            return "green";
        case "Invoiced":
            return "orange";
        case "Paid":
            return "mint";
        default:
            return "teal";
    }
}
