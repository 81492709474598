import { AuthManager } from "@cpchem/azure-auth";
import config from "@config";
import { ApiResponse } from "../";
import { FetchOptionMethod, FetchOptions } from "@services/fetch-interfaces";
import { PersonalizationService } from "./interface";
import { log, LogLevel } from "@cpchem/logging";
import { PersonalizationRecord } from "./personalization-record";

function handle401Response() {
    log(`You are not authorized to make this call.`, LogLevel.ERROR);
    return {
        data: null,
    };
}

export class PersonalizationServiceImplementation
    implements PersonalizationService
{
    private readonly personalizationBase = config.personalization.url;
    private readonly personalizationScopes = config.personalization.scopes;

    authMgr: AuthManager;
    constructor(authManager?: AuthManager) {
        this.authMgr = authManager ?? AuthManager.getInstance();
    }

    private async ensureFetchOptionsAsync(
        method: FetchOptionMethod,
        body?: string,
    ): Promise<FetchOptions> {
        const token = await this.authMgr.getApiToken(
            this.personalizationScopes,
        );
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");

        return {
            method,
            headers,
            body,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    private buildKeyParemeter(key: string): string {
        return key ? `?key=${encodeURIComponent(key)}` : "";
    }

    async deletePersonalizationByKey(key: string): Promise<ApiResponse<null>> {
        const uri = `${this.personalizationBase}/${encodeURIComponent(key)}`;

        const options = await this.ensureFetchOptionsAsync("DELETE");

        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: null,
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(`Personalization API not found.`, LogLevel.ERROR);
            return {
                data: null,
            };
        }

        log(
            `Unknown error when attempting to delete personalization values for ${key}. Status: ${res.statusText}`,
            LogLevel.ERROR,
        );
        return {
            error: res.statusText,
        };
    }

    async getAllPersonalization(): Promise<
        ApiResponse<PersonalizationRecord[] | null>
    > {
        const uri = `${this.personalizationBase}/`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json,
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(
                `Data not found for this combination of Application ID and User Principal Name.`,
                LogLevel.ERROR,
            );
            return {
                data: null,
            };
        }

        log(
            `Unknown error when attempting to retrieve all personalization values for this combination of Application ID and User Principal Name. Status: ${res.statusText}`,
            LogLevel.ERROR,
        );
        return {
            error: res.statusText,
        };
    }

    async getPersonalizationByKey(
        key: string,
    ): Promise<ApiResponse<PersonalizationRecord | null>> {
        const uri = `${this.personalizationBase}/${this.buildKeyParemeter(
            key,
        )}`;

        const options = await this.ensureFetchOptionsAsync("GET");

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json.length === 0 ? [] : json[0],
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(
                `Data not found for this combination of Application ID, User Principal Name, and ${key}`,
                LogLevel.ERROR,
            );
            return {
                data: null,
            };
        }

        log(
            `Unknown error when attempting to retrieve personalization values for this combination of Application ID, User Principal Name, and ${key}. Status: ${res.statusText}`,
            LogLevel.ERROR,
        );

        return {
            error: res.statusText,
        };
    }

    async savePersonalizationByKey(
        key: string,
        values: string,
    ): Promise<ApiResponse<null>> {
        const uri = `${this.personalizationBase}/${encodeURIComponent(key)}`;

        const options = await this.ensureFetchOptionsAsync("PATCH", values);

        const res = await fetch(uri, options);

        if (res.ok) {
            return {
                data: null,
            };
        }

        if (res.status === 401) {
            return handle401Response();
        }

        if (res.status === 404) {
            log(`Personalization API not found.`, LogLevel.ERROR);
            return {
                data: null,
            };
        }

        log(
            `Unknown error when attempting to save personalization values for ${key}. Status: ${res.statusText}`,
            LogLevel.ERROR,
        );
        return {
            error: res.statusText,
        };
    }
}
