import React from "react";
import { ProductLineCheckbox } from "../index";
import "./styles.scss";

export interface ProductLineCheckboxProps {
    id: string;
    tabindex: number;
    icon: string;
    label: string;
    checked: boolean;
    testId?: string;
    onChange: (id: string) => void;
}

export interface ProductLineListProps {
    productLines: ProductLineCheckboxProps[];
    testId?: string;
    onChange: (id: string) => void;
}

function ProductLineList(props: ProductLineListProps) {
    const renderedProductLines = props.productLines.map((row, index) => (
        <ProductLineCheckbox
            key={row.id}
            tabindex={index}
            checked={row.checked}
            id={row.id}
            label={row.label}
            icon={row.icon}
            onChange={() => props.onChange(row.id)}
        />
    ));

    return <div className="productline-list">{renderedProductLines}</div>;
}

export default React.memo(ProductLineList);
