import React from "react";

export type SearchCountResultTitleProps = {
    appStatus: string;
    itemCount: number;
    searchTerm: string | null;
    testId?: string;
};

export function SearchCountResultTitle({
    appStatus,
    itemCount,
    searchTerm,
    testId,
}: SearchCountResultTitleProps): JSX.Element {
    const countText = appStatus === "loading" ? "Searching" : itemCount;
    const orderText = itemCount === 1 ? "order" : "orders";
    const afterSearchText = appStatus === "loading" ? "" : " found";
    const titleTestId = testId
        ? `${testId}-search-count-result-title`
        : undefined;
    return (
        <div className="search-query-count" data-testid={titleTestId}>
            {`${countText} ${orderText}${afterSearchText} matching `}
            <b>{`"${searchTerm}"`}</b>
        </div>
    );
}
