import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition,
} from "@cpchem/covalence-ui";

const baseColumns: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Delivery #",
        accessor: "deliveryNumber",
        id: "deliveryNumber",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Route",
        accessor: "route",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Carrier",
        accessor: "carrier",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Delivery Item Qty",
        accessor: "deliveryItemQuantity",
        disableSortBy: true,
        alignment: "right",
    },
    {
        Header: "Shipping Status",
        accessor: "shippingStatus",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Shipped Planned",
        accessor: "plannedShippedOn",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Shipped On",
        accessor: "shippedOn",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Invoice #",
        accessor: "invoiceNumber",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Invoice Due Date",
        accessor: "invoiceDueDate",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Invoice Amount",
        accessor: "invoiceAmount",
        disableSortBy: true,
        alignment: "right",
    },
    {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Delivery Line Items",
        accessor: "lineItems",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Download Menu",
        accessor: "download",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Track Button",
        accessor: "track",
        disableSortBy: true,
        alignment: "left",
    },
];

export function GetShipmentDetailsItemColumnsBySize() {
    const columnsDefinition: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[] = [...baseColumns];

    return columnsDefinition;
}
