import React from "react";
import { Card } from "@cpchem/covalence-ui";

export type ItemDetailsDisplayProps = {
    poNumber: string;
    poDate: string;
    orderNumber: string;
    quoteNumber: string;
    items: number;
    deliverTo: string[];
    requestedDeliveryDate: string;
    incoterms: string;
};

export function ItemDetailsDisplay(props: ItemDetailsDisplayProps) {
    const {
        poNumber,
        poDate,
        orderNumber,
        quoteNumber,
        items,
        deliverTo,
        requestedDeliveryDate,
        incoterms,
    } = props;
    return (
        <Card className="item-details-page-bar">
            <div className="item-details-page-bar-content">
                <div className="cpo-number">
                    <span className="title">PO #</span>
                    <br />
                    <span className="content">{poNumber}</span>
                </div>
                <div className="po-date">
                    <span className="title">PO Date</span>
                    <br />
                    <span className="content">{poDate}</span>
                </div>
                <div className="order-number">
                    <span className="title">Order #</span>
                    <br />
                    <span className="content">{orderNumber}</span>
                </div>
                <div className="quote-number">
                    <span className="title">Quote #</span>
                    <br />
                    <span className="content">{quoteNumber}</span>
                </div>
                <div className="items">
                    <span className="title">Items</span>
                    <br />
                    <span className="content">{items}</span>
                </div>
                <div className="deliver-to">
                    <span className="title">Deliver to</span>
                    <br />
                    <span className="content">{deliverTo[0]}</span>
                    <br />
                    <span style={{ fontSize: ".8em", color: "gray" }}>
                        {deliverTo[1]}
                    </span>
                </div>
                <div className="requested-delivery-date">
                    <span className="title">Requested Delivery Date</span>
                    <br />
                    <span className="content">{requestedDeliveryDate}</span>
                </div>
                <div className="incoterms">
                    <span className="title">Incoterms</span>
                    <br />
                    <span className="content">{incoterms}</span>
                </div>
            </div>
        </Card>
    );
}
