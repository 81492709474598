import React, { useEffect, useState } from "react";
import { Button, Modal, ModalContent } from "@cpchem/covalence-ui";
import {
    ShipmentTracking,
    ShipmentTrackingHistoryItem,
    ShipmentTrackingItem,
} from "@models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    getShipmentTrackingDetailsContent,
    getShipmentTrackingModalStatus,
    getShipmentTrackingHistoryContent,
    requestShipmentTrackingDetails,
    requestShipmentTrackingHistoryDetails,
    getShipmentTrackingModalError,
} from "@stores/shipment-tracking";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import {
    TransformShipmentTrackingDetailsRaw,
    TransformShipmentTrackingHistoryRaw,
} from "@utilities/data/shipment-tracking-mapping";
import ShipmentDeliveryButton from "@components/display/shipment-details/shipping-details-button/shipment-tracking-button";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { createPageViewEvent } from "@cpchem/logging";
import { SHIPMENT_TRACKING_DIALOG_LOADING } from "@utilities/log/log-events";
import { LoadingDisplay } from "@components/loading";

export type ShipmentTrackingModalDisplayProps = {
    shipmentTrackingItem: ShipmentTrackingItem;
    testId?: string;
};

export function ShipmentTrackingModalDisplay({
    shipmentTrackingItem,
    testId,
}: ShipmentTrackingModalDisplayProps): JSX.Element {
    const dispatch = useAppDispatch();
    const faPropAngleDownIcon = faAngleDown as IconProp;
    const faCircleExclamationIcon = faCircleExclamation as IconProp;
    const shipmentTrackingDetailRaw = useAppSelector(
        getShipmentTrackingDetailsContent,
    );
    const shipmentHistoryDetailsRaw = useAppSelector(
        getShipmentTrackingHistoryContent,
    );
    const shipmentTrackingStatus = useAppSelector(
        getShipmentTrackingModalStatus,
    );
    const shipmentTrackingError = useAppSelector(getShipmentTrackingModalError);

    const shipmentTrackingDetail = TransformShipmentTrackingDetailsRaw(
        shipmentTrackingDetailRaw,
    );
    const shipmentTrackingHistory = TransformShipmentTrackingHistoryRaw(
        shipmentHistoryDetailsRaw,
    );
    const [showShippingHistory, setShowShippingHistory] = useState(false);

    const searchNumber = shipmentTrackingItem.deliveryNumber ?? 0;

    useEffect(() => {
        if (searchNumber !== 0) {
            ecomTrackEvent({
                type: SHIPMENT_TRACKING_DIALOG_LOADING,
                contents: JSON.stringify({ searchNumber }),
            });
            dispatch(requestShipmentTrackingDetails(searchNumber));
            dispatch(requestShipmentTrackingHistoryDetails(searchNumber));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchNumber, dispatch]);

    function SwitchShowShippingHistory() {
        setShowShippingHistory(!showShippingHistory);
    }

    function BuildMainDeliveryTitleInfo(
        trackingInfo: ShipmentTracking | null,
    ): JSX.Element {
        if (trackingInfo !== null) {
            const { actualDelivery, estimatedDelivery, status } = trackingInfo;
            let infoTitle = "";
            let infoDateTime = "";
            if (status === "Not Tracking") {
                infoTitle = "Estimated Delivery";
            } else {
                if (actualDelivery.trim() !== "") {
                    infoTitle = "Delivered on";
                    infoDateTime = actualDelivery;
                } else {
                    infoTitle = "Estimated delivery";
                    infoDateTime = estimatedDelivery;
                }
            }
            return (
                <>
                    <span className="title text-block space-bottom-small">
                        {infoTitle}
                    </span>
                    {status === "Not Tracking" ? (
                        <div className="banner-warning">
                            <FontAwesomeIcon
                                className="icon icon-size"
                                icon={faCircleExclamationIcon}
                            />
                            <span className="padding-left-medium">
                                Tracking information is unavailable
                            </span>
                        </div>
                    ) : (
                        <div className="bold xl">{infoDateTime}</div>
                    )}
                    <br />
                    <div className="space-bottom-medium">
                        <span className="title">Deliver to </span>
                        <span className="bold">{`${
                            shipmentTrackingDetail?.destination.city ?? ""
                        }, ${
                            shipmentTrackingDetail?.destination.state ?? ""
                        }`}</span>
                    </div>
                    <span className="title">Shipped with</span>
                    <span className="bold">{` ${
                        shipmentTrackingDetail?.carrier ?? ""
                    }`}</span>
                    <br />
                    <ShipmentDeliveryButton
                        shipmentTracking={shipmentTrackingDetail}
                        testId={testId}
                    />
                </>
            );
        }
        return <></>;
    }

    function BuildShippingHistoryItem(
        historyItem: ShipmentTrackingHistoryItem,
    ): JSX.Element {
        return (
            <div className="space-bottom-medium">
                <span className="title">{historyItem.date}</span>
                <br />
                <span>{`${historyItem.time} | `}</span>
                <span className="bold">{`${historyItem.location}`}</span>
            </div>
        );
    }

    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("Loading Shipment Tracking dialog"));
    });

    return (
        <Modal
            className={`shipment-tracking-modal ${
                shipmentTrackingStatus === "loading"
                    ? "height-large"
                    : "height-small"
            } `}
            isOpen={shipmentTrackingItem.isOpen}
            onRequestClose={() =>
                shipmentTrackingItem.closeShipmentTrackingModal()
            }
            title={`Shipment Details of Delivery # ${shipmentTrackingItem.deliveryNumber}`}
            data-testid={testId}
        >
            <ModalContent>
                <LoadingDisplay
                    className="tracking-container"
                    showLoading={shipmentTrackingStatus === "loading"}
                    hideContentWhileLoading
                >
                    {shipmentTrackingStatus === "not found" ||
                    shipmentTrackingStatus === "error" ? (
                        <div>
                            <div
                                className="bold medium space-bottom-medium-half"
                                data-testid={`${testId}-error-message`}
                            >
                                {shipmentTrackingError}
                            </div>
                        </div>
                    ) : (
                        <>
                            {BuildMainDeliveryTitleInfo(shipmentTrackingDetail)}

                            {shipmentTrackingDetail?.status === "Completed" ||
                            shipmentTrackingDetail?.status ===
                                "Not Tracking" ? (
                                <></>
                            ) : (
                                <>
                                    <br />
                                    <br />
                                    <div className="sub-header">
                                        <span className="header-title bold">{`${
                                            showShippingHistory
                                                ? "Shipping History"
                                                : "Latest Update"
                                        }`}</span>
                                        <Button
                                            className="header-button"
                                            variant="text"
                                            text={`${
                                                showShippingHistory
                                                    ? "Hide"
                                                    : "Show"
                                            } Full Shipping History`}
                                            icon={
                                                <FontAwesomeIcon
                                                    className="icon icon-size"
                                                    icon={faPropAngleDownIcon}
                                                />
                                            }
                                            onClick={() =>
                                                SwitchShowShippingHistory()
                                            }
                                        />
                                    </div>
                                    <div className="shippingHistoryContainer">
                                        {showShippingHistory ? (
                                            shipmentTrackingHistory.map(
                                                (historyItem) => {
                                                    return BuildShippingHistoryItem(
                                                        historyItem,
                                                    );
                                                },
                                            )
                                        ) : (
                                            <div>
                                                <span className="title">
                                                    {shipmentTrackingDetail
                                                        ?.latestTimeStamp
                                                        .date ?? ""}
                                                </span>
                                                <br />
                                                <span>
                                                    {`${
                                                        shipmentTrackingDetail
                                                            ?.latestTimeStamp
                                                            .time ?? ""
                                                    } |`}
                                                </span>
                                                <span className="bold">
                                                    {` ${
                                                        shipmentTrackingDetail
                                                            ?.latestLocation
                                                            .city ?? ""
                                                    }, ${
                                                        shipmentTrackingDetail
                                                            ?.latestLocation
                                                            .state ?? ""
                                                    } `}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </LoadingDisplay>
            </ModalContent>
        </Modal>
    );
}
