import { DateRange } from "@models/date-range";

export function getLastMonth(): DateRange {
    const currentDate = new Date();

    const lastMonthDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0,
    );

    const lastMonth = `0${lastMonthDate.getMonth() + 1}`;

    return {
        startDate: `${lastMonthDate.getFullYear()}-${lastMonth.slice(-2)}-01`,
        endDate: `${lastMonthDate.getFullYear()}-${lastMonth.slice(
            -2,
        )}-${lastMonthDate.getDate()}`,
    };
}
