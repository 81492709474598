import React, { useEffect, useState } from "react";
import {
    updateUserProductLines,
    selectAuthorizedProductLines,
} from "@stores/product-lines";
import { ProductLineCheckbox } from "@components/products";
import { Button } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { OnboardingHeroBanner } from "@components/banners";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import "./styles.scss";
import { GetProductSelections } from "@utilities/pages/product-lines";
import { createPageViewEvent } from "@cpchem/logging";
import { FINISH_USER_ONBOARDING } from "@utilities/log/log-events";
import { OnboardingElements } from "./elements";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";

export function OnboardingPage(): JSX.Element {
    document.title = "Select Product Lines - CustomerConnect";
    const backgroundImageClassName = "pasadena";
    const dispatch = useAppDispatch();
    const productLinesList = useAppSelector(selectAuthorizedProductLines);
    const [productLines, setProductLines] = useState(productLinesList);

    function updateUserProductSelection() {
        const productLinesSelected = GetProductSelections(productLines);
        ecomTrackEvent({
            type: FINISH_USER_ONBOARDING,
            contents: JSON.stringify({
                productLinesSelected,
            }),
        });
        dispatch(
            updateUserProductLines({
                data: GetProductSelections(productLines),
            }),
        );
    }

    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("Onboarding Page"));
    }, []);

    const productSelectionChanged = (id: string) => {
        setProductLines(
            productLines.map((product) => {
                if (product.id === id) {
                    return { ...product, selected: !product.selected };
                }
                return product;
            }),
        );
    };
    const productLineSelected = productLines.some(
        (item) => item.selected === true,
    );

    const renderedProductLines = productLines.map((row, index) => (
        <ProductLineCheckbox
            key={row.id}
            tabindex={index}
            checked={row.selected}
            id={row.id}
            label={row.description}
            icon={row.iconName}
            testId={`${OnboardingElements.ProductCardPrepend}${row.id}`}
            onChange={productSelectionChanged}
        />
    ));

    return (
        <div className="onboarding-page" data-testid="onboarding-page-testId">
            <OnboardingHeroBanner
                backgroundClassName={backgroundImageClassName}
            />
            <div className="onboarding-content">
                <p className="headline">
                    Select the Product Lines that apply to you
                </p>
                <div className="productlines">{renderedProductLines}</div>
            </div>
            <Button
                isDisabled={!productLineSelected}
                className="selection-button"
                text="Personalize My Order Results List"
                isIconAfterText
                icon={<FontAwesomeIcon icon={faArrowRight} />}
                color="primary"
                size="large"
                onClick={() => updateUserProductSelection()}
                testId={OnboardingElements.PersonalizeOrdersButton}
            />
            <br />
        </div>
    );
}
