import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition,
} from "@cpchem/covalence-ui";

const baseColumns: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Items",
        accessor: "items",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Item Qty",
        accessor: "itemQuantity",
        disableSortBy: true,
        alignment: "right",
    },
    {
        Header: "Total Confirmed Qty",
        accessor: "totalConfirmedQuantity",
        disableSortBy: true,
        alignment: "right",
    },
    {
        Header: "Est. Ship Date",
        accessor: "estimatedShipmentDate",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Ship From",
        accessor: "shipFrom",
        disableSortBy: true,
        alignment: "left",
    },
    {
        Header: "Shipments",
        accessor: "shipments",
        id: "shipments",
        disableSortBy: true,
        alignment: "right",
    },
    {
        Header: "Schedule Lines",
        accessor: "scheduleLines",
        id: "scheduleLines",
        disableSortBy: true,
        alignment: "right",
    },
    {
        Header: "Total Left Quantity",
        accessor: "totalLeftQuantity",
        id: "totalLeftQuantity",
        disableSortBy: true,
        alignment: "right",
    },
];

export function GetItemDetailsColumnsBySize() {
    const columnsDefinition: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[] = [...baseColumns];

    return columnsDefinition;
}
