import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./styles.scss";
import { ShipmentTracking } from "@models/shipment-tracking-detail";

type ShipmentDeliveryButtonProps = {
    shipmentTracking: ShipmentTracking | null;
    testId?: string;
};

export const Elements = {
    ShippingDetailsButton_Title: "tracking-button",
};

export default function ShipmentDeliveryButton({
    shipmentTracking,
    testId,
}: ShipmentDeliveryButtonProps): JSX.Element {
    const iconProp = faUpRightFromSquare as IconProp;
    const _testId = testId ? `${testId}-tracking-button` : undefined;
    const _url = shipmentTracking?.trackingUrl ?? null;
    return (
        <>
            {_url && (
                <div className="delivery-tracking-button" data-testid={_testId}>
                    <a href={_url} target="_blank" rel="noreferrer">
                        View More Tracking Information
                    </a>
                    <FontAwesomeIcon
                        className="icon icon-size"
                        icon={iconProp}
                    />
                </div>
            )}
        </>
    );
}
