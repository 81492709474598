import React from "react";
import {
    getNextSortDirectionByDataType,
    sortValue,
} from "@utilities/pages/order-history";
import {
    DataGridColumnDefinition,
    SortingIcon,
    AriaSortType,
} from "@cpchem/covalence-ui";
import {
    AriaForTypes,
    DataGridColumnSortState,
    ecomColumnDetailList,
} from "./constants";

export type HeaderCellContentProps = {
    header: DataGridColumnDefinition | undefined;
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void;
    currentSortValue: sortValue;
    testId?: string;
};

export function HeaderCellContent({
    header,
    onSelectSortValue,
    currentSortValue,
    testId,
}: HeaderCellContentProps): JSX.Element {
    let ariaType: AriaSortType | undefined = undefined;
    const canSort = !header?.disableSortBy;
    const columnId = header?.id ?? "";
    const { sortBy, textAlign } = ecomColumnDetailList[columnId];
    const buttonStyle = canSort ? "is-button" : "";
    const role = "columnheader";
    const sortValueType =
        header?.sortType === "datetime" ? "datetime" : "string";
    const columnSortDirection =
        canSort && currentSortValue.column === sortBy
            ? currentSortValue.sort
            : DataGridColumnSortState.WAIT;
    const nextSortValue = getNextSortDirectionByDataType(
        sortValueType,
        columnSortDirection,
    );
    const title = `Sort ${
        nextSortValue === DataGridColumnSortState.WAIT
            ? "reset"
            : nextSortValue.toString()
    }`;
    const sortFunction = canSort
        ? () =>
              onSelectSortValue(
                  {
                      column: `${sortBy}`,
                      sort: DataGridColumnSortState.WAIT,
                      type: sortValueType,
                  },
                  currentSortValue,
              )
        : undefined;
    if (canSort) {
        ariaType = AriaForTypes[columnSortDirection];
    }
    const headerTestId = testId ? `${testId}-header` : undefined;
    return (
        <th
            title={title}
            aria-sort={ariaType}
            key={columnId}
            role={role}
            data-testid={headerTestId}
            onClick={sortFunction}
            className={`text-align-${textAlign} ${buttonStyle}`}
        >
            <div className={`header-cell justify-${textAlign}`}>
                {`${header?.Header}`}{" "}
                {canSort ? (
                    <SortingIcon sort={columnSortDirection} testId={testId} />
                ) : (
                    <></>
                )}
            </div>
        </th>
    );
}
