import React, { useState } from "react";
import {
    DataGrid,
    DataGridColumnDefinition,
    DataGridProps,
    DataGridRow,
    FilteringDataGridColumnDefinition,
} from "@cpchem/covalence-ui";
import {
    DeliveryItems,
    DeliveryLineItem,
    OrderLineItem,
} from "@models/shipment-details";
import { GetShipmentDetailsItemColumnsBySize } from "./content/shipment-details-columns";
import { HeaderRendererFull } from "./renderer/header";
import { ResultRowFull } from "./renderer/row";
import { ShipmentDeliveryModalDisplay } from "./shipment-delivery-modal";
import { ShipmentTrackingModalDisplay } from "./shipment-tracking-modal";
import { ShipmentTrackingItem } from "@models";

type DisplaySizeOptions = {
    currentColumns: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[];
    currentHeaderRenderer: () => JSX.Element;
    currentRowRenderer: (row: DataGridRow) => JSX.Element;
};

interface ShipmentDetailsGridDisplayProps {
    shipmentDetails: OrderLineItem;
    testId?: string;
}

export function ShipmentDetailsGridDisplay({
    shipmentDetails,
    testId,
}: ShipmentDetailsGridDisplayProps): JSX.Element {
    const { deliveryList } = shipmentDetails;
    const [deliveryModalData, setDeliveryModalData] = useState<DeliveryItems>({
        deliveryNumber: 0,
        deliveryData: [],
        modalOpen: false,
        closeDeliveryModal: () => null,
    });
    const [shipmentTrackingModalData, setShipmentTrackingModalData] =
        useState<ShipmentTrackingItem>({
            deliveryNumber: 0,
            isOpen: false,
            closeShipmentTrackingModal: () => null,
        });

    function openDeliveryLineItemsModal(
        deliveryNumber: number,
        deliveryData: DeliveryLineItem[],
    ) {
        setDeliveryModalData({
            deliveryNumber: deliveryNumber,
            deliveryData: deliveryData,
            modalOpen: true,
            closeDeliveryModal: () => {
                setDeliveryModalData({
                    deliveryData: deliveryData,
                    deliveryNumber: deliveryNumber,
                    modalOpen: false,
                    closeDeliveryModal: () => null,
                });
            },
        });
    }

    function openShipmentTrackingModal(deliveryNumber: number) {
        setShipmentTrackingModalData({
            deliveryNumber: deliveryNumber,
            isOpen: true,
            closeShipmentTrackingModal: () => {
                setShipmentTrackingModalData({
                    deliveryNumber: deliveryNumber,
                    isOpen: false,
                    closeShipmentTrackingModal: () => null,
                });
            },
        });
    }

    const ResultRowRenderer = (row: DataGridRow) => {
        return ResultRowFull(
            row,
            openDeliveryLineItemsModal,
            openShipmentTrackingModal,
        );
    };

    const displaySizeOptions: DisplaySizeOptions = {
        currentColumns: GetShipmentDetailsItemColumnsBySize(),
        currentHeaderRenderer: HeaderRendererFull,
        currentRowRenderer: ResultRowRenderer,
    };

    const gridProps: DataGridProps = {
        data: deliveryList,
        columns: displaySizeOptions.currentColumns,
        useStickyHeaders: true,
        headerRenderer: displaySizeOptions.currentHeaderRenderer,
        rowRenderer: displaySizeOptions.currentRowRenderer,
        isSortable: true,
    };

    return (
        <div className="line-items-grid" data-testid={testId}>
            <DataGrid {...gridProps} />
            <ShipmentDeliveryModalDisplay deliveryItems={deliveryModalData} />
            <ShipmentTrackingModalDisplay
                shipmentTrackingItem={shipmentTrackingModalData}
            />
        </div>
    );
}
