import config from "@config";
import { ShipmentTrackingHistoryItemsRaw, ShipmentTrackingRaw } from "@models";
import { ApplicationStatus } from "@models/data-store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiOptionsServiceKey, ServiceLocator } from "@services";
import { ApiOptionsService } from "@services/api-options";
import { RootState } from "@stores";

export type ShipmentTrackingAppStatus = ApplicationStatus | "not found";

export type ShipmentTrackingDetailsSliceState = {
    detailStatus: ShipmentTrackingAppStatus;
    historyStatus: ShipmentTrackingAppStatus;
    error: string | null;
    detailsContent: ShipmentTrackingRaw | null;
    historyContent: ShipmentTrackingHistoryItemsRaw | null;
};

const initialState: ShipmentTrackingDetailsSliceState = {
    detailsContent: null,
    historyContent: null,
    error: null,
    detailStatus: "ready",
    historyStatus: "ready",
};

type payloadTypes =
    | ShipmentTrackingRaw
    | ShipmentTrackingHistoryItemsRaw
    | undefined;

function isPayloadShipmentTrackingItemRaw(
    payload: payloadTypes,
): payload is ShipmentTrackingRaw {
    return (payload as ShipmentTrackingRaw).deliveryNumber !== undefined;
}

export const requestShipmentTrackingDetails = createAsyncThunk(
    "orderHistory/itemDetails/shipmentTrackingDetails",
    async (deliveryNumber: number) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const url = `${config.api.shipments.url}/${deliveryNumber}`;

        const res = await fetch(url, options);
        return res.json();
    },
);

export const requestShipmentTrackingHistoryDetails = createAsyncThunk(
    "orderHistory/itemDetails/shipmentTrackingHistoryDetails",
    async (deliveryNumber: number) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const url = `${config.api.shipments.url}/${deliveryNumber}/locations`;

        const res = await fetch(url, options);
        return res.json();
    },
);

export function onDetailsFulfilled(
    state: ShipmentTrackingDetailsSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    const { payload } = action;
    if (
        (payload.statusCode !== undefined && payload.statusCode === 404) ||
        (payload.status !== undefined && payload.status === 404)
    ) {
        state.detailStatus = "not found";
        state.error = `No information found with this delivery number.`;
    } else if (payload.statusCode === 407) {
        state.detailStatus = "error";
        state.error = `Something is temporarily wrong with your network connection. Please check your network and try again later.`;
    } else if (payload.statusCode === 422) {
        state.detailStatus = "error";
        state.error = `System is overloaded. Please try again later.`;
    } else if (payload.statusCode >= 400 && payload.statusCode <= 503) {
        state.detailStatus = "error";
        state.error = `Something went wrong. An error occurred while loading the shipment data.`;
    } else if (isPayloadShipmentTrackingItemRaw(payload)) {
        state.detailsContent = payload;
        state.detailStatus = "ready";
    } else {
        state.detailStatus = "error";
        state.error = `Error getting Shipment Tracking Details. Error code: ${payload.statusCode}`;
    }
}

export function onShippingHistoryFulfilled(
    state: ShipmentTrackingDetailsSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    const { payload } = action;
    if (payload.statusCode !== undefined && payload.statusCode === 404) {
        state.historyStatus = "not found";
        state.error = `No information found with this delivery number.`;
    } else if (payload.statusCode === 407) {
        state.historyStatus = "error";
        state.error = `Something is temporarily wrong with your network connection. Please check your network and try again later.`;
    } else if (payload.statusCode === 422) {
        state.historyStatus = "error";
        state.error = `System is overloaded. Please try again later.`;
    } else if (payload.statusCode >= 400 && payload.statusCode <= 503) {
        state.historyStatus = "error";
        state.error = `Something went wrong. An error occurred while loading the shipment data.`;
    } else {
        state.historyContent = payload;
        state.historyStatus = "ready";
    }
}

export const ShipmentTrackingDetailsSlice = createSlice({
    name: "shipmentTrackingDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(requestShipmentTrackingDetails.pending, (state) => {
            (state.detailStatus = "loading"), (state.error = null);
            state.detailsContent = null;
        });
        builder.addCase(
            requestShipmentTrackingDetails.fulfilled,
            onDetailsFulfilled,
        );
        builder.addCase(requestShipmentTrackingDetails.rejected, (state) => {
            state.detailStatus = "error";
            state.error = "Error getting Shipment Tracking Details";
        });
        builder.addCase(
            requestShipmentTrackingHistoryDetails.pending,
            (state) => {
                (state.historyStatus = "loading"), (state.error = null);
                state.historyContent = null;
            },
        );
        builder.addCase(
            requestShipmentTrackingHistoryDetails.fulfilled,
            onShippingHistoryFulfilled,
        );
        builder.addCase(
            requestShipmentTrackingHistoryDetails.rejected,
            (state) => {
                state.historyStatus = "error";
                state.error = "Error getting Shipment Tracking History.";
            },
        );
    },
});

export const getShipmentTrackingModalStatus = (
    state: RootState,
): ShipmentTrackingAppStatus => {
    if (
        state.ShipmentTrackingDetails.detailStatus === "ready" &&
        state.ShipmentTrackingDetails.historyStatus === "ready"
    ) {
        return "ready";
    }

    if (state.ShipmentTrackingDetails.detailStatus === "not found")
        return "not found";

    if (
        state.ShipmentTrackingDetails.detailStatus === "error" ||
        state.ShipmentTrackingDetails.historyStatus === "error"
    ) {
        return "error";
    }

    return "loading";
};

export const getShipmentTrackingModalError = (
    state: RootState,
): string | null => {
    return state.ShipmentTrackingDetails.error;
};

export const getShipmentTrackingDetailsContent = (state: RootState) =>
    state.ShipmentTrackingDetails.detailsContent;
export const getShipmentTrackingHistoryContent = (state: RootState) =>
    state.ShipmentTrackingDetails.historyContent;

export default ShipmentTrackingDetailsSlice.reducer;
