import {
    DataGridColumnSortState,
    DirectionForTypes,
} from "@components/display/orders/content/constants";
import config from "@config";
import { DateRange } from "@models";
import { APIQueryOptions, APISearchQueryOptions } from "@models/orders";
import {
    buildDateFilterQuery,
    buildFilterListQuery,
    buildOrderProductLineStringArray,
    buildOrderLineItemQuery,
} from "@utilities/api";

interface SortTypes {
    [sortType: string]: sortSequenceValues;
}

export type sortValue = {
    column: string;
    sort: DataGridColumnSortState;
    type: sortValueType;
};

export type sortSequenceValues = {
    fromAsc: DataGridColumnSortState;
    fromDesc: DataGridColumnSortState;
    fromNone: DataGridColumnSortState;
};

export type selectedColumn = {
    column: string;
    type: sortValueType;
};

export type sortValueType = "string" | "datetime";
export type sortDirection = "ASC" | "DESC" | null;

const sortValuesSequenceByType: SortTypes = {
    datetime: {
        fromAsc: DataGridColumnSortState.WAIT,
        fromDesc: DataGridColumnSortState.ASC,
        fromNone: DataGridColumnSortState.DESC,
    },
    string: {
        fromAsc: DataGridColumnSortState.DESC,
        fromDesc: DataGridColumnSortState.WAIT,
        fromNone: DataGridColumnSortState.ASC,
    },
};

export function buildOrderHistoryRequestURL(
    queryOptions: APIQueryOptions,
): string {
    const baseURLRequest = `${config.api.orderHistory.url}?$count=true`;
    const pageLocationString = `&$skip=${
        (queryOptions.pageLocation - 1) * queryOptions.pageSize
    }`;
    const pageSizeQueryString = `&$top=${queryOptions.pageSize}`;
    const filterOptionsString = buildFilterOptionsString(queryOptions);
    const sortByString = getSortString(
        queryOptions.sortValue.column,
        queryOptions.sortValue.sort,
    );

    const url = `${baseURLRequest}${pageLocationString}${sortByString}${pageSizeQueryString}${filterOptionsString}`;
    return url;
}

export function buildOrderHistorySearchRequestURL(
    querySearchOptions: APISearchQueryOptions,
): string {
    const { userProductLines, pageLocation, pageSize, sortValue } =
        querySearchOptions;
    const baseURLRequest = `${config.api.orderSearch.url}?$count=true`;
    const pageLocationString = `&$skip=${(pageLocation - 1) * pageSize}`;
    const pageSizeQueryString = `&$top=${pageSize}`;
    const sortByString = getSortString(sortValue.column, sortValue.sort);
    const productLinesFilterString =
        getProductLinesFilterString(userProductLines);

    const url = `${baseURLRequest}${pageLocationString}${pageSizeQueryString}${sortByString}&$filter=${productLinesFilterString} contains(searchValue, '${querySearchOptions.searchValue}' )`;
    return url;
}

export function buildFilterOptionsString(
    queryOptions: APIQueryOptions,
): string {
    const { dateSelection, userProductLines } = queryOptions;
    const orderCreationDateQueryString =
        getOrderCreationDateQueryString(dateSelection);
    const filtersQueryString = getFiltersQueryString(queryOptions);
    const productLinesFilterString =
        getProductLinesFilterString(userProductLines);
    return `&$filter=${productLinesFilterString}${filtersQueryString}${orderCreationDateQueryString}`;
}

export function getOrderCreationDateQueryString(dateSelection: DateRange) {
    const defaultColumnDate = `orderCreationDate`;
    return `${defaultColumnDate} ge ${dateSelection.startDate} and ${defaultColumnDate} le ${dateSelection.endDate}`;
}

export function getProductLinesFilterString(
    userProductLines: string[],
): string {
    const productLines = buildOrderProductLineStringArray(userProductLines);
    const salesOrganizationValues =
        productLines.length > 0
            ? `salesOrganization in (${productLines.join(",")}) and `
            : "";

    return salesOrganizationValues;
}

export function getFiltersQueryString({
    employeeFilters,
    customerFilters,
    shipFromFilters,
    locationFilters,
    orderStatusFilters,
    productDescriptionFilters,
    poDateFilter,
    requestedDeliveryDateFilter,
}: APIQueryOptions): string {
    const employeeFiltersString = buildFilterListQuery(
        "employeeResponsible",
        employeeFilters,
    );
    const customerFiltersString = buildFilterListQuery(
        "soldToPartyName",
        customerFilters,
    );
    const shipFromFiltersString = buildOrderLineItemQuery(
        "shipFrom",
        shipFromFilters,
    );
    const locationFiltersString = buildFilterListQuery(
        "deliveryLocation",
        locationFilters,
    );
    const statusFiltersString = buildFilterListQuery(
        "status",
        orderStatusFilters,
    );
    const productDescriptionFiltersString = buildOrderLineItemQuery(
        "productDescription",
        productDescriptionFilters,
    );
    const poDateFilterString = buildDateFilterQuery(
        "customerPurchaseOrderDate",
        poDateFilter,
    );
    const requestDateFilterString = buildDateFilterQuery(
        "requestedShipDate",
        requestedDeliveryDateFilter,
    );

    return `${employeeFiltersString}${customerFiltersString}${shipFromFiltersString}${locationFiltersString}${statusFiltersString}${productDescriptionFiltersString}${poDateFilterString}${requestDateFilterString}`;
}

export function getSortString(
    column: string,
    sort: DataGridColumnSortState,
): string {
    return sort !== DataGridColumnSortState.WAIT
        ? `&$orderby=${column} ${DirectionForTypes[sort]}, SalesDocumentNumber ${DirectionForTypes[sort]}`
        : "&$orderby=SalesDocumentNumber DESC";
}

export function getNextSortValues(
    currentSortValues: sortValue,
    selectedSortColumn: sortValue,
): sortValue {
    const { column, sort } = currentSortValues;
    const isSameValue = column === selectedSortColumn.column;
    const nextSortDirection = getNextSortDirectionByDataType(
        selectedSortColumn.type,
        isSameValue ? sort : DataGridColumnSortState.WAIT,
    );
    return {
        column: selectedSortColumn.column,
        sort: nextSortDirection,
        type: selectedSortColumn.type,
    };
}

export function getNextSortDirectionByDataType(
    type: sortValueType,
    currentSortDirection: DataGridColumnSortState,
): DataGridColumnSortState {
    const typeSortSequence = sortValuesSequenceByType[type];
    if (currentSortDirection === DataGridColumnSortState.ASC) {
        return typeSortSequence.fromAsc;
    }
    if (currentSortDirection === DataGridColumnSortState.DESC) {
        return typeSortSequence.fromDesc;
    }
    return typeSortSequence.fromNone;
}
