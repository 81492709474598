import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContext } from "./context";
import { Toast, ToastId, ToastMessage, ToastOptions } from "./model";
import { ToastComponent } from "./toast.component";
import { Toast_Elements } from "./elements";
import { ToastEngine } from "./toast-engine";
import "./provider-styles.scss";

export interface ToastProviderProps {
    children?: React.ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps): JSX.Element {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const onUpdateToasts = useCallback((toasts: Toast[]) => {
        setToasts(toasts);
    }, []);

    const engine = useRef<ToastEngine>(
        new ToastEngine({
            onUpdateToasts,
        }),
    );

    const handleCreateToast = useCallback(
        (message: ToastMessage, options?: ToastOptions) => {
            engine.current.createToast(message, options);
        },
        [],
    );

    const handleRemoveToast = useCallback((id: ToastId) => {
        engine.current.removeToast(id);
    }, []);

    const handleDismissToast = useCallback(async (id: ToastId) => {
        engine.current.onRequestDimissToast(id);
    }, []);

    const handleRemoveAllToasts = useCallback(() => {
        engine.current.removeAllToasts();
    }, []);

    useEffect(() => {
        function cleanUp(engine: ToastEngine) {
            engine.removeAllToasts();
            engine.clearTimeouts();
        }
        return cleanUp(engine.current);
    }, [engine]);

    const toastList = toasts.map((toast) => {
        return (
            <ToastComponent
                className="toast-list"
                testId={`${Toast_Elements.Toast}${toast.id}`}
                key={toast.id}
                onDismiss={handleDismissToast}
                onRemove={handleRemoveToast}
                {...toast}
            />
        );
    });

    return (
        <ToastContext.Provider
            value={{
                toasts,
                onRequestCreate: handleCreateToast,
                onRequestDismiss: handleDismissToast,
                onRequestDismissAll: handleRemoveAllToasts,
            }}
        >
            {children}
            <ul data-testid={Toast_Elements}>{toastList}</ul>
        </ToastContext.Provider>
    );
}
