import { useContext } from "react";
import { ToastContext } from "./context";
import { Toast, ToastId, ToastMessage, ToastOptions } from "./model";

export type UseToastReturn = {
    /**
     * List of all active toasts.
     */
    toasts: Toast[];
    /**
     * Requests to create Toast with status and options.
     */
    createToast: (message: ToastMessage, options?: ToastOptions) => void;
    /**
     * Requests to dismiss toasy by id.
     */
    dismissToast: (id: ToastId) => void;
    /**
     * Requests to dismiss all toasts.
     */
    dismissAllToasts: () => void;
};

/**
 * Allows the creation of toasts.
 */
export function useToast(): UseToastReturn {
    const { onRequestCreate, onRequestDismiss, onRequestDismissAll, toasts } =
        useContext(ToastContext);

    return {
        toasts,
        createToast: onRequestCreate,
        dismissToast: onRequestDismiss,
        dismissAllToasts: onRequestDismissAll,
    };
}
