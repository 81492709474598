import { FilterQueryOptions } from "../../models/filters";
import { buildFilterProductLineQuery } from ".";
import config from "@config";

export function buildFilterAPIQuery(
    queryOptions: FilterQueryOptions,
    filterType: string,
): string {
    const products = buildFilterProductLineQuery(queryOptions.userProducts);
    const queryString = `${config.api.filters.url}?field=${filterType}${products}&startDate=${queryOptions.dateSelection.startDate}&endDate=${queryOptions.dateSelection.endDate}`;

    return queryString;
}
