import { DateRange } from "@models";
import { DateRangeItem } from "@components/filters/types";

export const setSelectedFiltersFromDateOnLoad = (
    filterItems: DateRangeItem[],
) => {
    const dateRange: DateRange = { id: "All", startDate: "", endDate: "" };

    filterItems.map((item) => {
        if (item.checked) {
            dateRange.id = item.selectedValues.id;
            dateRange.startDate = item.selectedValues.startDate;
            dateRange.endDate = item.selectedValues.endDate;
        }
    });

    return dateRange;
};
