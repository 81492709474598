import config from "@config";
import { ItemDetailsRaw } from "@models";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@stores";
import { ApiOptionsServiceKey, ServiceLocator } from "@services";
import { ApiOptionsService } from "@services/api-options";

export type APIQueryOptions = {
    orderNumber: string;
};

type ItemDetailsSliceState = {
    status: "loading" | "idle";
    error: string | null;
    content: ItemDetailsRaw | null;
};

const initialState: ItemDetailsSliceState = {
    content: null,
    error: null,
    status: "idle",
};

export const getItemDetails = createAsyncThunk(
    "orderHistory/itemDetails",
    async (queryOptions: APIQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const url = `${config.api.orderHistory.url}/${queryOptions.orderNumber}?expand=LineItems($expand=ScheduleLines)`;

        const res = await fetch(url, options);
        return res.json();
    },
);

export const ItemDetailsSlice = createSlice({
    name: "itemDetails",
    initialState,
    reducers: {
        updateItemDetails(state, action) {
            state.content = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getItemDetails.pending, (state) => {
            state.status = "loading";
            state.error = null;
            state.content = null;
        });
        builder.addCase(getItemDetails.fulfilled, (state, { payload }) => {
            if (
                payload.statusCode !== undefined &&
                payload.statusCode !== 200
            ) {
                state.status = "idle";
                state.error = `Error getting Item Details.Error Code: ${payload.statusCode}`;
            } else {
                state.content = !payload.value ? payload : payload.value;
                state.status = "idle";
            }
        });

        builder.addCase(getItemDetails.rejected, (state) => {
            state.status = "idle";
            state.error = "Error getting Item Details";
        });
    },
});

export const { updateItemDetails } = ItemDetailsSlice.actions;

export const selectItemDetailsLoadStatus = (state: RootState) =>
    state.ItemDetails.status;
export const selectItemDetailsError = (state: RootState) =>
    state.ItemDetails.error;
export const selectItemDetails = (state: RootState) =>
    state.ItemDetails.content;

export default ItemDetailsSlice.reducer;
