import React, { useEffect } from "react";
import { ItemDetailsDisplay, LineItemsDisplay } from "@components/display";
import { Card, Divider } from "@cpchem/covalence-ui";
import {
    getItemDetails,
    selectItemDetails,
    selectItemDetailsLoadStatus,
    selectItemDetailsError,
} from "@stores/item-details";
import { TransformItemDetailsRaw } from "@utilities/data/item-details-mapping";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { NotificationPage } from "../error-page";
import { Breadcrumb } from "../../components/breadcrumbs";
import { LoadingDisplay } from "@components/loading";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { LOADING_ITEM_DETAILS } from "@utilities/log/log-events";
import { createPageViewEvent } from "@cpchem/logging";
import { ItemDetailsElements } from "./elements";

export function ItemDetailsPage(): JSX.Element {
    const dispatch = useAppDispatch();
    const itemDetailsRaw = useAppSelector(selectItemDetails);
    const itemDetailsLoadStatus = useAppSelector(selectItemDetailsLoadStatus);
    const itemDetailsError = useAppSelector(selectItemDetailsError);
    const itemDetailsList =
        itemDetailsRaw !== null
            ? TransformItemDetailsRaw(itemDetailsRaw)
            : {
                  poNumber: "",
                  poDate: "",
                  orderNumber: "",
                  quoteNumber: "",
                  items: 0,
                  deliverTo: ["", ""],
                  requestedDeliveryDate: "",
                  incoterms: "",
                  lineItems: [],
              };
    const headerItemDetailsProps = {
        poNumber: itemDetailsList.poNumber,
        poDate: itemDetailsList.poDate,
        orderNumber: itemDetailsList.orderNumber,
        quoteNumber: itemDetailsList.quoteNumber,
        items: itemDetailsList.items,
        deliverTo: itemDetailsList.deliverTo,
        requestedDeliveryDate: itemDetailsList.requestedDeliveryDate,
        incoterms: itemDetailsList.incoterms,
    };
    const { orderNumber } = useParams();

    const ordersPageLink = "/orders";

    useEffect(() => {
        ecomTrackEvent({
            type: LOADING_ITEM_DETAILS,
            contents: JSON.stringify({
                orderNumber: orderNumber ?? "",
            }),
        });
        dispatch(getItemDetails({ orderNumber: orderNumber ?? "" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("Item Details Page"));
    }, []);

    document.title = `Order # ${orderNumber} - CustomerConnect`;

    return (
        <div className="item-details-page">
            <div className="item-details-page-header">
                <div>
                    <Breadcrumb
                        title={`Order # ${orderNumber}` as string}
                        links={[
                            { display: "Your Orders", link: ordersPageLink },
                        ]}
                    />
                </div>
                <h1
                    className="item-details-title"
                    data-testid={ItemDetailsElements.OrderNumberHeader}
                >{`Order # ${orderNumber}`}</h1>
            </div>
            <Divider />
            <div className="item-details-page-body">
                {itemDetailsError !== null ? (
                    <NotificationPage status={itemDetailsLoadStatus} />
                ) : (
                    <LoadingDisplay
                        showLoading={itemDetailsLoadStatus === "loading"}
                        className="body-container"
                    >
                        <ItemDetailsDisplay {...headerItemDetailsProps} />
                        <div
                            className="item-details-page-content"
                            data-testid={ItemDetailsElements.ItemDetailsContent}
                        >
                            <Card className="item-details-page-card no-border">
                                <div
                                    className="grid-filter-bar"
                                    id="grid-filter-bar"
                                />
                                <LineItemsDisplay
                                    ItemDetails={itemDetailsList}
                                />
                            </Card>
                        </div>
                    </LoadingDisplay>
                )}
            </div>
        </div>
    );
}
