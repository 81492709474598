import React, { useEffect, useState } from "react";
import {
    DataGrid,
    DataGridColumnDefinition,
    DataGridProps,
    DataGridRow,
    DisplayAmount,
    FilteringDataGridColumnDefinition,
    Pagination,
} from "@cpchem/covalence-ui";
import {
    ResultRowFull,
    ResultRowMedium,
    ResultRowSmall,
    FilteredEmptyResults,
} from "./renderer/row";
import {
    HeaderRendererFull,
    HeaderRendererMedium,
    HeaderRendererSmall,
} from "./renderer/header";
import { GetColumnsByScreenSize } from "./content/column-definitions";
import { useBreakpoint } from "@hooks/breakpoint";
import { OrderHistoryItem } from "@models";
import { sortValue } from "@utilities/pages/order-history";
import { OrderDisplay } from "./elements";

type DisplaySizeOptions = {
    currentColumns: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[];
    currentHeaderRenderer: (headers: DataGridColumnDefinition[]) => JSX.Element;
    currentRowRenderer: (row: DataGridRow) => JSX.Element;
};

interface OrderDisplayProps {
    orders: OrderHistoryItem[];
    orderCount: number;
    rowsToDisplay: DisplayAmount;
    currentPage: number;
    goToPage: (value: number) => void;
    onDisplayPageChange: (value: string) => void;
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void;
    currentSortValue: sortValue;
}

export function OrdersDisplay({
    orders,
    orderCount,
    rowsToDisplay,
    currentPage,
    goToPage,
    onDisplayPageChange,
    onSelectSortValue,
    currentSortValue,
}: OrderDisplayProps) {
    // OrderHistory store variables.

    function getHeaderRendererLarge(
        headers: DataGridColumnDefinition[],
    ): JSX.Element {
        return HeaderRendererFull(headers, onSelectSortValue, currentSortValue);
    }

    function getHeaderRendererMedium(
        headers: DataGridColumnDefinition[],
    ): JSX.Element {
        return HeaderRendererMedium(
            headers,
            onSelectSortValue,
            currentSortValue,
        );
    }

    function getHeaderRendererSmall(
        headers: DataGridColumnDefinition[],
    ): JSX.Element {
        return HeaderRendererSmall(
            headers,
            onSelectSortValue,
            currentSortValue,
        );
    }

    const [displaySizeOptions, setDisplaySizeOptions] =
        useState<DisplaySizeOptions>({
            currentColumns: GetColumnsByScreenSize("xxl"),
            currentHeaderRenderer: getHeaderRendererLarge,
            currentRowRenderer: ResultRowFull,
        });

    // Variables for view port control
    const breakpoints = useBreakpoint();
    const columns = displaySizeOptions.currentColumns;

    // Initial loading section
    useEffect(() => {
        function defineColumns() {
            const matchingMedia = Object.keys(breakpoints).find((media) => {
                return breakpoints[media] === true;
            });
            if (matchingMedia === "lg" || matchingMedia === "md") {
                setDisplaySizeOptions({
                    ...displaySizeOptions,
                    currentColumns: GetColumnsByScreenSize("lg"),
                    currentRowRenderer: ResultRowSmall,
                    currentHeaderRenderer: getHeaderRendererSmall,
                });
            } else if (matchingMedia === "xl") {
                setDisplaySizeOptions({
                    ...displaySizeOptions,
                    currentColumns: GetColumnsByScreenSize("xl"),
                    currentRowRenderer: ResultRowMedium,
                    currentHeaderRenderer: getHeaderRendererMedium,
                });
            } else {
                setDisplaySizeOptions({
                    ...displaySizeOptions,
                    currentColumns: GetColumnsByScreenSize("xxl"),
                    currentRowRenderer: ResultRowFull,
                    currentHeaderRenderer: getHeaderRendererLarge,
                });
            }
        }
        defineColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoints, currentSortValue]);

    // Datagrid props definition
    const gridProps: DataGridProps = {
        data: orders,
        columns,
        useStickyHeaders: true,
        headerRenderer: displaySizeOptions.currentHeaderRenderer,
        rowRenderer: displaySizeOptions.currentRowRenderer,
        emptyResultsRenderer: FilteredEmptyResults,
        isSortable: false,
    };

    return (
        <div className="orders-grid">
            <DataGrid
                {...gridProps}
                columns={displaySizeOptions.currentColumns}
                testId={OrderDisplay.OrdersTable}
            />
            <div style={{ width: "100%" }}>
                <Pagination
                    className={"content-center"}
                    totalItems={orderCount}
                    displayAmount={rowsToDisplay}
                    currentPage={currentPage}
                    showItemsPerPageDropDown={orderCount > 10}
                    showDisplayAmountDescription={false}
                    showTotalPagesIndex={false}
                    showNavigationText
                    onDisplayAmountChange={onDisplayPageChange}
                    goToPage={goToPage}
                />
            </div>
        </div>
    );
}
