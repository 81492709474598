import {
    DeliveryLineItem,
    DeliveryLineItemRaw,
    OrderLineItem,
    OrderLineItemRaw,
    ShipmentDetail,
    ShipmentDetailRaw,
} from "@models/shipment-details";
import { ConvertFullDateToShort } from "@utilities/dates/date-format-helper";
import {
    GetFormattedNumber,
    GetFormattedNumberByCurrencyType,
} from "@utilities/numbers/number-format-helper";

export function TransformShipmentDetailsRaw(
    rawContent: OrderLineItemRaw | null,
): OrderLineItem {
    const contentTransformed: OrderLineItem = {
        itemNumber: "",
        itemDescription: "",
        itemMaterialNumber: 0,
        itemCustomerMaterialNumber: "",
        orderedQuantity: "",
        totalConfirmedQuantity: "",
        shipFrom: "",
        shipmentsQuantity: 0,
        deliveryList: [],
    };

    if (rawContent !== null) {
        contentTransformed.itemNumber = rawContent.salesDocumentItem.toString();
        contentTransformed.itemDescription = rawContent.materialName;
        contentTransformed.itemMaterialNumber = rawContent.materialNumber;
        contentTransformed.itemCustomerMaterialNumber =
            rawContent.customerMaterialNumber;
        contentTransformed.orderedQuantity = `${GetFormattedNumber(
            rawContent.itemQuantity,
        )} ${rawContent.unitOfMeasure}`;
        contentTransformed.totalConfirmedQuantity = ` ${GetFormattedNumber(
            rawContent.totalConfirmedQuantity,
        )} ${rawContent.unitOfMeasure}`;
        contentTransformed.shipFrom = rawContent.shipFrom;
        contentTransformed.shipmentsQuantity = rawContent.deliveryCount;
        contentTransformed.deliveryList = TransformShipmentDeliveryItemsRaw(
            rawContent.shipmentDetails,
        );
    }

    return contentTransformed;
}

function TransformShipmentDeliveryItemsRaw(
    rawContent: ShipmentDetailRaw[] | null,
): ShipmentDetail[] {
    const deliveryItemTransformed: ShipmentDetail[] = [];

    if (rawContent !== null && rawContent.length > 0) {
        rawContent.forEach((rawItem) => {
            deliveryItemTransformed.push({
                deliveryNumber: rawItem.deliveryNumber,
                route: rawItem.route,
                carrier: rawItem.routeDescription ?? "",
                deliveryItemQuantity: `${GetFormattedNumber(
                    rawItem.itemQuantity,
                )} ${rawItem.unitOfMeasure}`,
                shippingStatus: rawItem.goodsMovementStatus,
                plannedShippedOn: ConvertFullDateToShort(
                    rawItem.plannedGoodsIssueDate,
                ),
                shippedOn:
                    rawItem.actualGoodsIssueDate !== null
                        ? ConvertFullDateToShort(rawItem.actualGoodsIssueDate)
                        : "",
                invoiceNumber:
                    rawItem.billingDocument !== null
                        ? `${rawItem.billingDocument}`
                        : "",
                invoiceDueDate:
                    rawItem.dueDate !== null
                        ? ConvertFullDateToShort(rawItem.dueDate)
                        : "",
                invoiceAmount:
                    rawItem.netValue !== null
                        ? GetFormattedNumberByCurrencyType(rawItem.netValue)
                        : "",
                status: rawItem.status,
                lineItems: TransformDeliveryLineItemsRaw(rawItem.lineItems),
            });
        });
    }

    return deliveryItemTransformed;
}

function TransformDeliveryLineItemsRaw(
    deliveryItemsRawContent: DeliveryLineItemRaw[] | null,
): DeliveryLineItem[] {
    const deliveryLineItems: DeliveryLineItem[] = [];
    if (
        deliveryItemsRawContent !== null &&
        deliveryItemsRawContent.length > 0
    ) {
        deliveryItemsRawContent.forEach((rawItem) => {
            deliveryLineItems.push({
                itemDescription: rawItem.materialName,
                itemCustomerMaterialNumber: rawItem.customerMaterialNumber,
                itemMaterialNumber: rawItem.materialNumber,
                shipmentQuantity: `${rawItem.itemQuantity} ${rawItem.unitOfMeasure}`,
            });
        });
    }

    return deliveryLineItems;
}
