import React from "react";
import { Button, Menu, MenuItem } from "@cpchem/covalence-ui";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Elements } from "./elements";

import "./styles.scss";

export type DateFilterItem = {
    id: string;
    display: string;
    startDate: string;
    endDate: string;
    testId?: string;
};

export interface DateSelectFilterProps {
    selectedFilter: DateFilterItem;
    filters: DateFilterItem[];
    testId?: string;
    onChange: (id: string) => void;
}

function DateSelectFilter(props: DateSelectFilterProps) {
    const containerTestId: string | undefined = props.testId
        ? `${props.testId}-${Elements.Filter_DateRange_Select_Container}`
        : undefined;

    const menuButtonTestId: string | undefined = props.testId
        ? `${props.testId}-${Elements.Filter_DateRange_Select_Menu_Button}`
        : undefined;

    const renderedFilterItems: MenuItem[] = [];

    props.filters?.map((row) =>
        renderedFilterItems.push({
            content: row.display,
            key: row.id,
            className: "menu-item",
            onClick: () => props.onChange(row.id),
        }),
    );

    const faPropAngleDownIcon = faAngleDown as IconProp;
    return (
        <div className="date-filter" data-testid={containerTestId}>
            <Menu
                className="menu"
                disableActiveStyles
                baseElement={
                    <Button
                        color="primary"
                        testId={menuButtonTestId}
                        text={props.selectedFilter?.display}
                        variant="outline"
                        className="button"
                        isIconAfterText
                        icon={
                            <FontAwesomeIcon
                                className="icon"
                                icon={faPropAngleDownIcon}
                            />
                        }
                    />
                }
                menuItems={renderedFilterItems}
            />
        </div>
    );
}

export default React.memo(DateSelectFilter);
