import { DateRange } from "@models/date-range";

export function getLastQuarter(currentMonth: number, year: number): DateRange {
    if (currentMonth >= 1 && currentMonth <= 3) {
        return { startDate: `${year - 1}-10-01`, endDate: `${year - 1}-12-31` };
    }
    if (currentMonth >= 4 && currentMonth <= 6) {
        return { startDate: `${year}-01-01`, endDate: `${year}-03-31` };
    }
    if (currentMonth >= 7 && currentMonth <= 9) {
        return { startDate: `${year}-04-01`, endDate: `${year}-06-30` };
    }

    return { startDate: `${year}-07-01`, endDate: `${year}-09-30` };
}
