export const ProductLine_Checkbox_Elements = {
    Product_Line_Container: "product-line-container",
    Product_Line_Indicator: "product-line-indicator",
    Product_Line_Icon: "product-line-icon",
    Product_Line_Label: "product-line-label",
    Product_Line_Input: "product-line-input",
    Product_Line_Checkbox_Container: "productline-checkbox-container",
    Product_Line_Checkbox_Icon: "productline-checkbox-icon",
    Product_Line_Checkbox_Label: "productline-checkbox-label",
    Product_Line_Checkbox_Indicator: "productline-checkbox-indicator",
};
