import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition,
} from "@cpchem/covalence-ui";

const baseColumns: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Items",
        accessor: "item",
        id: "item",
        alignment: "left",
    },
    {
        Header: "Delivery Item Qty",
        accessor: "deliveryItemQuantity",
        id: "deliveryItemQuantity",
        alignment: "right",
    },
];

export function GetDeliveryItemColumns() {
    const columnsDefinition: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[] = [...baseColumns];

    return columnsDefinition;
}
