import {
    faTriangleExclamation,
    faUserLock,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { errorProps } from "../../../pages/error-page";
import "./styles.scss";

export function NotificationDisplay(props: errorProps): JSX.Element {
    let error_icon = faTriangleExclamation;
    let error_text = "Technical error";
    let error_explaination = (
        <span>
            Something went wrong. Please refresh the page or try again later.
        </span>
    );
    let error_solution = <span />;
    const showError = props.status === "unAuthorized" || "error";
    if (props.status === "unAuthorized") {
        error_icon = faUserLock;
        error_text = "Access denied";
        error_explaination = (
            <span>You don’t have permission to access this application. </span>
        );
        error_solution = (
            <span>
                Use <a href="http://idm.cpchem.net/">Identity Manager (IDM) </a>
                to request access.
            </span>
        );
    }
    return (
        showError && (
            <div className="error-display" data-testid="errorDisplay-testId">
                <FontAwesomeIcon
                    icon={error_icon}
                    className="fa-light fa-4x"
                    color="#D44148"
                />
                <span className="error-display__text">{error_text}</span>
                {error_explaination}
                {error_solution}
            </div>
        )
    );
}
