import {
    EMPLOYEE_FILTER_ID,
    CUSTOMER_FILTER_ID,
    LOCATION_FILTER_ID,
    PRODUCT_FILTER_ID,
    CUSTOMER_FILTER_TITLE,
    EMPLOYEE_FILTER_TITLE,
    PRODUCT_FILTER_TITLE,
    LOCATION_FILTER_TITLE,
    SHIPFROM_FILTER_ID,
    SHIPFROM_FILTER_TITLE,
} from "./filter-names";
import { FilterListItem, FilterQueryOptions } from "../../models/filters";
import { DefaultStatusFilterItems } from "@pages/orders-page/data";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    resetFilterList,
    resetFilter,
    resetDateFilter,
    hasCheckedFilter,
} from "../../utilities/filters";
import { FetchAndReturnJson, buildFilterAPIQuery } from "../../utilities/api";
import { RootState } from "@stores";
import { buildFilterListItemList } from "@utilities/filters";
import { DateRangeItem } from "@components/filters/types";
import {
    DefaultPoDateFilterData,
    DefaultRequestedDateFilterData,
} from "@components/filters/filter-date-range-group/filter-date-range-group-data";
import { ApiOptionsServiceKey, ServiceLocator } from "@services";
import { ApiOptionsService } from "@services/api-options";

export type FiltersSliceState = {
    employeeFilters: FilterListItem[];
    customerFilters: FilterListItem[];
    shipFromFilters: FilterListItem[];
    deliveryLocationFilters: FilterListItem[];
    productDescriptionFilters: FilterListItem[];
    statusFilters: FilterListItem[];
    poDateFilters: DateRangeItem[];
    requestedDeliveryDateFilters: DateRangeItem[];
};
export type FilterCatalogTypes =
    | "Customer"
    | "Employee Responsible"
    | "Ship From"
    | "Delivery Location"
    | "Product Description";

const initialState: FiltersSliceState = {
    customerFilters: [],
    shipFromFilters: [],
    deliveryLocationFilters: [],
    productDescriptionFilters: [],
    employeeFilters: [],
    statusFilters: DefaultStatusFilterItems,
    poDateFilters: DefaultPoDateFilterData,
    requestedDeliveryDateFilters: DefaultRequestedDateFilterData,
};

export type ErrorResponse = {
    error: string;
};

export const getEmployeeFilterItems = createAsyncThunk(
    "filters/getEmployeeFilterItems",
    async (queryOptions: FilterQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const apiResponse = await FetchAndReturnJson(
            buildFilterAPIQuery(queryOptions, EMPLOYEE_FILTER_ID),
            options,
        );

        return apiResponse;
    },
);

export const getCustomerFilterItems = createAsyncThunk(
    "filters/getCustomerFilterItems",
    async (queryOptions: FilterQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const apiResponse = await FetchAndReturnJson(
            buildFilterAPIQuery(queryOptions, CUSTOMER_FILTER_ID),
            options,
        );

        return apiResponse;
    },
);

export const getShipFromFilterItems = createAsyncThunk(
    "filters/getShipFromFilterItems",
    async (queryOptions: FilterQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const apiResponse = await FetchAndReturnJson(
            buildFilterAPIQuery(queryOptions, SHIPFROM_FILTER_ID),
            options,
        );

        return apiResponse;
    },
);

export const getDeliveryLocationItems = createAsyncThunk(
    "filters/getDeliveryLocationItems",
    async (queryOptions: FilterQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const apiResponse = await FetchAndReturnJson(
            buildFilterAPIQuery(queryOptions, LOCATION_FILTER_ID),
            options,
        );

        return apiResponse;
    },
);

export const getProductDescriptionItems = createAsyncThunk(
    "filters/getProductDescriptionItems",
    async (queryOptions: FilterQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const apiResponse = await FetchAndReturnJson(
            buildFilterAPIQuery(queryOptions, PRODUCT_FILTER_ID),
            options,
        );

        return apiResponse;
    },
);

export function onEmployeeFilterItemsFullfilled(
    state: FiltersSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    state.employeeFilters = verifyFilterData(action, EMPLOYEE_FILTER_TITLE);
}

export function onCustomerFilterItemsFullfilled(
    state: FiltersSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    state.customerFilters = verifyFilterData(action, CUSTOMER_FILTER_TITLE);
}

export function onShipFromFilterItemsFullfilled(
    state: FiltersSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    state.shipFromFilters = verifyFilterData(action, SHIPFROM_FILTER_TITLE);
}

export function onDeliveryLocationFilterItemsFullfilled(
    state: FiltersSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    state.deliveryLocationFilters = verifyFilterData(
        action,
        LOCATION_FILTER_TITLE,
    );
}

export function onProductDescriptionFilterItemsFullfilled(
    state: FiltersSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    state.productDescriptionFilters = verifyFilterData(
        action,
        PRODUCT_FILTER_TITLE,
    );
}

export function verifyFilterData(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
    filterTitle: FilterCatalogTypes,
) {
    const { meta, payload } = action;
    if (payload.status === 200) {
        return buildFilterListItemList(
            payload.content,
            filterTitle,
            meta.arg.currentFilterList ?? [],
        );
    }
    if (payload.status !== undefined && payload.status >= 400) {
        console.log(ResolveErrorResponseStatus(payload.status).error);
    }
    return [];
}

export function ResolveErrorResponseStatus(status: number): ErrorResponse {
    if (status === 400) {
        return {
            error: "A value in your request is wrong. Try again with other values in your filter request.",
        };
    }
    if (status === 401) {
        return { error: "You are unauthorized." };
    }
    if (status === 404) {
        return {
            error: "No filter items were found.",
        };
    }
    return {
        error: "Error getting Filter List. If this keeps happening, contact customer support.",
    };
}

export const FiltersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        updateFilters(state, action) {
            state.employeeFilters = action.payload.employeeFilters;
            state.customerFilters = action.payload.customerFilters;
            state.shipFromFilters = action.payload.shipFromFilters;
            state.deliveryLocationFilters =
                action.payload.deliveryLocationFilters;
            state.poDateFilters = action.payload.poDateFilters;
            state.productDescriptionFilters =
                action.payload.productDescriptionFilters;
            state.requestedDeliveryDateFilters =
                action.payload.requestedDeliveryDateFilters;
            state.statusFilters = action.payload.statusFilters;
        },
        resetAllFilters(state) {
            resetFilterList(state.employeeFilters);
            resetFilterList(state.customerFilters);
            resetFilterList(state.shipFromFilters);
            resetFilterList(state.deliveryLocationFilters);
            resetFilterList(state.productDescriptionFilters);
            state.poDateFilters = DefaultPoDateFilterData;
            state.requestedDeliveryDateFilters = DefaultRequestedDateFilterData;
            state.statusFilters = DefaultStatusFilterItems;
        },
        resetNonPartialFilters(state) {
            state.poDateFilters = DefaultPoDateFilterData;
            state.requestedDeliveryDateFilters = DefaultRequestedDateFilterData;
        },
        resetBadge(state, action) {
            const { id, title } = action.payload;
            switch (title) {
                case "Employee Responsible":
                    state.employeeFilters = resetFilter(
                        state.employeeFilters,
                        id,
                    );
                    break;
                case "Customer":
                    state.customerFilters = resetFilter(
                        state.customerFilters,
                        id,
                    );
                    break;
                case "Ship From":
                    state.shipFromFilters = resetFilter(
                        state.shipFromFilters,
                        id,
                    );
                    break;
                case "Delivery Location":
                    state.deliveryLocationFilters = resetFilter(
                        state.deliveryLocationFilters,
                        id,
                    );
                    break;
                case "Order Status":
                    state.statusFilters = resetFilter(state.statusFilters, id);
                    break;
                case "Product Description":
                    state.productDescriptionFilters = resetFilter(
                        state.productDescriptionFilters,
                        id,
                    );
                    break;
                case "PO Date":
                    state.poDateFilters = resetDateFilter(
                        state.poDateFilters,
                        title,
                    );
                    break;
                case "Requested Delivery":
                    state.requestedDeliveryDateFilters = resetDateFilter(
                        state.requestedDeliveryDateFilters,
                        title,
                    );
                    break;
            }
        },
    },
    // Verify if this functions could be unified in a way that could make it testeable or is just the api helper function.
    extraReducers: (builder) => {
        builder.addCase(
            getEmployeeFilterItems.fulfilled,
            onEmployeeFilterItemsFullfilled,
        );

        builder.addCase(
            getCustomerFilterItems.fulfilled,
            onCustomerFilterItemsFullfilled,
        );

        builder.addCase(
            getShipFromFilterItems.fulfilled,
            onShipFromFilterItemsFullfilled,
        );

        builder.addCase(
            getDeliveryLocationItems.fulfilled,
            onDeliveryLocationFilterItemsFullfilled,
        );

        builder.addCase(
            getProductDescriptionItems.fulfilled,
            onProductDescriptionFilterItemsFullfilled,
        );
    },
});

export const {
    updateFilters,
    resetAllFilters,
    resetBadge,
    resetNonPartialFilters,
} = FiltersSlice.actions;

export const selectFilters = (state: RootState) => state.Filters;
export const selectHasActiveFilter = (state: RootState) => {
    return hasCheckedFilter(
        state.Filters.employeeFilters,
        state.Filters.customerFilters,
        state.Filters.productDescriptionFilters,
        state.Filters.shipFromFilters,
        state.Filters.deliveryLocationFilters,
        state.Filters.statusFilters,
        state.Filters.poDateFilters,
        state.Filters.requestedDeliveryDateFilters,
    );
};

export default FiltersSlice.reducer;
