import React, { useEffect, useState } from "react";
import { AppBar, Button } from "@cpchem/covalence-ui";
import { LogoIcon } from "@components/icons";
import { AuthManager } from "@cpchem/azure-auth";
import { useAuth } from "@hooks/use-auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getGraphUserPhoto } from "./methods/graph-photo";
import { getInitialsFromName } from "./methods/user-initials";
import { getAvatarContent } from "./methods/avatar-content";
import config from "@config";
import {
    pageReset,
    setPageNumber,
    selectOrderHistoryMode,
} from "@stores/order-history";
import { isInDevEnvironment } from "@utilities/log/in-dev-environment";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import { resetAllFilters } from "@stores/filters";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { SEARCH_FIELD_REQUEST } from "@utilities/log/log-events";

type ecomBarProps = {
    ApplicationReady: boolean;
    testId?: string;
};

export function EcomBar(props: ecomBarProps): JSX.Element {
    const { search } = useLocation();
    const urlSearchTerm = new URLSearchParams(search).get("q");
    const GetPageMode = useAppSelector(selectOrderHistoryMode);
    const { currentAccount } = useAuth();
    const authMgr = AuthManager.getInstance();
    const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>(urlSearchTerm || "");
    const navigate = useNavigate();
    const { ApplicationReady, testId } = props;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (GetPageMode === "order") {
            setSearchTerm("");
        }
        if (GetPageMode === "search" && urlSearchTerm) {
            setSearchTerm(urlSearchTerm);
        }
    }, [GetPageMode, urlSearchTerm]);

    function loadUserInitials() {
        if (userInitials === "") {
            const parsedInitials = getInitialsFromName(currentAccount);
            if (parsedInitials !== userInitials) {
                setUserInitials(parsedInitials);
            }
        }
    }

    async function loadCurrentUserPhoto() {
        if (profilePhoto === null) {
            setProfilePhoto(await getGraphUserPhoto(profilePhoto));
        }
    }

    function handleIconClick() {
        setSearchTerm("");
        dispatch(setPageNumber(1));
        dispatch(pageReset());
        dispatch(resetAllFilters());
        navigate("/");
    }

    useEffect(() => {
        loadUserInitials();
        if (
            (isInDevEnvironment(config.env) && config.connected) ||
            !isInDevEnvironment(config.env)
        ) {
            loadCurrentUserPhoto();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSearchValueChange(value: string) {
        setSearchTerm(value);
    }

    function onSearchEnter() {
        dispatch(setPageNumber(1));
        if (searchTerm.length >= 1) {
            ecomTrackEvent({
                type: SEARCH_FIELD_REQUEST,
                contents: JSON.stringify({ searchString: searchTerm }),
            });
            navigate(`/search?q=${searchTerm}`, {
                state: { searchTerm: searchTerm },
            });
        } else {
            console.log("No term was entered");
        }
    }

    const helpMenuContent = (
        <div className="feed-back-menu">
            <Button
                text={"Submit Feedback"}
                icon={
                    <FontAwesomeIcon icon={faExternalLink} className="icon" />
                }
                isIconAfterText
                variant="text"
            />
        </div>
    );

    return (
        <AppBar
            className="ecom-appbar"
            applicationName="CustomerConnect"
            searchTooltip="Search orders by PO #, Order #, Quote #, Delivery #, or Invoice #"
            searchboxPlaceholderText="Search orders..."
            hasThickBorder
            showSearch={ApplicationReady}
            searchValue={searchTerm}
            onSearchPressEnter={onSearchEnter}
            onSearchValueChange={onSearchValueChange}
            icon={<LogoIcon />}
            onIconClick={handleIconClick}
            helpMenuItems={[
                {
                    key: "feedback",
                    content: helpMenuContent,
                    onClick: () =>
                        window.open(
                            "https://forms.office.com/Pages/ResponsePage.aspx?id=e8e2iOD0xUCfu99RqScXmhc18TgMWfhOu0RuNuSnXjVUQkRBTktYRE02WkRXVEpTSVRQMEM0MDBKNyQlQCN0PWcu",
                            "_blank",
                        ),
                },
            ]}
            avatarMenu={{
                avatar: getAvatarContent(
                    profilePhoto,
                    userInitials,
                    currentAccount,
                    testId,
                ),
                userName: currentAccount ? currentAccount?.username : "",
                displayName: currentAccount ? currentAccount?.name : "",
                showLogout: true,
                showThemeSelector: false,
                additionalMenuItems: ApplicationReady
                    ? [
                          {
                              key: "Preferences",
                              content: "Preferences",
                              onClick: () => navigate("/preferences"),
                              isActive: true,
                          },
                      ]
                    : [],
                onRequestLogout: authMgr.logout,
            }}
        />
    );
}
