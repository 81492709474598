import {
    getDateInPast,
    getYearInPast,
    getFirstDayOfYear,
    getLastDayOfYear,
} from "../../../utilities/dates";
import { DateFilterItem } from "./index";

const currentDate = new Date();

// used to initialize the date select filter
export const dateFilters: DateFilterItem[] = [
    {
        id: "O1",
        display: "Last 30 Days",
        startDate: getDateInPast(currentDate, 30).toISOString().split("T")[0],
        endDate: currentDate.toISOString().split("T")[0],
    },
    {
        id: "O2",
        display: "Past 90 Days",
        startDate: getDateInPast(currentDate, 90).toISOString().split("T")[0],
        endDate: currentDate.toISOString().split("T")[0],
    },
    // Current year...
    {
        id: "O3",
        display: getYearInPast(currentDate, 0).toString(),
        startDate: getFirstDayOfYear().toISOString().split("T")[0],
        endDate: getLastDayOfYear(getYearInPast(currentDate, 0))
            .toISOString()
            .split("T")[0],
    },
    // 1 year ago...
    {
        id: "O4",
        display: getYearInPast(currentDate, 1).toString(),
        startDate: getFirstDayOfYear(getYearInPast(currentDate, 1))
            .toISOString()
            .split("T")[0],
        endDate: getLastDayOfYear(getYearInPast(currentDate, 1))
            .toISOString()
            .split("T")[0],
    },
    // 2 years ago...
    {
        id: "O5",
        display: getYearInPast(currentDate, 2).toString(),
        startDate: getFirstDayOfYear(getYearInPast(currentDate, 2))
            .toISOString()
            .split("T")[0],
        endDate: getLastDayOfYear(getYearInPast(currentDate, 2))
            .toISOString()
            .split("T")[0],
    },
    // 3 years ago...
    {
        id: "O6",
        display: getYearInPast(currentDate, 3).toString(),
        startDate: getFirstDayOfYear(getYearInPast(currentDate, 3))
            .toISOString()
            .split("T")[0],
        endDate: getLastDayOfYear(getYearInPast(currentDate, 3))
            .toISOString()
            .split("T")[0],
    },
];

// the default selected date filter
export const defaultDateRangeSelectFilterData: DateFilterItem = {
    id: "O2",
    display: "Past 90 Days",
    startDate: getDateInPast(currentDate, 90).toISOString().split("T")[0],
    endDate: currentDate.toISOString().split("T")[0],
};
