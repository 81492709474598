import { Accordion } from "@cpchem/covalence-ui";
import React, { useState } from "react";
import { RadioButton, FilterDateRange } from "..";
import {
    getFormattedDate,
    getFormattedDateRange,
} from "../../../utilities/dates";
import { DateRangeItem } from "../types";
import { DateRange } from "@models/date-range";

export interface FilterDateRangeGroupProps {
    className?: string;
    /** Determines the title of the Filter component */
    title: string;
    /** Determines the group of the radio group */
    group: string;
    /** Determines if the user should be able to select a future range */
    isFutureRange: boolean;
    /** Passes in Date Range Filter Options */
    dateRangeFilterOptions: DateRangeItem[];
    onDateChange: (
        dateRange: DateRange,
        rawStartDate: string,
        rawEndDate: string,
        isStartDate: boolean,
    ) => void;
    onChange: (selectedValues: DateRange) => void;
    testId?: string;
}

export function FilterDateRangeGroup({
    title,
    group,
    className,
    isFutureRange,
    onDateChange,
    onChange,
    dateRangeFilterOptions,
    testId,
}: FilterDateRangeGroupProps): JSX.Element {
    const currentTime = new Date();

    const [formattedDateRange, setFormattedDateRange] = useState(
        getFormattedDateRange(currentTime, currentTime),
    );

    function handleDateChange(date: Date, startDate: boolean) {
        let rawStartDate;
        let rawEndDate;
        const stringToStartDate = new Date(formattedDateRange.startDate);
        const oneDay = 1000 * 60 * 60 * 24;
        const formattedDate = getFormattedDate(date);
        const newStartDate = new Date();
        let dateUpdated;

        if (startDate) {
            dateUpdated = { ...formattedDateRange, startDate: formattedDate };
            rawStartDate = `${date}`;
            rawEndDate = `${new Date(
                `${formattedDateRange.endDate}T00:00:00`,
            )}`;
        } else {
            if (stringToStartDate.valueOf() >= date.valueOf()) {
                newStartDate.setTime(date.getTime() - oneDay);
                rawStartDate = `${newStartDate}`;
                rawEndDate = `${date}`;
                dateUpdated = {
                    ...formattedDateRange,
                    endDate: formattedDate,
                    startDate: getFormattedDate(newStartDate),
                };
            } else {
                rawEndDate = `${date}`;
                rawStartDate = `${new Date(
                    `${formattedDateRange.startDate}T00:00:00`,
                )}`;
                dateUpdated = {
                    ...formattedDateRange,
                    endDate: formattedDate,
                };
            }
        }

        setFormattedDateRange(dateUpdated);

        const dateRangeFormattedArray: DateRange = {
            id: "Date range",
            startDate: dateUpdated.startDate,
            endDate: dateUpdated.endDate,
        };

        onDateChange(
            dateRangeFormattedArray,
            rawStartDate,
            rawEndDate,
            startDate,
        );
    }

    function handleChange(selectedValues: DateRange) {
        onChange(selectedValues);
    }

    const accordionTestId = testId ? `${testId}-accordion` : undefined;
    const radioButtonTestId = testId ? `${testId}-radio-button` : undefined;
    const dateRangeTestId = testId ? `${testId}-date-range` : undefined;

    const cnParts = ["cui-data-grid-filter-input"];

    if (className) {
        cnParts.push(className);
    }

    const cn = cnParts.join(" ");
    return (
        <div className={cn} data-testid={testId}>
            <Accordion label={title} testId={accordionTestId}>
                {dateRangeFilterOptions.map((row) => (
                    <RadioButton
                        key={row.id}
                        group={group}
                        value={row.name}
                        label={row.name as string}
                        isChecked={row.checked}
                        onChange={() => handleChange(row.selectedValues)}
                        testId={radioButtonTestId}
                    >
                        {row.name === "Date range" && row.checked && (
                            <FilterDateRange
                                startDate={row.rawStartDate}
                                endDate={row.rawEndDate}
                                onChange={handleDateChange}
                                testId={dateRangeTestId}
                                isFutureRange={isFutureRange}
                            />
                        )}
                    </RadioButton>
                ))}
            </Accordion>
        </div>
    );
}
