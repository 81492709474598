import { Button, DataGridRow } from "@cpchem/covalence-ui";
import { DeliveryLineItem, ShipmentDetail } from "@models/shipment-details";
import React from "react";
import { DisableShipmentTrackingButton } from "../../utilities";
import DocumentDropdown from "@components/documents/document-dropdown";
import {
    DeliveryNumberDisplay,
    DeliveryNumberProps,
} from "./delivery-number-modal";
import { ShipmentDetailsRow } from "../../elements";

function GetBaseColumnsRow(
    row: DataGridRow,
    openDeliveryModal: (
        deliveryNumber: number,
        lineItems: DeliveryLineItem[],
    ) => void,
    openShipmentTrackingModal: (deliveryNumber: number) => void,
): JSX.Element {
    const {
        deliveryNumber,
        route,
        carrier,
        deliveryItemQuantity,
        shippingStatus,
        plannedShippedOn,
        shippedOn,
        invoiceNumber,
        invoiceDueDate,
        invoiceAmount,
        status,
        lineItems,
    } = row.values as ShipmentDetail;
    const deliveryNumberModal: DeliveryNumberProps = {
        deliveryNumber,
        lineItems,
        openDeliveryModal,
    };
    return (
        <>
            <td className="left delivery-number">
                <DeliveryNumberDisplay {...deliveryNumberModal} />
            </td>
            <td className="left carrier">
                <span>{`${carrier}`}</span>
            </td>
            <td className="right item-qty">
                <span>{`${deliveryItemQuantity}`}</span>
            </td>
            <td className="left shipped-on">
                {shippingStatus.toUpperCase() === "C" ? (
                    <span>{shippedOn} </span>
                ) : (
                    <>
                        <span>{plannedShippedOn}</span>
                        <br />
                        <span className="estimated-ship-date-title">
                            Est. ship date
                        </span>
                    </>
                )}
            </td>
            <td className="left invoice-number">
                <span>{invoiceNumber}</span>
            </td>
            <td className="left invoice-due-date">
                <span>{invoiceDueDate}</span>
            </td>
            <td className="right invoice-amount">
                <span>{invoiceAmount}</span>
            </td>
            <td className="left status">
                <span>{status}</span>
            </td>
            <td className="document-dropdown-cell">
                <DocumentDropdown
                    id={String(deliveryNumber)}
                    system={"DELIVERY"}
                    testId={`${ShipmentDetailsRow.DownloadDocumentDropdown}-${deliveryNumber}`}
                />
            </td>
            <td className="shipment-cell">
                <Button
                    className="shipment-page-button track-shipment"
                    color="primary"
                    isDisabled={DisableShipmentTrackingButton(
                        route,
                        shippingStatus,
                    )}
                    text="Track Shipment"
                    onClick={() => openShipmentTrackingModal(deliveryNumber)}
                    testId={`${ShipmentDetailsRow.TrackShipmentButton}-${deliveryNumber}`}
                />
            </td>
        </>
    );
}

export function ResultRowFull(
    row: DataGridRow,
    openDeliveryModal: (
        deliveryNumber: number,
        lineItems: DeliveryLineItem[],
    ) => void,
    openShipmentTrackingModal: (deliveryNumber: number) => void,
): JSX.Element {
    const { deliveryNumber } = row.values as ShipmentDetail;

    return (
        <tr key={`${deliveryNumber}`}>
            {GetBaseColumnsRow(
                row,
                openDeliveryModal,
                openShipmentTrackingModal,
            )}
        </tr>
    );
}
