import React from "react";

export function NoSearchResults(
    searchTerm: string | null,
    testId?: string,
): JSX.Element {
    return (
        <div
            className="no-results"
            data-testid={testId ? `${testId}-empty-search` : ""}
        >
            <div className="no-results-content">
                <div className="no-results-title">No search results.</div>
                <div>
                    Your search - {`"${searchTerm}"`} did not match any orders.
                </div>
                <div className="no-results-subtitle">Search Suggestions:</div>
                <ul>
                    <li>
                        Search orders by PO #, Order #, Quote #, Delivery #, or
                        Invoice #.
                    </li>
                    <li>
                        Make sure all words or numbers are spelled correctly.
                    </li>
                    <li> Searches are NOT case sensitive</li>
                </ul>
            </div>
        </div>
    );
}
