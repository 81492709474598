import { OrderHistoryItem, OrderHistoryItemsRaw } from "@models";
import { ConvertFullDateToShort } from "@utilities/dates/date-format-helper";
import { validateString } from "@utilities/string-utilities";

// Mapping function to map data from the source to the way the datagrid needs it
export function TransformOrderHistoryRawData(
    data: OrderHistoryItemsRaw[],
): OrderHistoryItem[] {
    const OrderHistoryDataMapped = data.map((value, index) => {
        const OrderItem: OrderHistoryItem = {
            orderId: index,
            poInformation: {
                customerPO: validateString(value.customerPurchaseOrderNumber),
                poDate: ConvertFullDateToShort(value.customerPurchaseOrderDate),
            },
            salesOrderCreateDate: ConvertFullDateToShort(
                value.orderCreationDate,
            ),
            orderInformation: {
                salesDocumentNumber: value.salesDocumentNumber,
                assignedQuoteNumber:
                    value.assignedQuoteNumber === null
                        ? ""
                        : `${value.assignedQuoteNumber}`,
            },
            items: value.lineItemCount,
            deliveryInformation: {
                shipToPartyName: value.shipToPartyName,
                deliveryLocation: value.deliveryLocation,
            },
            status: value.status ?? "Order Received",
            requiredDeliveryDate: ConvertFullDateToShort(
                value.requestedShipDate,
            ),
            incoterms: value.incoTerms1Description,
        };
        return OrderItem;
    });
    return OrderHistoryDataMapped;
}

export function FormatDeliveryStateInformation(
    city: string,
    region: string,
): string {
    const deliveryInformationSeparator = ", ";
    return `${city ? city.toUpperCase() : ""}${
        city && region ? deliveryInformationSeparator : ""
    }${region ? region.toUpperCase() : ""}`;
}

// Function that controls the data size and display based on content obtained from the api or mock data.
export function dataRendering(
    displayAmount: number,
    currentPage: number,
    OrderHistoryItemsList: OrderHistoryItem[],
) {
    const currentPageIndex = displayAmount * currentPage - displayAmount;
    const endPageIndex =
        OrderHistoryItemsList.length >= currentPageIndex + displayAmount
            ? currentPageIndex + displayAmount
            : OrderHistoryItemsList.length;
    const dataResult = OrderHistoryItemsList.slice(
        currentPageIndex,
        endPageIndex,
    );

    return dataResult;
}
