import { Tooltip } from "@cpchem/covalence-ui";
import { POInformation } from "@models/order-history-item";
import React from "react";

export function RenderPOInformation(
    poInformation: POInformation,
    testId?: string,
): JSX.Element {
    const { customerPO, poDate } = poInformation;

    return customerPO.length > 10 ? (
        <Tooltip
            content={poInformation.customerPO}
            className="tooltip-ecom"
            enterDelay="medium"
            testId={testId}
        >
            {GetPOInformationBlock(
                `${poInformation.customerPO.substring(0, 9)}...`,
                poDate,
                testId,
            )}
        </Tooltip>
    ) : (
        GetPOInformationBlock(customerPO, poDate, testId)
    );
}

function GetPOInformationBlock(
    customerPO: string,
    poDate: string,
    testId?: string,
): JSX.Element {
    return (
        <>
            <span
                id={`customerPoField-${customerPO}`}
                className="customer-po "
                data-testid={testId ? `${testId}-po-number` : ""}
            >
                {customerPO}
            </span>
            <label style={{ fontSize: ".8em", color: "gray" }}>
                {`${poDate}`}
            </label>
        </>
    );
}
