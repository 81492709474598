import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { OrderHistoryItemsRaw } from "@models/order-history-item";
import { defaultDateFilter, DateFilterItem } from "@components/filters";
import { APIQueryOptions, APISearchQueryOptions } from "@models/orders";
import { FetchAndReturnJson } from "@utilities/api";
import {
    buildOrderHistoryRequestURL,
    buildOrderHistorySearchRequestURL,
    sortValue,
} from "@utilities/pages/order-history";
import { ApiOptionsServiceKey, ServiceLocator } from "@services";
import { ApiOptionsService } from "@services/api-options";
import { DataGridColumnSortState } from "@components/display/orders/content/constants";

type OrderRequestStatus =
    | "loading"
    | "reset"
    | "update"
    | "partial"
    | "error"
    | "loaded";

export type OrderHistoryItemSliceState = {
    status: OrderRequestStatus;
    mode: "order" | "search";
    searchTerm: string;
    error: string | null;
    count: number;
    list: OrderHistoryItemsRaw[];
    pageNumber: number;
    rowsToDisplay: number;
    orderDateRange: DateFilterItem;
    sortValue: sortValue;
};

type ErrorResponse = {
    status: OrderRequestStatus;
    error: string;
};

const defaultSortValue: sortValue = {
    column: "orderCreationDate",
    sort: DataGridColumnSortState.DESC,
    type: "datetime",
};

const initialState: OrderHistoryItemSliceState = {
    list: [],
    error: null,
    count: 0,
    status: "reset",
    mode: "order",
    searchTerm: "",
    pageNumber: 1,
    rowsToDisplay: 25,
    orderDateRange: defaultDateFilter,
    sortValue: defaultSortValue,
};

export const getOrderHistorySearchResults = createAsyncThunk(
    "orderHistory/getOrderHistorySearchResults",
    async (querySearchOptions: APISearchQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const url = buildOrderHistorySearchRequestURL(querySearchOptions);
        const jsonResponse = await FetchAndReturnJson(url, options);
        return jsonResponse;
    },
);

export const getOrderHistoryItems = createAsyncThunk(
    "orderHistory/getOrderHistoryItems",
    async (queryOptions: APIQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const url = buildOrderHistoryRequestURL(queryOptions);
        const jsonResponse = await FetchAndReturnJson(url, options);
        return jsonResponse;
    },
);

export function onOrderHistoryItemsFulfilled(
    state: OrderHistoryItemSliceState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
) {
    const { payload } = action;
    if (payload.status !== undefined && payload.status >= 400) {
        const { status, error } = ResolveErrorResponseStatus(payload.status);
        state.status = status;
        state.error = error;
    } else if (payload.status === 200) {
        state.list = !payload.content.value
            ? payload.content
            : payload.content.value;
        state.count = payload.content["@odata.count"];
        state.status = "loaded";
    } else {
        state.status = "error";
        state.error = "Error getting Order History Items.";
    }
}

export function ResolveErrorResponseStatus(status: number): ErrorResponse {
    if (status === 400) {
        return {
            status: "error",
            error: "A value in your request is wrong. Try again with other values in your filters.",
        };
    }
    if (status === 401) {
        return { status: "error", error: "You are unauthorized." };
    }
    if (status === 404) {
        return {
            status: "error",
            error: "No Order History items were found with this options.",
        };
    }
    return { status: "error", error: "Error getting Order History Items." };
}

export const OrderHistoryItemsSlice = createSlice({
    name: "orderHistory",
    initialState,
    reducers: {
        pageUpdate(state) {
            state.status = "update";
        },
        pageReset(state) {
            state.pageNumber = 1;
            state.status = "reset";
            state.mode = "order";
            state.searchTerm = "";
            state.orderDateRange = defaultDateFilter;
            state.sortValue = defaultSortValue;
        },
        pagePartial(state) {
            state.pageNumber = 1;
            state.status = "partial";
        },
        pageOrderMode(state) {
            state.mode = "order";
        },
        pageSearchMode(state) {
            state.mode = "search";
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setRowsToDisplay(state, action) {
            state.pageNumber = 1;
            state.rowsToDisplay = action.payload;
        },
        setOrderDateRange(state, action) {
            state.pageNumber = 1;
            state.orderDateRange = action.payload;
        },
        setSortValue(state, action) {
            state.sortValue = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrderHistoryItems.pending, (state) => {
            state.status = "loading";
            state.error = null;
        });

        builder.addCase(
            getOrderHistoryItems.fulfilled,
            onOrderHistoryItemsFulfilled,
        );

        builder.addCase(getOrderHistoryItems.rejected, (state) => {
            state.status = "error";
            state.error = "Error getting Order History Items";
        });

        builder.addCase(getOrderHistorySearchResults.pending, (state) => {
            state.status = "loading";
            state.error = null;
        });

        builder.addCase(
            getOrderHistorySearchResults.fulfilled,
            onOrderHistoryItemsFulfilled,
        );

        builder.addCase(getOrderHistorySearchResults.rejected, (state) => {
            state.status = "error";
            state.error = "Error getting Order History Items";
        });
    },
});

export const {
    pageUpdate,
    pageReset,
    pagePartial,
    pageOrderMode,
    pageSearchMode,
    setSearchTerm,
    setPageNumber,
    setRowsToDisplay,
    setOrderDateRange,
    setSortValue,
} = OrderHistoryItemsSlice.actions;

export const selectOrderHistoryItemsStatus = (state: RootState) =>
    state.OrderHistoryItems.status;
export const selectOrderHistorySearchTerm = (state: RootState) =>
    state.OrderHistoryItems.searchTerm;
export const selectOrderHistoryMode = (state: RootState) =>
    state.OrderHistoryItems.mode;
export const selectPageNumber = (state: RootState) =>
    state.OrderHistoryItems.pageNumber;
export const selectSortValue = (state: RootState) =>
    state.OrderHistoryItems.sortValue;
export const selectRowsToDisplay = (state: RootState) =>
    state.OrderHistoryItems.rowsToDisplay;
export const selectOrderDateRange = (state: RootState) =>
    state.OrderHistoryItems.orderDateRange;
export const selectOrderHistoryItems = (state: RootState) =>
    state.OrderHistoryItems.list;
export const selectOrderHistoryItemsCount = (state: RootState) =>
    state.OrderHistoryItems.count;

export default OrderHistoryItemsSlice.reducer;
