import { ItemDetails, ItemDetailsRaw, LineItems } from "@models";
import { ScheduleLine, ScheduleLineRaw } from "@models/item-details";
import { ConvertFullDateToShort } from "@utilities/dates/date-format-helper";
import { GetFormattedNumber } from "@utilities/numbers/number-format-helper";

export function TransformItemDetailsRaw(data: ItemDetailsRaw): ItemDetails {
    const lineItemsList: LineItems[] = [];
    data.lineItems.forEach((item) => {
        lineItemsList.push({
            items: [
                item.salesDocumentItem.toString(),
                item.materialName,
                item.materialNumber.toString(),
                item.customerMaterialNumber,
            ],
            status: item.status ?? "Order Received",
            itemQuantity: ` ${GetFormattedNumber(item.itemQuantity)} ${
                item.unitOfMeasure
            }`,
            totalConfirmedQuantity: ` ${GetFormattedNumber(
                item.totalConfirmedQuantity,
            )} ${item.unitOfMeasure}`,
            totalLeftQuantity: `${GetFormattedNumber(
                item.itemQuantity - item.totalConfirmedQuantity,
            )} ${item.unitOfMeasure}`,
            estimatedShipmentDate: [
                ConvertFullDateToShort(item.estimatedShipDate),
                item.scheduleLines.length.toString(),
            ],
            shipFrom: [
                item.shipFromPlantCode,
                item.shipFromCity,
                item.shipFromRegion,
            ],
            shipments: item.deliveryCount,
            scheduleLines: GetScheduleLines(
                item.scheduleLines,
                item.unitOfMeasure,
            ),
        });
    });

    const finalData: ItemDetails = {
        poNumber: data.customerPurchaseOrderNumber,
        poDate: ConvertFullDateToShort(data.customerPurchaseOrderDate),
        orderNumber: data.salesDocumentNumber.toString(),
        quoteNumber: data.assignedQuoteNumber,
        items: data.lineItemCount,
        deliverTo: [data.shipToPartyName, data.deliveryLocation],
        requestedDeliveryDate: ConvertFullDateToShort(data.requestedShipDate),
        incoterms: data.incoTerms1Description,
        lineItems: lineItemsList,
    };

    return finalData;
}

function GetScheduleLines(
    scheduleLinesRawItem: ScheduleLineRaw[],
    itemUnit: string,
): ScheduleLine[] {
    const scheduleLinesResult: ScheduleLine[] = [];
    scheduleLinesRawItem.forEach((item) => {
        scheduleLinesResult.push({
            id: item.scheduleLine,
            date: ConvertFullDateToShort(item.scheduleLineDate),
            confirmedQuantity: `${GetFormattedNumber(
                item.confirmedQuantity,
            )} ${itemUnit}`,
        });
    });
    return scheduleLinesResult;
}
