const invalidShipmentRouteCodes = ["CUST", "TAPIT", "10000", "CACAR", "XXXXX"];

export function DisableShipmentTrackingButton(
    /* A string value indicating the route code returned from the api. */
    routeCode: string,
    /* A string indicating the shipment status code returned from the api. */
    shippingStatus: string,
): boolean {
    /* validate the result for the invalid route codes. */
    const validateCode = invalidShipmentRouteCodes.includes(routeCode);
    /* validate if the shipping status is 'C' or not. */
    const validateStatus = shippingStatus !== "C";

    /* Return the result of the previous validations to enable or not the shipment tracking button. */
    return validateCode || validateStatus;
}
