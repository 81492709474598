import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/pro-light-svg-icons";
import { ProductLine_Checkbox_Elements } from "./elements";

import "./styles.scss";

export interface ProductLineCheckboxProps {
    id: string;
    tabindex: number;
    icon: string;
    label: string;
    checked: boolean;
    testId?: string;
    onChange: (id: string) => void;
}

interface ProductLineIconProps {
    icon: string;
    iconTestId?: string;
}

interface ProductLineNameDisplayProps {
    label: string;
    labelTestId?: string;
}

function ProductLineCheckbox({
    id,
    tabindex,
    icon,
    label,
    checked,
    testId,
    onChange,
}: ProductLineCheckboxProps) {
    const productLineIconTestId = testId
        ? `${testId}-${ProductLine_Checkbox_Elements.Product_Line_Icon}`
        : undefined;
    const labelTestId = testId
        ? `${testId}-${ProductLine_Checkbox_Elements.Product_Line_Label}`
        : undefined;
    const checkIconTestId = testId ? `${testId}-check` : undefined;
    const plusIconTestId = testId ? `${testId}-plus` : undefined;
    const inputTestId = testId
        ? `${testId}-${ProductLine_Checkbox_Elements.Product_Line_Input}`
        : undefined;
    const indicatorTestId = testId
        ? `${testId}-${ProductLine_Checkbox_Elements.Product_Line_Indicator}`
        : undefined;
    const containerTestId = testId
        ? `${testId}-${ProductLine_Checkbox_Elements.Product_Line_Container}`
        : undefined;

    const PlusIcon = function () {
        return (
            <div data-testid={plusIconTestId}>
                <FontAwesomeIcon
                    icon={faPlus}
                    aria-hidden="true"
                    className="fa-xl fa-plus"
                />
            </div>
        );
    };

    const CheckedDisplayIcon = () => {
        return (
            <div data-testid={checkIconTestId}>
                <FontAwesomeIcon
                    icon={faCheck}
                    aria-hidden="true"
                    className="fa-xl fa-check"
                />
            </div>
        );
    };

    const ProductLineIcon = (props: ProductLineIconProps) => {
        return (
            <div
                className={`productline-checkbox-icon `}
                data-testid={props.iconTestId}
            >
                <div className={`regular ${props.icon}`} />
            </div>
        );
    };

    const ProductLineNameDisplay = (props: ProductLineNameDisplayProps) => {
        return (
            <div
                className="productline-checkbox-label"
                data-testid={props.labelTestId}
            >
                {props.label}
            </div>
        );
    };

    return (
        <label
            className={`productline-checkbox-container ${
                checked ? "selected" : ""
            }`}
            data-testid={containerTestId}
            tabIndex={tabindex}
        >
            <input
                type="checkbox"
                className="productline-checkbox"
                checked={checked}
                data-testid={inputTestId}
                onChange={() => onChange(id)}
            />

            <ProductLineIcon icon={icon} iconTestId={productLineIconTestId} />
            <ProductLineNameDisplay label={label} labelTestId={labelTestId} />

            <div
                className="productline-checkbox-indicator"
                data-testid={indicatorTestId}
            >
                <div>{checked ? <CheckedDisplayIcon /> : <PlusIcon />}</div>
            </div>
        </label>
    );
}

export default React.memo(ProductLineCheckbox);
