import { createContext } from "react";
import { Toast, ToastId, ToastMessage, ToastOptions } from "./model";

export interface ToastContextProps {
    /**
     * List of all active toasts.
     */
    toasts: Toast[];
    /**
     * Requests to create a toast and add it to state with specified options.
     */
    onRequestCreate: (message: ToastMessage, options?: ToastOptions) => void;

    /**
     * Requests to close a toast based on its id
     */
    onRequestDismiss: (id: ToastId) => void;

    /**
     * Requests to close all toasts at once.
     */
    onRequestDismissAll: () => void;
}

export const ToastContext = createContext<ToastContextProps>({
    toasts: [],
    onRequestCreate: () => "",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onRequestDismiss: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onRequestDismissAll: () => {},
});
