import React from "react";

function BaseHeaderContent(): JSX.Element {
    return (
        <>
            <th>
                <span className="header-cell justify-left items">Items</span>
            </th>
            <th>
                <span className="header-cell justify-left status">Status</span>
            </th>
            <th>
                <span className="header-cell justify-right item-qty">
                    Item Qty
                </span>
            </th>
            <th>
                <span className="header-cell justify-right total-qty">
                    Total Confirmed Qty
                </span>
            </th>
            <th>
                <span className="header-cell justify-left ship-date">
                    Est. Ship Date
                </span>
            </th>
            <th>
                <span className="header-cell justify-left ship-from">
                    Ship From
                </span>
            </th>
            <th>
                <span className="header-cell justify-right shipments">
                    Shipments
                </span>
            </th>
            <th>
                <span className="header-cell justify-left actions" />
            </th>
        </>
    );
}

export function HeaderRendererFull(): JSX.Element {
    return (
        <thead className="is-sticky">
            <tr>{BaseHeaderContent()}</tr>
        </thead>
    );
}
