import { AccountInformation, AuthManager, AuthState } from "@cpchem/azure-auth";
import { useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import config from "@config";

export interface UseAuthReturn {
    authState: AuthState;
    currentAccount: AccountInformation | null;
    currentToken: string | null;
}

export function useAuth(): UseAuthReturn {
    const authMgr = AuthManager.getInstance();

    const [authState, setAuthState] = useState(authMgr.getAuthState());
    const [currentAccount, setCurrentAccount] = useState(authMgr.getAccount());
    const [currentToken, setCurrentToken] = useState<string | null>(null);

    useEffect(() => {
        function handleLoggedIn() {
            setAuthState(authMgr.getAuthState());
            setCurrentAccount(authMgr.getAccount());
            authMgr
                .getApiToken(config.msal.scopes)
                .then((value) => setCurrentToken(value));
        }
        function handleLoggedOut() {
            setAuthState(authMgr.getAuthState());
            setCurrentAccount(null);
        }
        function handleRedirecting() {
            setAuthState(authMgr.getAuthState());
            setCurrentAccount(authMgr.getAccount());

            const restoredUrl = authMgr.getRestoreState();
            if (restoredUrl) {
                redirect(restoredUrl);
            }
        }
        function handleFailure() {
            setAuthState(authMgr.getAuthState());
        }

        authMgr.subscribe("failure", handleFailure);
        authMgr.subscribe("loggedIn", handleLoggedIn);
        authMgr.subscribe("loggedOut", handleLoggedOut);
        authMgr.subscribe("redirecting", handleRedirecting);

        return function cleanup() {
            authMgr.unsubscribe("failure", handleFailure);
            authMgr.unsubscribe("loggedIn", handleLoggedIn);
            authMgr.unsubscribe("loggedOut", handleLoggedOut);
            authMgr.unsubscribe("redirecting", handleRedirecting);
        };
    }, [authMgr]);

    return {
        authState,
        currentAccount,
        currentToken,
    };
}
