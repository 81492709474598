export const CUSTOMER_FILTER_ID = "customer";
export const EMPLOYEE_FILTER_ID = "employeeResponsible";
export const SHIPFROM_FILTER_ID = "shipFrom";
export const LOCATION_FILTER_ID = "deliveryLocation";
export const PRODUCT_FILTER_ID = "productDescription";
export const CUSTOMER_FILTER_TITLE = "Customer";
export const EMPLOYEE_FILTER_TITLE = "Employee Responsible";
export const SHIPFROM_FILTER_TITLE = "Ship From";
export const LOCATION_FILTER_TITLE = "Delivery Location";
export const PRODUCT_FILTER_TITLE = "Product Description";
