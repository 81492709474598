import { FilterListItem } from "../../models/filters";
import { DateRangeItem } from "../../components/filters/types";

export const hasCheckedFilter = (
    employeeFilters: FilterListItem[],
    customerFilters: FilterListItem[],
    productDescriptionFilters: FilterListItem[],
    shipFromFilters: FilterListItem[],
    deliveryLocationFilters: FilterListItem[],
    statusFilters: FilterListItem[],
    poDateFilters: DateRangeItem[],
    requestedDeliveryDateFilters: DateRangeItem[],
): boolean => {
    let hasCheckedFilter = false;

    employeeFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });

    customerFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });

    productDescriptionFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });

    shipFromFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });

    deliveryLocationFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });

    statusFilters.filter((item) => {
        if (item.checked === true) {
            hasCheckedFilter = true;
        }
    });

    poDateFilters.filter((item) => {
        if (item.checked === true && item.id !== "All") {
            hasCheckedFilter = true;
        }
    });

    requestedDeliveryDateFilters.filter((item) => {
        if (item.checked === true && item.id !== "All") {
            hasCheckedFilter = true;
        }
    });

    return hasCheckedFilter;
};
