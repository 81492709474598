import {
    LogEvent,
    LogManager,
    logsAtOrAbove,
    LogLevel,
    createAppInsightsSink,
} from "@cpchem/logging";
import config from "@config";
import { ecomEventSinks } from "./ecom-appinsights-events";
const LOGGING_CONTEXT = {
    ecomSessionId: "",
    who: "",
};
// const values for app insight filter system
const DO_NOT_SEND = false;
const APPROVED_TO_SEND = true;

export function configureLogging(): void {
    const environmentSpecificLogFilter = logsAtOrAbove(LogLevel.WARN);

    LogManager.current().subscribeToLogs(
        consoleLogger,
        environmentSpecificLogFilter,
    );
}

export function configureAppInsightsLogging(ecomSessionId: string) {
    LOGGING_CONTEXT.ecomSessionId = ecomSessionId ?? "";
    const commonSinkBehaviors = (event: LogEvent) => {
        const eventSpecificDetails = ecomEventSinks[event.type]
            ? ecomEventSinks[event.type](event)
            : {};
        return { ...eventSpecificDetails, ...LOGGING_CONTEXT };
    };

    LogManager.current().subscribeToEvents(
        createAppInsightsSink(commonSinkBehaviors),
        (type) => ecomEventSinks[type.toUpperCase()] !== undefined,
    );

    if (config.logging.logRocketAppId !== "") {
        AddLogManagerAppInsightFilter(config.logging.logRocketAppId);
    }
}

function consoleLogger(level: string, message: string): void {
    console.log(`${level}: ${message}`);
}

export function setUserInLoggingContext(user: string) {
    LOGGING_CONTEXT.who = user;
}

export function getEcomSessionId(): string {
    return LOGGING_CONTEXT.ecomSessionId;
}

function AddLogManagerAppInsightFilter(filter: string) {
    LogManager.appInsights().appInsights.addTelemetryInitializer((item) => {
        const isFilterTargetFound =
            item?.baseData?.target !== undefined
                ? item?.baseData?.target.indexOf(encodeURIComponent(filter)) >=
                  0
                : false;

        if (isFilterTargetFound) {
            return DO_NOT_SEND;
        }
        return APPROVED_TO_SEND;
    });
}
