import { Badge } from "@cpchem/covalence-ui";
import { FilterListItem } from "@models";
import { ConvertFullDateToShort } from "@utilities/dates/date-format-helper";
import React from "react";
import { DateRangeItem } from "../types";

export interface FilterBadgesProps {
    className?: string;
    isDateRange?: boolean;
    onDismiss: (id: string, title: string) => void;
    filters: FilterListItem[] | DateRangeItem[];
    title: string;
    testId?: string;
}

export function FilterBadges({
    className,
    onDismiss,
    filters,
    title,

    testId,
    isDateRange,
}: FilterBadgesProps): JSX.Element {
    const cnParts = ["filter-badge"];

    if (className) {
        cnParts.push(className);
    }
    if (!isDateRange) {
        cnParts.push("text-filter-badge");
    }
    if (isDateRange) {
        cnParts.push("date-filter-badge");
    }

    function onBadgeDismiss(id: string, title: string) {
        onDismiss(id, title);
    }

    const textFilterTestId = testId ? `${testId}-text-filter-badge` : undefined;
    const dateFilterTestId = testId ? `${testId}-date-filter-badge` : undefined;

    const cn = cnParts.join(" ");

    const selectedFilters: JSX.Element[] = [];

    if (isDateRange) {
        const dateFilters = filters as DateRangeItem[];
        dateFilters.map((value) => {
            if (value.id !== "All" && value.checked === true) {
                const formattedStartDate = ConvertFullDateToShort(
                    `${value.selectedValues.startDate}T00:00:00`,
                );
                const formattedEndDate = ConvertFullDateToShort(
                    `${value.selectedValues.endDate}T00:00:00`,
                );

                const dateRangeText = `${formattedStartDate} to ${formattedEndDate}`;
                selectedFilters.push(
                    <div
                        className={cn}
                        data-testid={`${testId}-date-${value.id}`}
                        key={`${title}-${value.id}`}
                    >
                        <Badge
                            isDismissible
                            onDismissRequested={() =>
                                onBadgeDismiss(value.id, title)
                            }
                            title={title}
                            size="medium"
                            text={
                                value.id === "Date range"
                                    ? `${dateRangeText}`
                                    : `${value.id}`
                            }
                            color="blue"
                            variant="subtle"
                            testId={`${dateFilterTestId}-${title}-${value.id}`}
                        />
                    </div>,
                );
            }
        });
    } else {
        filters.map((value) => {
            if (
                value.checked === true &&
                value.id !== "All" &&
                value.id !== "Date range"
            ) {
                selectedFilters.push(
                    <div
                        className={cn}
                        data-testid={`${testId}-text-${value.id}`}
                        key={value.id}
                    >
                        <Badge
                            isDismissible
                            onDismissRequested={() =>
                                onBadgeDismiss(value.id, title)
                            }
                            title={title}
                            text={value.name}
                            size="medium"
                            color="blue"
                            variant="subtle"
                            testId={`${textFilterTestId}-${value.id}`}
                        />
                    </div>,
                );
            }
        });
    }

    return <>{selectedFilters}</>;
}
