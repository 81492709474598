import { AriaSortType } from "@cpchem/covalence-ui";
import { ColumnDetailList } from "./types";

interface SortToAria {
    [key: string]: AriaSortType;
}

interface SortToDirection {
    [key: string]: string;
}

export const ecomColumnDetailList: ColumnDetailList = {
    poInformation: { sortBy: "customerPurchaseOrderNumber", textAlign: "left" },
    orderInformation: {
        sortBy: "salesDocumentNumber",
        textAlign: "left",
    },
    salesOrderCreateDate: {
        sortBy: "orderCreationDate",
        textAlign: "left",
    },
    items: { sortBy: "lineItemCount", textAlign: "right" },
    deliveryInformation: {
        sortBy: "shipToPartyName",
        textAlign: "left",
    },
    status: { sortBy: "status", textAlign: "left" },
    requiredDeliveryDate: {
        sortBy: "requestedShipDate",
        textAlign: "left",
    },
    incoterms: { sortBy: "incoTerms1Description", textAlign: "left" },
};

export enum DataGridColumnSortState {
    ASC = "ascending",
    DESC = "descending",
    WAIT = "waiting to sort",
    DISABLED = "disabled",
}

export const AriaForTypes: SortToAria = {
    [DataGridColumnSortState.ASC]: "ascending",
    [DataGridColumnSortState.DESC]: "descending",
    [DataGridColumnSortState.WAIT]: "none",
};

export const DirectionForTypes: SortToDirection = {
    [DataGridColumnSortState.ASC]: "ASC",
    [DataGridColumnSortState.DESC]: "DESC",
    [DataGridColumnSortState.WAIT]: "",
};
