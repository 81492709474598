// Landing page
export const GET_PRODUCT_LINES = "GET_PRODUCT_LINES";
export const GET_USER_PREFERENCES = "GET_USER_PREFERENCES";

// Onboarding page
export const START_USER_ONBOARDING = "START_USER_ONBOARDING";
export const FINISH_USER_ONBOARDING = "FINISH_USER_ONBOARDING";

// Orders page
export const LOADING_ORDERS = "LOADING_ORDERS";
export const LOADING_FILTER_LIST = "LOADING_FILTER_LIST";

// Item details page
export const LOADING_ITEM_DETAILS = "LOADING_ITEM_DETAILS";

// Shipment Tracking page
export const SHIPMENT_DETAILS_LOADING = "SHIPMENT_DETAILS_LOADING";
export const SHIPMENT_TRACKING_DIALOG_LOADING =
    "SHIPMENT_TRACKING_DIALOG_LOADING";

// Search Tracking
export const SEARCH_FIELD_REQUEST = "SEARCH_FIELD_REQUEST";
export const LOADING_SEARCH_ORDERS = "LOADING_SEARCH_ORDERS";

// User Preferences
export const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES";

// Download Events
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DOCUMENT_LIST_LOADING = "DOCUMENT_LIST_LOADING";

// Error page triggering
export const ERROR_PAGE_AUTHORIZATION = "ERROR_PAGE_AUTHORIZATION";
export const ERROR_PAGE_TECHNICAL_ISSUE = "ERROR_PAGE_TECHNICAL_ISSUE";
