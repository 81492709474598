import React from "react";
import { DataGridRow } from "@cpchem/covalence-ui";
import { OrderHistoryItem } from "@models/order-history-item";
import { RenderPill } from "@utilities/status-helper";
import { Link } from "react-router-dom";
import DocumentDropdown from "../../../../documents/document-dropdown";
import { RenderPOInformation } from "./po-information";
import { OrderRow } from "./elements";

function RenderOrderHistoryDownloadMenu(row: DataGridRow): JSX.Element {
    const { orderInformation } = row.values as OrderHistoryItem;

    return (
        <DocumentDropdown
            id={orderInformation.salesDocumentNumber}
            system={"ORDERS"}
            testId={`${OrderRow.DownloadDocumentDropdown}-${orderInformation.salesDocumentNumber}`}
        />
    );
}

function GetBaseColumnsRow(row: DataGridRow): JSX.Element {
    const {
        poInformation,
        salesOrderCreateDate,
        orderInformation,
        items,
        deliveryInformation,
        status,
    } = row.values as OrderHistoryItem;
    return (
        <>
            <td className="po-information-cell left">
                {RenderPOInformation(poInformation)}
            </td>
            <td className="order-information-cell left">
                <span className="orders-link">
                    <Link
                        to={`/orders/${orderInformation.salesDocumentNumber}`}
                        state={orderInformation.salesDocumentNumber}
                    >
                        {orderInformation.salesDocumentNumber}
                    </Link>
                </span>

                <br />
                <label style={{ fontSize: ".8em", color: "gray" }}>
                    {orderInformation.assignedQuoteNumber === ""
                        ? ""
                        : `Quote: ${orderInformation.assignedQuoteNumber}`}
                </label>
            </td>
            <td className="order-create-date-cell left">
                {salesOrderCreateDate}
            </td>
            <td className="items-cell right">{items}</td>
            <td className="deliver-to-cell left">
                <label>{deliveryInformation.shipToPartyName}</label>
                <br />
                <label style={{ fontSize: ".8em", color: "gray" }}>
                    {deliveryInformation.deliveryLocation}
                </label>
            </td>
            <td className="status-cell left">{RenderPill(status)}</td>
        </>
    );
}

function GetRequestedDeliveryColumn(row: DataGridRow): JSX.Element {
    const { requiredDeliveryDate } = row.values as OrderHistoryItem;
    return (
        <td className="requested-delivery-date-cell left">
            {requiredDeliveryDate}
        </td>
    );
}

function GetIncotermColumn(row: DataGridRow): JSX.Element {
    const { incoterms } = row.values as OrderHistoryItem;
    return <td className="incoterms-cell left">{incoterms}</td>;
}

function GetActionsMenuColumn(row: DataGridRow): JSX.Element {
    return (
        <td className="left">
            <div
                className="action-menu"
                style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                }}
            >
                {RenderOrderHistoryDownloadMenu(row)}
            </div>
        </td>
    );
}

export function ResultRowFull(row: DataGridRow): JSX.Element {
    const { poInformation, orderInformation } = row.values as OrderHistoryItem;
    return (
        <tr
            key={`${orderInformation.salesDocumentNumber}-${poInformation.customerPO}`}
        >
            {GetBaseColumnsRow(row)}
            {GetRequestedDeliveryColumn(row)}
            {GetIncotermColumn(row)}
            {GetActionsMenuColumn(row)}
        </tr>
    );
}

export function ResultRowMedium(row: DataGridRow): JSX.Element {
    const { poInformation, orderInformation } = row.values as OrderHistoryItem;
    return (
        <tr
            key={`${orderInformation.salesDocumentNumber}-${poInformation.customerPO}`}
        >
            {GetBaseColumnsRow(row)}
            {GetIncotermColumn(row)}
            {GetActionsMenuColumn(row)}
        </tr>
    );
}

export function ResultRowSmall(row: DataGridRow): JSX.Element {
    const { poInformation, orderInformation } = row.values as OrderHistoryItem;
    return (
        <tr
            key={`${orderInformation.salesDocumentNumber}-${poInformation.customerPO}`}
        >
            {GetBaseColumnsRow(row)}
            {GetActionsMenuColumn(row)}
        </tr>
    );
}

export function FilteredEmptyResults() {
    return (
        <div className="no-results">
            <div className="no-results-content">
                <div className="no-results-title">
                    No orders match the current filters.
                </div>
                <div>
                    Expecting to see orders? Make sure all filters are applied
                    correctly.
                </div>
            </div>
        </div>
    );
}
