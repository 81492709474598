import React from "react";
import { Card } from "@cpchem/covalence-ui";

export type ShipmentDetailsHeaderDisplayProps = {
    itemNumber: string;
    itemDescription: string;
    itemMaterialNumber: number;
    itemCustomerMaterialNumber: string;
    itemQuantity: string;
    itemConfirmedQuantity: string;
    shipFrom: string;
    shipments: number;
    testId?: string;
};

export function ShipmentDetailsHeader(
    props: ShipmentDetailsHeaderDisplayProps,
): JSX.Element {
    const {
        itemNumber,
        itemDescription,
        itemMaterialNumber,
        itemCustomerMaterialNumber,
        itemQuantity,
        itemConfirmedQuantity,
        shipFrom,
        shipments,
        testId,
    } = props;
    return (
        <Card className="body-container-bar" testId={testId}>
            <div className="bar-content">
                <div className="left-content">
                    <div className="cpo-number">
                        <span className="bold">{`Item # ${itemNumber}`}</span>
                        <br />
                        <span className="product">{itemDescription}</span>
                        <br />
                        <span className="title">
                            {`CPChem Mat. #: ${itemMaterialNumber} `}{" "}
                        </span>
                        <span className="title">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span className="title">{` Customer Mat. #: ${itemCustomerMaterialNumber}`}</span>
                    </div>
                </div>
                <div className="right-content">
                    <div className="ordered-quantity">
                        <span className="title">Ordered Qty</span>
                        <br />
                        <span className="content">{itemQuantity}</span>
                    </div>
                    <div className="total-confirmed-quantity">
                        <span className="title">Total Confirmed Qty</span>
                        <br />
                        <span className="content">{itemConfirmedQuantity}</span>
                    </div>
                    <div className="ship-from">
                        <span className="title">Ship From</span>
                        <br />
                        <span className="content">{shipFrom}</span>
                    </div>
                    <div className="shipments">
                        <span className="title">Shipments</span>
                        <br />
                        <span className="content">{shipments}</span>
                    </div>
                </div>
            </div>
        </Card>
    );
}
