import React from "react";
import "./styles.scss";

// Note: This file is designed to be easily refactored to be used in other spaces, feel free to modify as needed/desired.

export interface OnboardingHeroBannerProps {
    testId?: string;
    title?: string;
    subtext?: string;
    backgroundClassName: string;
}

export function OnboardingHeroBanner({
    testId,
    title = "Getting Started",
    subtext = "Welcome to CustomerConnect! Let's set up your account to personalize your order results list.",
    backgroundClassName,
}: OnboardingHeroBannerProps): JSX.Element {
    const cnParts = ["hero-banner"];
    if (backgroundClassName) {
        cnParts.push("background-image");
        cnParts.push(`${backgroundClassName}-background`);
    }
    const cn = cnParts.join(" ");

    return (
        <div className={cn} data-testid={testId}>
            <div className="hero-text">
                <p className="hero-title">{title}</p>
                <p className="hero-subtext">{subtext}</p>
            </div>
        </div>
    );
}
