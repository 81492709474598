import { LogEvent } from "@cpchem/logging";
import {
    DOWNLOAD_FILE,
    GET_PRODUCT_LINES,
    GET_USER_PREFERENCES,
    FINISH_USER_ONBOARDING,
    START_USER_ONBOARDING,
    LOADING_ORDERS,
    LOADING_FILTER_LIST,
    LOADING_ITEM_DETAILS,
    LOADING_SEARCH_ORDERS,
    SEARCH_FIELD_REQUEST,
    DOCUMENT_LIST_LOADING,
    SHIPMENT_DETAILS_LOADING,
    SHIPMENT_TRACKING_DIALOG_LOADING,
    UPDATE_USER_PREFERENCES,
} from "./log-events";

type sinkDefinition = (event: LogEvent) => Record<string, unknown>;

const standardEventSink: sinkDefinition = (event: LogEvent) => {
    return { prop: { ...parseEventContent(event.contents) } };
};

interface sinkDrawer {
    [key: string]: sinkDefinition;
}

function parseEventContent(contents: string) {
    try {
        const parsedObject = JSON.parse(contents);
        return parsedObject;
    } catch (e) {
        return {};
    }
}

export const ecomEventSinks: sinkDrawer = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [GET_PRODUCT_LINES]: (event: LogEvent) => {
        return {};
    },
    [GET_USER_PREFERENCES]: standardEventSink,
    [START_USER_ONBOARDING]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };
        return {
            prop: {
                catalog: parsed.catalog,
                productsSelected: parsed.productLinesSelected,
            },
        };
    },
    [FINISH_USER_ONBOARDING]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };
        return {
            prop: {
                catalog: parsed.catalog,
                productsSelected: parsed.productLinesSelected,
            },
        };
    },
    [LOADING_ORDERS]: standardEventSink,
    [LOADING_ITEM_DETAILS]: standardEventSink,
    [LOADING_FILTER_LIST]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };
        return {
            prop: {
                source: parsed.source,
                field: parsed.field,
                userProducts: parsed.productLines,
                dateSelection: parsed.dataRange,
            },
        };
    },
    [SEARCH_FIELD_REQUEST]: standardEventSink,
    [LOADING_SEARCH_ORDERS]: standardEventSink,
    [DOWNLOAD_FILE]: standardEventSink,
    [DOCUMENT_LIST_LOADING]: standardEventSink,
    [SHIPMENT_DETAILS_LOADING]: standardEventSink,
    [SHIPMENT_TRACKING_DIALOG_LOADING]: standardEventSink,
    [UPDATE_USER_PREFERENCES]: standardEventSink,
};
