import { Toast, ToastId, ToastMessage, ToastOptions } from "./model";

const defaultToastOptions: ToastOptions = {
    dismissable: true,
    autoDismiss: true,
    autoDismissDelay: 3000,
};

export function createToast(
    message: ToastMessage,
    incomingOptions?: ToastOptions,
): Toast {
    const idCreatedFromTime = `id-${new Date().getTime()}`;
    return {
        id: idCreatedFromTime,
        isActive: true,
        message,
        ...defaultToastOptions,
        ...incomingOptions,
    } as Toast;
}

export function removeToastById(
    activeToasts: Toast[],
    toastIdToRemove: ToastId,
): Toast[] {
    return activeToasts.filter((toast) => toast.id !== toastIdToRemove);
}

export function requestDismissToast(
    activeToasts: Toast[],
    id: ToastId,
): Toast[] {
    return activeToasts.map((toast) => {
        if (toast.id === id) {
            return {
                ...toast,
                requestDismiss: true,
                isActive: false,
            };
        }
        return toast;
    });
}
