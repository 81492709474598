import {
    getFormattedDateRange,
    getLastQuarter,
    getCurrentMonth,
    getLastMonth,
} from "@utilities/dates";
import { DateRangeItem } from "../types";

const currentTime = new Date();
const formattedCurrentDateRange = getFormattedDateRange(
    currentTime,
    currentTime,
);
const currentQuarter = getLastQuarter(
    currentTime.getMonth() + 1,
    currentTime.getFullYear(),
);
const currentMonth = getCurrentMonth();

const lastMonth = getLastMonth();

const currentTimeString = `${currentTime}`;

export const DefaultPoDateFilterData: DateRangeItem[] = [
    {
        name: "All",
        id: "All",
        title: "PO Date",
        checked: true,
        selectedValues: { id: "All", startDate: "", endDate: "" },
    },
    {
        name: "Current month",
        id: "Current month",
        title: "PO Date",
        checked: false,
        selectedValues: {
            id: "Current month",
            startDate: currentMonth.startDate,
            endDate: currentMonth.endDate,
        },
    },
    {
        name: "Last month",
        id: "Last month",
        title: "PO Date",
        checked: false,
        selectedValues: {
            id: "Last month",
            startDate: lastMonth.startDate,
            endDate: lastMonth.endDate,
        },
    },
    {
        name: "Last quarter",
        id: "Last quarter",
        title: "PO Date",
        checked: false,
        selectedValues: {
            id: "Last quarter",
            startDate: currentQuarter.startDate,
            endDate: currentQuarter.endDate,
        },
    },
    {
        name: "Date range",
        id: "Date range",
        title: "PO Date",
        checked: false,
        selectedValues: {
            id: "Date range",
            startDate: formattedCurrentDateRange.startDate,
            endDate: formattedCurrentDateRange.endDate,
        },
        rawStartDate: currentTimeString,
        rawEndDate: currentTimeString,
    },
];

export const DefaultRequestedDateFilterData: DateRangeItem[] = [
    {
        name: "All",
        id: "All",
        title: "Requested Delivery",
        checked: true,
        selectedValues: { id: "All", startDate: "", endDate: "" },
    },
    {
        name: "Current month",
        id: "Current month",
        title: "Requested Delivery",
        checked: false,
        selectedValues: {
            id: "Current month",
            startDate: currentMonth.startDate,
            endDate: currentMonth.endDate,
        },
    },
    {
        name: "Last month",
        id: "Last month",
        title: "Requested Delivery",
        checked: false,
        selectedValues: {
            id: "Last month",
            startDate: lastMonth.startDate,
            endDate: lastMonth.endDate,
        },
    },
    {
        name: "Last quarter",
        id: "Last quarter",
        title: "Requested Delivery",
        checked: false,
        selectedValues: {
            id: "Last quarter",
            startDate: currentQuarter.startDate,
            endDate: currentQuarter.endDate,
        },
    },
    {
        name: "Date range",
        id: "Date range",
        title: "Requested Delivery",
        checked: false,
        selectedValues: {
            id: "Date range",
            startDate: formattedCurrentDateRange.startDate,
            endDate: formattedCurrentDateRange.endDate,
        },
        rawStartDate: currentTimeString,
        rawEndDate: currentTimeString,
    },
];
