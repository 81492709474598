import { AuthManager } from "@cpchem/azure-auth";
import config from "@config";

export async function getGraphUserPhoto(
    profilePhoto: string | null,
): Promise<string | null> {
    const authMgr = AuthManager.getInstance();
    const token = await authMgr.getApiToken(config.graph.photo.scopes);
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers,
        encoding: null,
    };

    const responsePhotoRequest = await fetch(
        config.graph.photo.endpoint,
        options,
    );
    if (responsePhotoRequest.ok) {
        const photoBlob = await responsePhotoRequest.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (profilePhoto !== (reader.result as string)) {
                    resolve(reader.result as string);
                }
            };
            reader.onerror = () => reject(null);
            reader.readAsDataURL(photoBlob);
        });
    }
    return null;
}
