import { DateRange } from "@models";

export function buildDateFilterQuery(
    fieldName: string,
    dateRange: DateRange,
): string {
    if (dateRange.startDate === "" || dateRange.endDate === "") {
        return "";
    }

    const filterString = ` ${fieldName} ge ${dateRange.startDate} and ${fieldName} le ${dateRange.endDate} and `;
    return filterString;
}
