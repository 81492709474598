import React, { useState, useEffect } from "react";
import {
    updateUserProductLines,
    selectUserProductLines,
    selectApplicationStatus,
    selectAuthorizedProductLines,
} from "@stores/product-lines";
import { pageReset } from "@stores/order-history";
import { ProductLineCheckbox } from "@components/products";
import { Button } from "@cpchem/covalence-ui";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import "./styles.scss";
import { useToast } from "@components/toasts";
import {
    GetProductSelections,
    LoadSelectedProductLines,
    ValidateProductSelections,
} from "@utilities/pages/product-lines";
import { PreferencesElements } from "./elements";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { UPDATE_USER_PREFERENCES } from "@utilities/log/log-events";
import { createPageViewEvent } from "@cpchem/logging";

export function PreferencesPage(): JSX.Element {
    document.title = "Preferences- CustomerConnect";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userProductLinesList = useAppSelector(selectUserProductLines);
    const authorizedProductLinesList = useAppSelector(
        selectAuthorizedProductLines,
    );
    const [productLines, setProductLines] = useState(
        LoadSelectedProductLines(
            authorizedProductLinesList,
            userProductLinesList,
        ),
    );
    const [validSelection, setValidSelection] = useState(
        ValidateProductSelections(
            LoadSelectedProductLines(
                authorizedProductLinesList,
                userProductLinesList,
            ),
            productLines,
        ),
    );
    const appStatus = useAppSelector(selectApplicationStatus);
    const { createToast } = useToast();

    useEffect(() => {
        setValidSelection(
            ValidateProductSelections(
                LoadSelectedProductLines(
                    authorizedProductLinesList,
                    userProductLinesList,
                ),
                productLines,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productLines]);

    function createToastAppStatus() {
        appStatus === "ready" &&
            createToast("Update has been applied to your account.", {
                status: "success",
                autoDismissDelay: 5000,
                title: "Saved",
            });
    }

    async function updateUserProductSelection() {
        setValidSelection(false);
        const productLinesSelected = GetProductSelections(productLines);
        ecomTrackEvent({
            type: UPDATE_USER_PREFERENCES,
            contents: JSON.stringify({ productLinesSelected }),
        });
        await dispatch(
            updateUserProductLines({
                data: productLinesSelected,
            }),
        );
        dispatch(pageReset());
        createToastAppStatus();
        setTimeout(() => {
            navigate("/orders");
        }, 800);
    }

    const productSelectionChanged = (id: string) => {
        setProductLines(
            productLines.map((product) => {
                if (product.id === id) {
                    return { ...product, selected: !product.selected };
                }
                return product;
            }),
        );
    };

    const renderedProductLines = productLines.map((row, index) => (
        <ProductLineCheckbox
            key={row.id}
            tabindex={index}
            checked={row.selected}
            id={row.id}
            label={row.description}
            icon={row.iconName}
            testId={`${PreferencesElements.ProductCardPrepend}${row.id}`}
            onChange={productSelectionChanged}
        />
    ));

    const ActionButtons = () => {
        return (
            <span className="action-buttons">
                <Button
                    className="cancel-button"
                    variant="outline"
                    color="primary"
                    text="Cancel"
                    size="large"
                    onClick={() => navigate(-1)}
                    testId={PreferencesElements.CancelButton}
                />

                <Button
                    className="save-button"
                    isDisabled={!validSelection}
                    text="Save Changes"
                    color="primary"
                    size="large"
                    onClick={() => updateUserProductSelection()}
                    testId={PreferencesElements.SaveChangesButton}
                />
            </span>
        );
    };

    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("User Preference Page"));
    }, []);

    return (
        <div className="preferences-background">
            <div className="preferences-page">
                <h1 className="title">Preferences</h1>
                <h2 className="subtitle">
                    Select the product line(s) that apply to you.
                </h2>
                <div className="productline-list">{renderedProductLines}</div>

                <ActionButtons />
            </div>
        </div>
    );
}
