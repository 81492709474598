import React from "react";
import { NotificationDisplay } from "@components/display/notifications";
import "./styles.scss";

export type errorProps = {
    status: string;
    testId?: string;
};
export function NotificationPage(props: errorProps): JSX.Element {
    return (
        <div className="error-page">
            <NotificationDisplay status={props.status} />
        </div>
    );
}
