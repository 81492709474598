import {
    DefaultPoDateFilterData,
    DefaultRequestedDateFilterData,
} from "@components/filters/filter-date-range-group/filter-date-range-group-data";
import { DateRangeItem } from "@components/filters/types";

export const resetDateFilter = (filter: DateRangeItem[], title: string) => {
    if (title === "PO Date") {
        return DefaultPoDateFilterData;
    }
    return DefaultRequestedDateFilterData;
};
