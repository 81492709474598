import React from "react";
import "./styles.scss";

export default function LogoIcon(): JSX.Element {
    return (
        <svg
            viewBox="0 0 642 551"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M447.611 0.459961C535.577 0.459961 609.665 58.9617 633.364 139.241C638.488 156.536 641.264 175.111 641.264 194.113V278.023C617.564 197.743 543.476 139.241 455.51 139.241H186.488C98.5219 139.241 24.434 80.7397 0.734375 0.459961H447.611Z"
                fill="url(#paint0_linear_1420_19759)"
            />
            <path
                d="M455.3 278.129H251.185C163.219 278.129 89.1306 219.627 65.431 139.348H186.491H455.513C543.48 139.348 617.567 197.849 641.267 278.129V416.91H641.054C617.354 336.631 543.053 278.129 455.3 278.129Z"
                fill="url(#paint1_linear_1420_19759)"
            />
            <path
                d="M455.298 278.235C543.264 278.235 617.352 336.737 641.051 417.017H314.595C226.629 417.017 152.541 358.515 128.841 278.235H455.298Z"
                fill="url(#paint2_linear_1420_19759)"
            />
            <path
                d="M574.647 550.46C601.123 550.46 622.687 528.895 622.687 502.42C622.687 475.945 601.123 454.38 574.647 454.38C548.172 454.38 526.608 475.945 526.608 502.42C526.608 528.895 548.172 550.46 574.647 550.46Z"
                fill="#00109F"
            />
            <path
                d="M308.401 550.46C334.876 550.46 356.441 528.895 356.441 502.42C356.441 475.945 334.876 454.38 308.401 454.38C281.926 454.38 260.361 475.945 260.361 502.42C260.361 528.895 281.926 550.46 308.401 550.46Z"
                fill="#00109F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1420_19759"
                    x1="641.056"
                    y1="187.887"
                    x2="26.332"
                    y2="-35.8548"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F8485E" />
                    <stop offset="1" stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1420_19759"
                    x1="668.387"
                    y1="360.56"
                    x2="92.4552"
                    y2="82.9268"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D22630" />
                    <stop offset="1" stopColor="#F8485E" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1420_19759"
                    x1="128.706"
                    y1="278.173"
                    x2="640.989"
                    y2="416.988"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00AEC7" />
                    <stop offset="0.1562" stopColor="#009CC2" />
                    <stop offset="0.4714" stopColor="#006CB6" />
                    <stop offset="0.9131" stopColor="#001FA3" />
                    <stop offset="1" stopColor="#000F9F" />
                </linearGradient>
            </defs>
        </svg>
    );
}
