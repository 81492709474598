import React from "react";
import { DataGridColumnDefinition } from "@cpchem/covalence-ui";
import { sortValue } from "@utilities/pages/order-history";
import {
    ActionsHeaderContent,
    BaseHeaderContent,
    IncotermHeaderContent,
    RequestedDeliveryHeaderContent,
} from "../../content/column-groups";

export function HeaderRendererFull(
    headers: DataGridColumnDefinition[],
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void,
    currentSortvalue: sortValue,
): JSX.Element {
    return (
        <thead className="is-sticky">
            <tr>
                {BaseHeaderContent(
                    headers,
                    onSelectSortValue,
                    currentSortvalue,
                )}
                {RequestedDeliveryHeaderContent(
                    headers,
                    onSelectSortValue,
                    currentSortvalue,
                )}
                {IncotermHeaderContent(
                    headers,
                    onSelectSortValue,
                    currentSortvalue,
                )}
                {ActionsHeaderContent()}
            </tr>
        </thead>
    );
}

export function HeaderRendererMedium(
    headers: DataGridColumnDefinition[],
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void,
    currentSortvalue: sortValue,
): JSX.Element {
    return (
        <thead className="is-sticky">
            <tr>
                {BaseHeaderContent(
                    headers,
                    onSelectSortValue,
                    currentSortvalue,
                )}
                {IncotermHeaderContent(
                    headers,
                    onSelectSortValue,
                    currentSortvalue,
                )}
                {ActionsHeaderContent()}
            </tr>
        </thead>
    );
}

export function HeaderRendererSmall(
    headers: DataGridColumnDefinition[],
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void,
    currentSortvalue: sortValue,
): JSX.Element {
    return (
        <thead className="is-sticky">
            <tr>
                {BaseHeaderContent(
                    headers,
                    onSelectSortValue,
                    currentSortvalue,
                )}
                {ActionsHeaderContent()}
            </tr>
        </thead>
    );
}
