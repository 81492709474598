import { ProductLine } from "@models";

export function ValidateProductSelections(
    ProductLinesSource: ProductLine[],
    ProductLinesChanges: ProductLine[],
) {
    return (
        JSON.stringify(ProductLinesSource) !==
            JSON.stringify(ProductLinesChanges) &&
        ProductLinesChanges.some((item) => item.selected === true)
    );
}

export function LoadSelectedProductLines(
    authorizedProductLinesList: ProductLine[],
    selectedProductLines: ProductLine[],
) {
    const ProductLinesWithSelection = authorizedProductLinesList.map((item) => {
        if (selectedProductLines.length > 0) {
            const productLineSelection = selectedProductLines.find(
                (productLine) => productLine.id === item.id,
            );
            if (
                productLineSelection !== undefined &&
                productLineSelection.selected !== undefined
            ) {
                return {
                    ...item,
                    selected: productLineSelection.selected,
                };
            }
        }
        return { ...item, selected: false };
    });
    return ProductLinesWithSelection;
}

export function GetProductSelections(productLinesCatalog: ProductLine[]) {
    const selectedProductLines: ProductLine[] = [];
    productLinesCatalog.forEach((item) => {
        if (item.selected === true) {
            selectedProductLines.push(item);
        }
    });
    return selectedProductLines;
}
