// API Response
import { ApiResponse } from "./api-response";
export type { ApiResponse };

// Service Locator
import ServiceLocator from "./service-locator";
export { ServiceLocator };

// Personalization
import {
    PersonalizationService,
    PersonalizationRecord,
    ServiceKey as PersonalizationServiceKey,
} from "./personalization";
export type { PersonalizationService, PersonalizationRecord };
export { PersonalizationServiceKey };

// utilities
export const AuthServiceKey = "Authentication";
export const ApiOptionsServiceKey = "ApiOptions";
