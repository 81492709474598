import config from "@config";
import { ApiOptionsServiceKey, ServiceLocator } from "@services";
import { ApiOptionsService } from "@services/api-options";

export type DocumentQueryOptions = {
    id: string;
    docType: "ORDERS" | "DELIVERY";
};

export async function getDocumentList(props: DocumentQueryOptions) {
    const docType = props.docType === "DELIVERY" ? "delivery" : "order";
    const url = `${config.api.documents.url}/${docType}/${props.id}`;
    const apiOptionsService =
        ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
    const options = await apiOptionsService.getApiOptions();
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
}

export async function getDocumentFromUrl(url: string): Promise<Blob> {
    const apiOptionsService =
        ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
    const options = await apiOptionsService.getApiOptions();
    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error(
            `Failed to fetch document: ${response.status} ${response.statusText}`,
        );
    }
    const blob = await response.blob();
    return blob;
}
