import React, { useEffect, useState } from "react";
import {
    SearchBox,
    Accordion,
    Checkbox,
    AlertBanner,
    useAlertBanner,
    Tooltip,
} from "@cpchem/covalence-ui";
import { FilterListItem } from "../../../models";
import "./styles.scss";
import { Elements } from "./elements";

export interface FilterListProps {
    filterList: FilterListItem[];
    title: string;
    filterUpdated: (id: string) => void;
    showSearch: boolean;
    defaultOpen?: boolean;
    showAlert?: boolean;
    testId?: string;
}

export function FilterList({
    filterList,
    title,
    filterUpdated,
    showSearch,
    defaultOpen,
    showAlert,
    testId,
}: FilterListProps): JSX.Element {
    const [filterText, setFilterText] = useState("");
    const { createAlertBanner } = useAlertBanner();

    useEffect(() => {
        createAlertBanner({
            severity: "info",
            message:
                "Didn't find what you were looking for? Use the search to find more.",
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const placeholder = "Type at least 2 characters to search";

    const filteredOptions = filterList.filter(({ name }) => {
        if (filterText.length <= 1) {
            return filterList;
        }
        const regex = new RegExp(filterText.toLowerCase());
        const filterTerm = name?.toString() || "";
        return regex.test(filterTerm.toLowerCase());
    });

    const accordionTestId = testId
        ? `${testId}-${Elements.Filter_List_Accordion}`
        : undefined;
    const checkBoxTestId = testId
        ? `${testId}-${Elements.Filter_List_Check_Box}`
        : undefined;
    const searchBoxTestId = testId
        ? `${testId}-${Elements.Filter_List_Search_Box}`
        : undefined;
    const tooltipTestId = testId
        ? `${testId}-${Elements.Filter_List_Tooltip}`
        : undefined;

    return (
        <div className="cui-data-grid-filter-input" data-testid={testId}>
            <Accordion
                label={title}
                defaultOpen={defaultOpen}
                testId={accordionTestId}
            >
                <div className="ecom-search-field">
                    {showSearch ? (
                        <SearchBox
                            className="text-filter-input"
                            isLabelHidden
                            label=""
                            onChange={setFilterText}
                            placeholder={placeholder}
                            value={filterText}
                            testId={searchBoxTestId}
                        />
                    ) : null}
                </div>
                <div className="option-list">
                    {filteredOptions.map((row) => {
                        const optionLabel = row.name as string;
                        const tooltipContent = row.tooltip as string;
                        return (
                            <span key={optionLabel}>
                                {title === "Order Status" ? (
                                    <Tooltip
                                        content={tooltipContent}
                                        className={
                                            optionLabel === "Invoiced"
                                                ? "tooltip-ecom invoiced-tooltip-ecom"
                                                : "tooltip-ecom"
                                        }
                                        enterDelay="long"
                                        side={
                                            optionLabel === "Invoiced"
                                                ? "topLeft"
                                                : undefined
                                        }
                                        leaveDelay="zero"
                                        testId={tooltipTestId}
                                    >
                                        <Checkbox
                                            isChecked={row.checked}
                                            label={optionLabel}
                                            onChange={() =>
                                                filterUpdated(row.id)
                                            }
                                            testId={checkBoxTestId}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Checkbox
                                        isChecked={row.checked}
                                        label={optionLabel}
                                        onChange={() => filterUpdated(row.id)}
                                        testId={checkBoxTestId}
                                    />
                                )}
                            </span>
                        );
                    })}
                    {showSearch && showAlert ? (
                        <AlertBanner className="info-alert-banner" />
                    ) : null}
                </div>
            </Accordion>
        </div>
    );
}
