import React from "react";
import { sortValue } from "@utilities/pages/order-history";
import { HeaderCellContent } from "./header-cell-content";
import { DataGridColumnDefinition } from "@cpchem/covalence-ui";
import { findHeaderItem } from "../utilities";

export function BaseHeaderContent(
    headers: DataGridColumnDefinition[],
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void,
    currentSortvalue: sortValue,
): JSX.Element {
    const baseHeaderItems = headers.filter(
        (header) =>
            header.id !== "requiredDeliveryDate" &&
            header.id !== "incoterms" &&
            header.id !== "Actions",
    );
    return (
        <>
            {baseHeaderItems.map((header) => {
                return (
                    <HeaderCellContent
                        key={header.id}
                        header={header}
                        onSelectSortValue={onSelectSortValue}
                        currentSortValue={currentSortvalue}
                    />
                );
            })}
        </>
    );
}

export function RequestedDeliveryHeaderContent(
    headers: DataGridColumnDefinition[],
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void,
    currentSortvalue: sortValue,
): JSX.Element {
    const header = findHeaderItem(headers, "requiredDeliveryDate");
    return (
        <>
            <HeaderCellContent
                key={header?.id}
                header={header}
                onSelectSortValue={onSelectSortValue}
                currentSortValue={currentSortvalue}
            />
        </>
    );
}

export function IncotermHeaderContent(
    headers: DataGridColumnDefinition[],
    onSelectSortValue: (newSort: sortValue, currentSort: sortValue) => void,
    currentSortvalue: sortValue,
): JSX.Element {
    const header = findHeaderItem(headers, "incoterms");
    return (
        <>
            <HeaderCellContent
                key={header?.id}
                header={header}
                onSelectSortValue={onSelectSortValue}
                currentSortValue={currentSortvalue}
            />
        </>
    );
}

export function ActionsHeaderContent(): JSX.Element {
    return (
        <>
            <th />
        </>
    );
}
