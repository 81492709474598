import React from "react";
import { Elements } from "./elements";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "@hooks/useTypedSelector";
import { pageOrderMode } from "@stores/order-history";
import "./styles.scss";

export type BreadcrumbLink = {
    link: string;
    display: string;
};

export interface BreadcrumbProps {
    links: BreadcrumbLink[];
    title: string;
    testId?: string;
}

export default function BreadCrumb({
    links,
    title,
    testId,
}: BreadcrumbProps): JSX.Element {
    const containerTestId: string | undefined = testId
        ? `${testId}-${Elements.Breadcrumb_Container}`
        : undefined;

    const titleTestId: string | undefined = testId
        ? `${testId}-${Elements.Breadcrumb_Title}`
        : undefined;

    const linksTestId: string | undefined = testId
        ? `${testId}-${Elements.Breadcrumb_Links}`
        : undefined;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function handleOrdersPageClick() {
        dispatch(pageOrderMode);
        navigate("/");
    }

    const renderLinks = (links: BreadcrumbLink[]) => {
        return links.map((link) => (
            <span key={`link.display.${link.link}`}>
                <Link
                    className="link"
                    to={link.link}
                    onClick={
                        link.link === "/orders"
                            ? handleOrdersPageClick
                            : undefined
                    }
                >
                    {link.display}
                </Link>
                &nbsp; / &nbsp;
            </span>
        ));
    };

    return (
        <div className="breadcrumb-container" data-testid={containerTestId}>
            <div className="breadcrumb">
                <span data-testid={linksTestId}>{renderLinks(links)}</span>
                <span data-testid={titleTestId}>{title}</span>
            </div>
        </div>
    );
}
