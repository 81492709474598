import { FilterListItem } from "@models";

// Apply testing functionality to validate behavior of applying previous state or not.
export const buildFilterListItemList = (
    data: string[],
    title: string,
    prevFilterList?: FilterListItem[],
): FilterListItem[] => {
    const prevSelectedItems = prevFilterList
        ? prevFilterList.filter((filterItem) => filterItem.checked)
        : [];

    const missingFilterItems = prevSelectedItems.filter(
        (previousItem) =>
            !data.some((filterItem) => filterItem === previousItem.id),
    );

    const filterList: FilterListItem[] = data.map((element: string) => {
        return {
            name: element,
            id: element,
            checked: prevSelectedItems.some(
                (filterItem) => filterItem.id === element,
            ),
            title: title,
        };
    });

    const finalFilterList = missingFilterItems.concat(filterList);

    return finalFilterList;
};
