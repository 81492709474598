import { getFormattedDate } from "./";

const shortDate = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
});

export type DateTimeFormatType =
    | "date-short"
    | "time-short"
    | "date-time-short";

function CreateShortDateTimeFormatterWithTimeZone(
    formatterType: DateTimeFormatType,
    timezoneValue: string,
): Intl.DateTimeFormat {
    let formatOptions: Intl.DateTimeFormatOptions = {};
    if (formatterType === "date-short") {
        formatOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
    }
    if (formatterType === "time-short") {
        formatOptions = {
            hour: "numeric",
            hour12: true,
            minute: "numeric",
        };
    }
    if (formatterType === "date-time-short") {
        formatOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            hour12: true,
            minute: "numeric",
        };
    }

    if (timezoneValue.toLowerCase() !== "local") {
        formatOptions = { ...formatOptions, timeZone: timezoneValue };
        if (formatterType !== "date-short") {
            formatOptions = { ...formatOptions, timeZoneName: "short" };
        }
    }

    return new Intl.DateTimeFormat("en", formatOptions);
}

export function ConvertFullDateToShort(dateToConvert: string): string {
    const dateCleaned = dateToConvert ? dateToConvert.replace("Z", "") : "";
    const dateValue = new Date(dateCleaned);
    return dateToConvert !== null && dateValue.toString() !== "Invalid Date"
        ? shortDate.format(dateValue)
        : "";
}

export function ConvertDateForFilter(dateToConvert: Date): string {
    return dateToConvert !== null ? getFormattedDate(dateToConvert) : "";
}

export function ConvertDateTimeToShortDateTimeWithTimeZone(
    dateToConvert: string,
    timeZoneValue: string,
    dateTimeFormatType: DateTimeFormatType,
): string {
    if (dateToConvert !== undefined && dateToConvert !== null) {
        const dateValue = new Date(
            timeZoneValue.toLocaleLowerCase() === "local"
                ? dateToConvert.replace("Z", "")
                : dateToConvert,
        );
        const dateFormatter = CreateShortDateTimeFormatterWithTimeZone(
            dateTimeFormatType,
            timeZoneValue,
        );

        return dateToConvert !== null && dateValue.toString() !== "Invalid Date"
            ? dateFormatter.format(dateValue)
            : "";
    }
    return "";
}
