import { DateRange } from "@models/date-range";

export function getFormattedDateRange(
    startDate: Date,
    endDate: Date,
): DateRange {
    const startDateMonth = `0${startDate.getMonth() + 1}`;
    const startDateDay = `0${startDate.getDate()}`;
    const endDateMonth = `0${endDate.getMonth() + 1}`;
    const endDateDay = `0${endDate.getDate()}`;
    const formattedStartDate = `${startDate.getFullYear()}-${startDateMonth.slice(
        -2,
    )}-${startDateDay.slice(-2)}`;
    const formattedEndDate = `${endDate.getFullYear()}-${endDateMonth.slice(
        -2,
    )}-${endDateDay.slice(-2)}`;

    return { startDate: formattedStartDate, endDate: formattedEndDate };
}
