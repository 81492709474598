import React, { useEffect } from "react";
import { AlertBanner, Grid } from "@cpchem/covalence-ui";
import AppRoutes from "./components/app-routes";
import { EcomBar, LoadingPage, OnboardingPage } from "../pages";
import {
    selectApplicationStatus,
    getAuthorizedProductLines,
    getUserProductLines,
} from "../stores/product-lines";
import { useAppDispatch, useAppSelector } from "../hooks/useTypedSelector";
import "./styles.scss";
import { NotificationPage } from "../pages/error-page";
import { ToastProvider } from "@components/toasts";
import { ApplicationStatus } from "@models/data-store";
import { useAuth } from "@hooks/use-auth";
import { setLogRocketUserId } from "@utilities/log/configure-log-rocket";
import {
    GET_PRODUCT_LINES,
    GET_USER_PREFERENCES,
} from "@utilities/log/log-events";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";

export function App(): JSX.Element {
    const appStatus = useAppSelector(selectApplicationStatus);
    const dispatch = useAppDispatch();
    const { currentAccount } = useAuth();

    useEffect(() => {
        ecomTrackEvent({
            type: GET_PRODUCT_LINES,
            contents: JSON.stringify({}),
        });
        dispatch(getAuthorizedProductLines());
        ecomTrackEvent({
            type: GET_USER_PREFERENCES,
            contents: JSON.stringify({
                catalog: "userProductLines",
            }),
        });
        dispatch(getUserProductLines());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (currentAccount) setLogRocketUserId(currentAccount);
    }, [currentAccount]);

    const errorPage: JSX.Element = <NotificationPage status={appStatus} />;

    const dictViewFor: { [key in ApplicationStatus]: JSX.Element } = {
        ready: <AppRoutes />,
        onboarding: <OnboardingPage />,
        error: errorPage,
        loading: <LoadingPage />,
        unAuthorized: <NotificationPage status={appStatus} />,
    };

    return (
        <Grid className="app">
            <EcomBar ApplicationReady={appStatus === "ready"} />
            <AlertBanner />
            <ToastProvider>
                <div className="main">
                    {dictViewFor[appStatus] || <LoadingPage />}
                </div>
            </ToastProvider>
        </Grid>
    );
}

export default App;
