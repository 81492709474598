import {
    faTimesCircle,
    faCheckCircle,
    faInfoCircle,
    faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";

export const statusState = {
    danger: {
        icon: faTimesCircle,
        statusText: "Danger!",
    },
    success: {
        icon: faCheckCircle,
        statusText: "Success!",
    },
    caution: {
        icon: faExclamationTriangle,
        statusText: "Warning!",
    },
    info: {
        icon: faInfoCircle,
        statusText: "Informational!",
    },
};
