import React, { useEffect } from "react";
import { App } from "../..";
import { LoginScreen } from "../login-screen";
import { useAuth } from "../../../hooks";
import { AuthState } from "@cpchem/azure-auth";
import { createPageViewEvent } from "@cpchem/logging";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { setUserInLoggingContext } from "@utilities/log/configure-logging";

export function AuthBoundary(): JSX.Element {
    const { authState, currentAccount } = useAuth();
    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("Loading Landing Page"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (authState === "Authenticated") {
            setUserInLoggingContext(currentAccount?.username ?? "anonymous");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]);

    const dictViewFor: { [key in AuthState]: JSX.Element } = {
        Authenticated: <App />,
        Authenticating: <LoginScreen />,
        Unauthenticated: <LoginScreen />,
        Unknown: <LoginScreen />,
    };
    return dictViewFor[authState] || <LoginScreen />;
}
