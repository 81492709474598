export function buildFilterListQuery(
    fieldName: string,
    items: string[],
): string {
    if (items.length === 0) {
        return "";
    }

    const filterValues: string[] = [];
    items.forEach((item) => {
        filterValues.push(`'${item}'`);
    });

    const filterString = `${fieldName} in (${filterValues.join()}) and `;
    return encodeURIComponent(filterString);
}
