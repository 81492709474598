export function isInDevEnvironment(env: string): boolean {
    return env.toUpperCase() === "DEVELOPMENT";
}

export function isInProduction(env: string): boolean {
    return env.toUpperCase() === "PRODUCTION";
}

export function isAppInsightsDeclared(settings: string): boolean {
    return settings !== "";
}
