import React from "react";
import {
    DataGrid,
    DataGridColumnDefinition,
    DataGridProps,
    DataGridRow,
    FilteringDataGridColumnDefinition,
} from "@cpchem/covalence-ui";
import { ItemDetails } from "@models";
import { GetItemDetailsColumnsBySize } from "./content/item-details-columns";
import { HeaderRendererFull } from "./renderer/header";
import { ResultRowFull } from "./renderer/row";
import { useNavigate } from "react-router-dom";

type DisplaySizeOptions = {
    currentColumns: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[];
    currentHeaderRenderer: () => JSX.Element;
    currentRowRenderer: (row: DataGridRow) => JSX.Element;
};

interface ItemDetailsDisplayProps {
    ItemDetails: ItemDetails;
}

export function LineItemsDisplay({
    ItemDetails,
}: ItemDetailsDisplayProps): JSX.Element {
    const { lineItems } = ItemDetails;
    const navigate = useNavigate();
    function RowRenderer(row: DataGridRow): JSX.Element {
        return ResultRowFull(row, navigate);
    }
    const displaySizeOptions: DisplaySizeOptions = {
        currentColumns: GetItemDetailsColumnsBySize(),
        currentHeaderRenderer: HeaderRendererFull,
        currentRowRenderer: RowRenderer,
    };

    // Datagrid props definition
    const gridProps: DataGridProps = {
        data: lineItems,
        columns: displaySizeOptions.currentColumns,
        useStickyHeaders: true,
        headerRenderer: displaySizeOptions.currentHeaderRenderer,
        rowRenderer: displaySizeOptions.currentRowRenderer,
        isSortable: true,
    };

    return (
        <div className="line-items-grid">
            <DataGrid {...gridProps} />
        </div>
    );
}
