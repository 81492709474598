import { ApiOptions } from "@services/api-options";

export async function FetchAndReturnJson(
    url: string,
    options: ApiOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    const response = await fetch(url, options);
    const jsonResponse = await response.json();
    const finalResponse = { content: jsonResponse, status: response.status };
    return finalResponse;
}
