import React, { useEffect, useState } from "react";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button, Menu, MenuItem, Spinner } from "@cpchem/covalence-ui";

import {
    buildDocumentFileName,
    buildDocumentUrl,
    buildDocumentList,
} from "./utilities";
import { getDocumentList, getDocumentFromUrl } from "@services/document";
import { DocumentDetail } from "@models";

import "./styles.scss";
import {
    DOCUMENT_LIST_LOADING,
    DOWNLOAD_FILE,
} from "@utilities/log/log-events";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";

export interface DocumentDropdownProps {
    id: string;
    system: "ORDERS" | "DELIVERY";
    testId?: string;
}

function DocumentDropdown(props: DocumentDropdownProps) {
    const containerTestId: string | undefined = props.testId
        ? props.testId
        : undefined;
    const menuTestid: string | undefined = props.testId
        ? `${props.testId}-list-open`
        : undefined;

    const [documentItems, setDocumentItems] = useState<MenuItem[]>([]);

    const [menuIsLoading, setMenuIsLoading] = useState(false);
    const [menuHasLoaded, setMenuHasLoaded] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const faPropAngleDownIcon = faAngleDown as IconProp;

    let originalDocumentList: MenuItem[] = [];
    const copiedDocumentList: MenuItem[] = [];

    useEffect(() => {
        // necessary to update the menu items after render
    }, [documentItems]);

    const handleDocumentClick = async (document: DocumentDetail) => {
        if (isDisabled) {
            return;
        }

        // clear copied array to prevent duplicating menu items
        copiedDocumentList.length = 0;
        // copy original items to copied array
        originalDocumentList.forEach((val) =>
            copiedDocumentList.push({ ...val }),
        );

        // change documentlist to disable items and add loading content
        copiedDocumentList.map((menuItem) => {
            menuItem.onClick = () => null;
            menuItem.disableHover = true;
            if (menuItem.content === document.documentDescription) {
                // set to loading content
                menuItem.className = "preparing";
                menuItem.leftIcon = <Spinner size="small" />;
                menuItem.content = <span>Preparing download</span>;
            } else {
                menuItem.className = "disabled";
            }
            return menuItem;
        });
        setDocumentItems(copiedDocumentList);

        getDocumentFromUrl(buildDocumentUrl(document))
            .then((blob) => {
                ecomTrackEvent({
                    type: DOWNLOAD_FILE,
                    contents: JSON.stringify({ ...document }),
                });
                downloadDocument(blob, document);
            })
            .finally(() => {
                // reset stateful documentList with original, unmodified menu-items
                setDocumentItems(originalDocumentList);
                setIsDisabled(false);
            });
    };

    // This is a temporary solution for getting the documents to auto download. Future
    // version will pass this functionality to a blank page (waiting for API endpoint update)
    const downloadDocument = (blob: Blob, doc: DocumentDetail) => {
        const link: HTMLAnchorElement = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = buildDocumentFileName(doc, props.id);
        link.click();
    };

    const interceptClick = async () => {
        if (menuHasLoaded) {
            return;
        }

        if (!menuIsLoading) {
            setMenuIsLoading(true);
            ecomTrackEvent({
                type: DOCUMENT_LIST_LOADING,
                contents: JSON.stringify({ orderItemID: props.id }),
            });
            const result = await getDocumentList({
                id: props.id,
                docType: props.system,
            });
            setMenuIsLoading(false);

            const menuItemTestId = menuTestid
                ? `${menuTestid}-menu-item`
                : undefined;

            originalDocumentList = buildDocumentList(
                result.documents,
                isDisabled,
                handleDocumentClick,
                menuItemTestId,
            );
            setDocumentItems(originalDocumentList);
        }

        setMenuHasLoaded(true);
    };

    const setButtonAsLoading = (isLoading: boolean) => (
        <Button
            className="document-dropdown-button justify-center"
            color="primary"
            text={isLoading ? "Loading" : "Download"}
            variant="outline"
            isIconAfterText={!isLoading}
            isDisabled={isLoading}
            icon={
                isLoading ? (
                    <Spinner size="small" />
                ) : (
                    <FontAwesomeIcon
                        className="icon"
                        icon={faPropAngleDownIcon}
                    />
                )
            }
            onClick={interceptClick}
        />
    );

    return (
        <div className="document-dropdown">
            <Menu
                disableActiveStyles
                className={`documents action-menu ${
                    props.system === "DELIVERY" ? "delivery" : ""
                }`}
                baseElement={
                    <span data-testid={containerTestId}>
                        {setButtonAsLoading(menuIsLoading)}
                    </span>
                }
                menuItems={documentItems}
                testId={menuTestid}
            />
        </div>
    );
}

export default React.memo(DocumentDropdown);
