import { FilterListItem } from "../../models/filters";

export const resetFilter = (filter: FilterListItem[], id: string) => {
    const clearChecked = [...filter];
    clearChecked.map((filter) => {
        if (filter.id === id) {
            filter.checked = false;
        }
    });
    return clearChecked;
};
