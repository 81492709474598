import { AuthManager } from "@cpchem/azure-auth";
import config from "@config";
import { getTraceParent } from "@cpchem/logging";
import { isAppInsightsDeclared } from "@utilities/log/in-dev-environment";

export type ApiOptions = {
    method: string;
    headers: Headers;
};

export class ApiOptionsService {
    private readonly authService: AuthManager;
    constructor(authService: AuthManager) {
        this.authService = authService;
    }
    async getApiOptions(requestMethod = "GET") {
        const token = await this.authService.getApiToken(config.api.scopes);

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        if (
            isAppInsightsDeclared(config.logging.appInsights.connectionString)
        ) {
            headers.append("traceparent", getTraceParent());
        }

        const options = {
            method: requestMethod,
            headers: headers,
        };

        return options;
    }
}
