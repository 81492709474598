import { FilterListItem } from "../../../models";

export const DefaultStatusFilterItems: FilterListItem[] = [
    {
        tooltip: "SAP: Open order",
        name: "Order Received",
        id: "Order Received",
        title: "Order Status",
        checked: false,
    },
    {
        tooltip: "SAP: Open delivery(ies) exist",
        name: "Awaiting Shipment",
        id: "Awaiting Shipment",
        title: "Order Status",
        checked: false,
    },
    {
        tooltip: "SAP: Some deliveries PGI’d",
        name: "Partially Shipped",
        id: "Partially Shipped",
        title: "Order Status",
        checked: false,
    },
    {
        tooltip: "SAP: All deliveries PGI’d",
        name: "Shipped",
        id: "Shipped",
        title: "Order Status",
        checked: false,
    },
    {
        tooltip: "SAP: Invoice(s) generated",
        name: "Invoiced",
        id: "Invoiced",
        title: "Order Status",
        checked: false,
    },
];
