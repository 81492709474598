interface ProvidedConfig {
    logging: {
        appInsights: {
            connectionString: string;
        };
        logRocketAppId: string;
    };
    personalization: {
        url: string;
        scopes: string[];
    };
    msal: {
        authority: string;
        clientId: string;
        redirectUri: string;
        scopes: string[];
    };
    graph: {
        photo: {
            endpoint: string;
            scopes: string[];
        };
    };
    api: {
        scopes: string[];
        productlines: {
            url: string;
            scopes: string[];
        };
        orderHistory: {
            url: string;
            scopes: string[];
        };
        orderSearch: {
            url: string;
            scopes: string[];
        };
        shipments: {
            url: string;
            scopes: string[];
        };
        filters: {
            url: string;
            scopes: string[];
        };
        documents: {
            url: string;
            scopes: string[];
        };
    };
    settings: unknown;
}

interface Config extends ProvidedConfig {
    env: string;
    connected: boolean;
}

// eslint-disable-next-line no-undef
const env = import.meta.env.MODE;
// eslint-disable-next-line no-undef
const envConnected = import.meta.env.VITE_CONNECTED
    ? import.meta.env.VITE_CONNECTED === "true"
    : false;
const connected = env === "production" || envConnected;

function getConfigFromIndex(): ProvidedConfig {
    const windowConfig = document.getElementById("config")?.innerHTML;
    if (windowConfig) {
        return JSON.parse(windowConfig);
    }
    throw "Unable to find config in page";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const providedConfig: ProvidedConfig =
    env === "development"
        ? {
              logging: {
                  appInsights: {
                      connectionString: "",
                  },
                  logRocketAppId: "",
              },
              personalization: {
                  url: "https://api-dev.cpchem.com/personalization/v1/personalization",
                  scopes: [
                      "https://api-dev.cpchem.com/Personalization.FullAccess",
                  ],
              },
              msal: {
                  authority:
                      "https://login.microsoftonline.com/88b6c77b-f4e0-40c5-9fbb-df51a927179a",
                  clientId: "dc3539b9-5700-4b4c-b8ba-8b938c7c6853",
                  redirectUri: "https://localhost:3000/auth_redirect",
                  scopes: [
                      "https://api-dev.cpchem.com/eCommerce.FullAccess",
                      "User.Read",
                  ],
              },
              graph: {
                  photo: {
                      endpoint:
                          "https://graph.microsoft.com/v1.0/me/photo/$value",
                      scopes: ["https://graph.microsoft.com/User.Read"],
                  },
              },
              api: {
                  scopes: ["https://api-dev.cpchem.com/eCommerce.FullAccess"],
                  productlines: {
                      url: "https://appcpcscus-dt-app-ecom-dev.azurewebsites.net/v1/ProductLines",
                      scopes: [],
                  },
                  orderHistory: {
                      url: "https://appcpcscus-dt-app-ecom-dev.azurewebsites.net/v1/Orders",
                      scopes: [],
                  },
                  orderSearch: {
                      url: "https://appcpcscus-dt-app-ecom-dev.azurewebsites.net/v1/ordersearch",
                      scopes: [],
                  },
                  shipments: {
                      url: "https://appcpcscus-dt-app-ship-dev.azurewebsites.net/v2/shipments",
                      scopes: [
                          "https://api-dev.cpchem.com/Shipments.FullAccess",
                      ],
                  },
                  filters: {
                      url: "https://appcpcscus-dt-app-ecom-dev.azurewebsites.net/v1/filters",
                      scopes: [],
                  },
                  documents: {
                      url: "https://appcpcscus-dt-app-ecom-dev.azurewebsites.net/v1/documents",
                      scopes: [],
                  },
              },
              settings: {},
          }
        : getConfigFromIndex();

const combinedConfig: Config = {
    ...providedConfig,
    env: env || "unknown",
    connected,
};

export default combinedConfig;
