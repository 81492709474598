import { Icon } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { statusState } from "./constants";
import { Toast, ToastId } from "./model";
import { motion, AnimatePresence } from "framer-motion";
import "./toast-styles.scss";

interface ToastProps
    extends Pick<
        Toast,
        | "isActive"
        | "autoDismiss"
        | "autoDismissDelay"
        | "dismissable"
        | "message"
        | "status"
        | "id"
        | "title"
        | "requestDismiss"
    > {
    className?: string;
    onDismiss: (id: ToastId) => void;
    onRemove: (id: ToastId) => void;
    testId?: string;
}

export function ToastComponent({
    className,
    isActive,
    status,
    id,
    message,
    title,
    dismissable,
    onDismiss,
    onRemove,
    requestDismiss,
    testId,
}: ToastProps): JSX.Element {
    let cn = "cui-toast";

    const handleDismissToast = useCallback(() => {
        onDismiss(id);
    }, [id, onDismiss]);

    useEffect(() => {
        if (isActive && requestDismiss) {
            onRemove(id);
        }
    }, [id, isActive, onRemove, requestDismiss]);

    const { icon, statusText: defaultTitleText } = status
        ? statusState[status]
        : { icon: faExclamationTriangle, statusText: "Important Message!" };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let style: any = undefined; // disabling rule allows us to pass CSS vars to component style

    if (status) {
        cn += ` is-color ${status}`;
        style = {
            "--color": `var(--${status})`,
            "--color-10": `var(--${status}-10)`,
            "--color-10-border": `var(--${status}-10-border)`,
            "--color-30": `var(--${status}-30)`,
            "--color-45": `var(--${status}-45)`,
            "--color-55": `var(--${status}-55)`,
            "--color-75": `var(--${status}-75)`,
            "--color-90": `var(--${status}-90)`,
        };
    }

    if (className) {
        cn += ` ${className}`;
    }

    const titleText = title || defaultTitleText;

    return (
        <AnimatePresence mode="popLayout">
            {isActive && (
                <motion.li
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={cn}
                    role="status"
                    style={style}
                    key={id}
                    data-testid={testId}
                >
                    {icon && (
                        <span className="icon">
                            <FontAwesomeIcon icon={icon as Icon} />
                        </span>
                    )}
                    <p className="title">{titleText}</p>
                    {dismissable && (
                        <button
                            className="dismiss-button"
                            onClick={handleDismissToast}
                            onKeyDown={handleDismissToast}
                        >
                            <FontAwesomeIcon icon={faTimes as Icon} />
                        </button>
                    )}
                    <p className="message">{message}</p>
                </motion.li>
            )}
        </AnimatePresence>
    );
}
