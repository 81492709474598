export function buildFilterProductLineQuery(productLines: string): string {
    let products = "";
    const productLineArray = productLines.split(",");

    for (let i = 0; i < productLineArray.length; i++) {
        const item = productLineArray[i];
        products += `&salesOrganizations=${item.trim()}`;
    }

    return products;
}
