import React from "react";
import { DeliveryItems, DeliveryLineItem } from "@models/shipment-details";
import {
    DataGrid,
    DataGridProps,
    Divider,
    Modal,
    ModalContent,
} from "@cpchem/covalence-ui";
import { GetDeliveryItemColumns } from "./content/delivery-item-columns";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { createPageViewEvent } from "@cpchem/logging";

type ShipmentDeliveryModalDisplayProps = {
    deliveryItems: DeliveryItems;
    testId?: string;
};

type DeliveryTableItem = {
    item: JSX.Element;
    deliveryItemQuantity: string;
};

export function ShipmentDeliveryModalDisplay({
    deliveryItems,
    testId,
}: ShipmentDeliveryModalDisplayProps): JSX.Element {
    function parseDeliveryData(deliveryData: DeliveryLineItem[]) {
        const deliveryTableData: DeliveryTableItem[] = [];
        deliveryData.forEach((item) => {
            deliveryTableData.push({
                item: (
                    <div className="cpo-number">
                        <span className="cpo-number-product">
                            {item.itemDescription}
                        </span>
                        <br />
                        <span className="cpo-number-title">
                            {`CPChem Mat. #: ${item.itemMaterialNumber} `}{" "}
                        </span>
                        <span className="cpo-number-title">
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                        </span>
                        <span className="cpo-number-title">{` Customer Mat. #: ${item.itemCustomerMaterialNumber}`}</span>
                    </div>
                ),
                deliveryItemQuantity: item.shipmentQuantity,
            });
        });
        return deliveryTableData;
    }

    const gridProps: DataGridProps = {
        data: parseDeliveryData(deliveryItems.deliveryData),
        columns: GetDeliveryItemColumns(),
        isSortable: true,
    };

    ecomTrackEvent(
        createPageViewEvent("Loading Shipment Details Delivery dialog"),
    );

    return (
        <Modal
            className="delivery-items-modal delivery-modal-width"
            isOpen={deliveryItems.modalOpen}
            onRequestClose={() => deliveryItems.closeDeliveryModal()}
            title={`Delivery # ${deliveryItems.deliveryNumber}`}
            data-testid={testId}
        >
            <ModalContent>
                <Divider />
                <DataGrid {...gridProps} />
            </ModalContent>
        </Modal>
    );
}
