import React from "react";

function BaseHeaderContent(): JSX.Element {
    return (
        <>
            <th>
                <span className="header-cell justify-left items">
                    Delivery #
                </span>
            </th>
            <th>
                <span className="header-cell justify-left status">Carrier</span>
            </th>
            <th>
                <span className="header-cell justify-right item-qty">
                    Delivery Item Qty
                </span>
            </th>
            <th>
                <span className="header-cell justify-left total-qty">
                    Shipped On
                </span>
            </th>
            <th>
                <span className="header-cell justify-left ship-date">
                    Invoice #
                </span>
            </th>
            <th>
                <span className="header-cell justify-left invoice-due-date">
                    Invoice Due Date
                </span>
            </th>
            <th>
                <span className="header-cell justify-right invoice-amount">
                    Invoice Amount
                </span>
            </th>
            <th>
                <span className="header-cell justify-left status">Status</span>
            </th>
            <th>
                <span className="header-cell justify-right" />
            </th>
            <th>
                <span className="header-cell justify-right" />
            </th>
        </>
    );
}

export function HeaderRendererFull(): JSX.Element {
    return (
        <thead className="is-sticky">
            <tr>{BaseHeaderContent()}</tr>
        </thead>
    );
}
