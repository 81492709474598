import { configureStore } from "@reduxjs/toolkit";
import FiltersSliceReducer from "./filters";
import OrderHistoryItemsReducer from "./order-history";
import ProductLinesReducer from "./product-lines";
import ItemDetailsReducer from "./item-details";
import ShipmentDetailsReducer from "./shipment-details";
import ShipmentTrackingDetailsReducer from "./shipment-tracking";

const store = configureStore({
    reducer: {
        ProductLines: ProductLinesReducer,
        OrderHistoryItems: OrderHistoryItemsReducer,
        Filters: FiltersSliceReducer,
        ItemDetails: ItemDetailsReducer,
        ShipmentDetails: ShipmentDetailsReducer,
        ShipmentTrackingDetails: ShipmentTrackingDetailsReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
