export function GetFormattedNumber(value: number | null | undefined) {
    if (value !== undefined && value !== null) {
        const numberToConvert = value;
        return numberToConvert.toLocaleString("en-US");
    }
    return "0";
}

export function GetFormattedNumberByCurrencyType(
    amount: number | null | undefined,
    currencyType = "USD",
): string {
    const amountToConvert =
        amount !== undefined && amount !== null ? amount : 0;

    const currencyFormatter = new Intl.NumberFormat("en", {
        style: "currency",
        currency: currencyType,
    });
    return currencyFormatter.format(amountToConvert);
}
