import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import store from "./stores";
import config, { providedConfig } from "@config";
import { Modal } from "@cpchem/covalence-ui";
import { AuthManager } from "@cpchem/azure-auth";
import { AuthBoundary } from "./app/components/auth-boundary";
import { BreakPointObject, BreakpointProvider } from "./hooks/breakpoint";

import "@cpchem/covalence-ui/lib/default-theme.css";
import "@cpchem/covalence-ui/lib/style.css";
import {
    configureAppInsightsLogging,
    configureLogging,
} from "./utilities/log/configure-logging";
import {
    PersonalizationServiceKey,
    ServiceLocator,
    ApiOptionsServiceKey,
} from "@services";
import { PersonalizationServiceImplementation } from "@services/personalization";
import { configureLogRocket } from "@utilities/log/configure-log-rocket";
import { ApiOptionsService } from "@services/api-options";
import {
    LogLevel,
    LogManager,
    getAppVersionFromMetaTag,
} from "@cpchem/logging";
import { getDevAccountMock } from "@utilities/use-devmode-user";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);

if (rootElement) {
    Modal.setAppElement(rootElement);
}

let authManagerInstance: AuthManager;

// Setting up Log Manager vars.
const appVersion = {
    appVersion: getAppVersionFromMetaTag(),
};

const appInsightSettings =
    providedConfig.logging.appInsights.connectionString !== ""
        ? {
              appInsights: {
                  connectionString:
                      providedConfig.logging.appInsights.connectionString,
                  sinkPageViews: true,
                  sinkLogsAtOrAboveLevel: LogLevel.WARN,
              },
          }
        : undefined;

LogManager.initialize({
    ...appVersion,
    ...appInsightSettings,
});
configureLogging();
if (appInsightSettings) {
    const ecomSessionId = `${LogManager.appInsights().context.user.id}-${
        LogManager.appInsights().context.telemetryTrace.traceID
    }`;

    console.log(
        `This is your eCommerce session id ${ecomSessionId}. Welcome to Customer Connect.`,
    );

    configureAppInsightsLogging(ecomSessionId);
}

configureLogRocket();

if (!config.connected) {
    authManagerInstance = AuthManager.getInstance();

    const mockAccountGenerator = await getDevAccountMock();
    authManagerInstance.loadConfiguration(
        "Dev",
        undefined,
        mockAccountGenerator,
    );

    // TBD: remove the worker if not using the below mocks with playwright
    const { worker } = await import("./api-mocks/browser");
    worker.start();
} else {
    authManagerInstance = AuthManager.getInstance();
    authManagerInstance.loadConfiguration("MSAL", config.msal);
}

ServiceLocator.set(
    PersonalizationServiceKey,
    new PersonalizationServiceImplementation(),
);

ServiceLocator.set(
    ApiOptionsServiceKey,
    new ApiOptionsService(AuthManager.getInstance()),
);

const queries: BreakPointObject = {
    md: "(max-width: 639px)",
    lg: "(max-width: 1023px)",
    xl: "(max-width: 1365px)",
    xxl: "(min-width: 1366px)",
};

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <BreakpointProvider queries={queries}>
                    <AuthBoundary />
                </BreakpointProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
);
