import React, { useEffect, useState } from "react";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    DateFilterItem,
    FilterBadges,
    FilterDateRangeGroup,
    FilterList,
} from "../index";
import { DateRange, FilterListItem } from "@models";
import {
    Button,
    Drawer,
    DrawerContent,
    DrawerActions,
    AlertBannerProvider,
} from "@cpchem/covalence-ui";
import "./styles.scss";
import { DateRangeItem } from "../types";
import { FilterElements } from "./elements";

export interface FilterContainerProps {
    applyFiltersDismiss: (id: string, title: string) => void;
    applyFilters: (
        employeeFilterList: FilterListItem[],
        customerFilterList: FilterListItem[],
        shipFromFilterList: FilterListItem[],
        locationFilterList: FilterListItem[],
        productFilterList: FilterListItem[],
        orderStatusFilterList: FilterListItem[],
        poDateRangeFilterList: DateRangeItem[],
        requestedDeliveryDateList: DateRangeItem[],
        employeeFilters: string[],
        customerFilters: string[],
        shipFromFilters: string[],
        locationFilters: string[],
        statusFilters: string[],
        productDescriptionFilters: string[],
        poDateFilter: DateRange,
        requestedDeliveryDateFilter: DateRange,
    ) => void;
    clearFilters: () => void;
    employeeFilters: FilterListItem[];
    customerFilters: FilterListItem[];
    shipFromFilters: FilterListItem[];
    deliveryLocationFilters: FilterListItem[];
    orderStatusFilters: FilterListItem[];
    productDescriptionFilters: FilterListItem[];
    poDateFilterData: DateRangeItem[];
    requestedDateFilterData: DateRangeItem[];
    currentDateRange: DateFilterItem;
    selectedEmployeeFilters: string[];
    selectedCustomerFilters: string[];
    selectedShipFromFilters: string[];
    selectedLocationFilters: string[];
    selectedStatusFilters: string[];
    selectedProductDescriptionFilters: string[];
    selectedPoFilter: DateRange;
    selectedRequestedDeliveryFilter: DateRange;
    hasActiveFilter: boolean;
    testId?: string;
}

export function FilterContainer({
    employeeFilters,
    customerFilters,
    shipFromFilters,
    deliveryLocationFilters,
    orderStatusFilters,
    productDescriptionFilters,
    poDateFilterData,
    requestedDateFilterData,
    hasActiveFilter,
    applyFilters,
    applyFiltersDismiss,
    clearFilters,
    testId,
}: FilterContainerProps) {
    const [modalState, setModalState] = useState(false);
    const [employeeFilterList, setEmployeeFilterList] =
        useState(employeeFilters);
    const [customerFilterList, setCustomerFilterList] =
        useState(customerFilters);
    const [shipFromFilterList, setShipFromFilterList] =
        useState(shipFromFilters);
    const [deliveryLocationFilterList, setDeliveryLocationFilterList] =
        useState(deliveryLocationFilters);
    const [orderStatusFilterList, setOrderStatusFilterList] =
        useState(orderStatusFilters);
    const [productDescriptionFilterList, setProductDescriptionFilterList] =
        useState(productDescriptionFilters);
    const [poDateFilterOptions, setPoDateFilterOptions] =
        useState(poDateFilterData);
    const [requestedDateFilterOptions, setRequestedDateFilterOptions] =
        useState(requestedDateFilterData);

    const openModal = () => setModalState(true);
    const closeModal = () => setModalState(false);

    const [triggerBadge, setTriggerBadge] = useState(false);
    function ClearFilters() {
        setModalState(false);

        // clear all for DateRanges
        poDateUpdated({ id: "All", startDate: "", endDate: "" });
        requestedDeliveryDateUpdated({ id: "All", startDate: "", endDate: "" });

        setEmployeeFilterList(
            employeeFilterList.map((filter) => {
                return { ...filter, checked: false };
            }),
        );

        setCustomerFilterList(
            customerFilterList.map((filter) => {
                return { ...filter, checked: false };
            }),
        );

        setShipFromFilterList(
            shipFromFilterList.map((filter) => {
                return { ...filter, checked: false };
            }),
        );

        setDeliveryLocationFilterList(
            deliveryLocationFilterList.map((filter) => {
                return { ...filter, checked: false };
            }),
        );

        setOrderStatusFilterList(
            orderStatusFilterList.map((filter) => {
                return { ...filter, checked: false };
            }),
        );

        setProductDescriptionFilterList(
            productDescriptionFilterList.map((filter) => {
                return { ...filter, checked: false };
            }),
        );

        clearFilters();
    }

    function ApplyFilters() {
        setModalState(false);

        const selectedEmployeeFilterList: string[] = [];
        employeeFilterList.map((item) => {
            if (item.checked) {
                selectedEmployeeFilterList.push(item.name);
            }
        });

        const selectedCustomerFilterList: string[] = [];
        customerFilterList.map((item) => {
            if (item.checked) {
                selectedCustomerFilterList.push(item.name);
            }
        });

        const selectedShipFromFilterList: string[] = [];
        shipFromFilterList.map((item) => {
            if (item.checked) {
                selectedShipFromFilterList.push(item.id);
            }
        });

        const selectedLocationFilterList: string[] = [];
        deliveryLocationFilterList.map((item) => {
            if (item.checked) {
                selectedLocationFilterList.push(item.id);
            }
        });

        const selectedStatusFilterList: string[] = [];
        orderStatusFilterList.map((item) => {
            if (item.checked) {
                selectedStatusFilterList.push(item.name);
            }
        });

        const selectedProductDescriptionFilterList: string[] = [];
        productDescriptionFilterList.map((item) => {
            if (item.checked) {
                selectedProductDescriptionFilterList.push(item.name);
            }
        });

        const poDateFilterSelection: DateRange = {
            id: "All",
            startDate: "",
            endDate: "",
        };
        const requestedDateFilterSelection: DateRange = {
            id: "All",
            startDate: "",
            endDate: "",
        };
        poDateFilterOptions.map((item) => {
            if (item.checked) {
                poDateFilterSelection.id = item.selectedValues.id;
                poDateFilterSelection.startDate = item.selectedValues.startDate;
                poDateFilterSelection.endDate = item.selectedValues.endDate;
            }
        });

        requestedDateFilterOptions.map((item) => {
            if (item.checked) {
                requestedDateFilterSelection.id = item.selectedValues.id;
                requestedDateFilterSelection.startDate =
                    item.selectedValues.startDate;
                requestedDateFilterSelection.endDate =
                    item.selectedValues.endDate;
            }
        });

        applyFilters(
            employeeFilterList,
            customerFilterList,
            shipFromFilterList,
            deliveryLocationFilterList,
            productDescriptionFilterList,
            orderStatusFilterList,
            poDateFilterOptions,
            requestedDateFilterOptions,
            selectedEmployeeFilterList,
            selectedCustomerFilterList,
            selectedShipFromFilterList,
            selectedLocationFilterList,
            selectedStatusFilterList,
            selectedProductDescriptionFilterList,
            poDateFilterSelection,
            requestedDateFilterSelection,
        );
    }

    const employeeListUpdated = (id: string) => {
        setEmployeeFilterList(
            employeeFilterList.map((filter) => {
                if (filter.id === id) {
                    return { ...filter, checked: !filter.checked };
                }
                return filter;
            }),
        );
    };

    const customerListUpdated = (id: string) => {
        setCustomerFilterList(
            customerFilterList.map((filter) => {
                if (filter.id === id) {
                    return { ...filter, checked: !filter.checked };
                }
                return filter;
            }),
        );
    };

    const shipFromListUpdated = (id: string) => {
        setShipFromFilterList(
            shipFromFilterList.map((filter) => {
                if (filter.id === id) {
                    return { ...filter, checked: !filter.checked };
                }
                return filter;
            }),
        );
    };

    const deliveryLocationListUpdated = (id: string) => {
        setDeliveryLocationFilterList(
            deliveryLocationFilterList.map((filter) => {
                if (filter.id === id) {
                    return { ...filter, checked: !filter.checked };
                }
                return filter;
            }),
        );
    };

    const orderStatusListUpdated = (id: string) => {
        setOrderStatusFilterList(
            orderStatusFilterList.map((filter) => {
                if (filter.id === id) {
                    return { ...filter, checked: !filter.checked };
                }
                return filter;
            }),
        );
    };

    const productDescriptionListUpdated = (id: string) => {
        setProductDescriptionFilterList(
            productDescriptionFilterList.map((filter) => {
                if (filter.id === id) {
                    return { ...filter, checked: !filter.checked };
                }
                return filter;
            }),
        );
    };

    function updatePoDateChange(
        dateRange: DateRange,
        rawStartDate: string,
        rawEndDate: string,
    ) {
        const newFilters = poDateFilterOptions.map((filter) => {
            if (filter.id === "Date range") {
                return {
                    ...filter,
                    selectedValues: dateRange,
                    checked: true,
                    rawStartDate: rawStartDate,
                    rawEndDate: rawEndDate,
                };
            }
            return filter;
        });

        setPoDateFilterOptions(newFilters);
    }

    function updateRequestedDeliveryDateChange(
        dateRange: DateRange,
        rawStartDate: string,
        rawEndDate: string,
    ) {
        const newFilters = requestedDateFilterOptions.map((filter) => {
            if (filter.id === "Date range") {
                return {
                    ...filter,
                    selectedValues: dateRange,
                    checked: true,
                    rawStartDate: rawStartDate,
                    rawEndDate: rawEndDate,
                };
            }
            return filter;
        });
        setRequestedDateFilterOptions(newFilters);
    }

    function poDateUpdated(selectedValues: DateRange) {
        const updatedValues = poDateFilterOptions.map((filter) =>
            filter.id === selectedValues.id
                ? {
                      ...filter,
                      checked: true,
                  }
                : {
                      ...filter,
                      checked: false,
                  },
        );
        setPoDateFilterOptions(updatedValues);
    }

    function requestedDeliveryDateUpdated(selectedValues: DateRange) {
        const updatedValues = requestedDateFilterOptions.map((filter) =>
            filter.id === selectedValues.id
                ? {
                      ...filter,
                      checked: true,
                  }
                : {
                      ...filter,
                      checked: false,
                  },
        );
        setRequestedDateFilterOptions(updatedValues);
    }

    function onBadgeDismiss(id: string, title: string) {
        applyFiltersDismiss(id, title);
        setTriggerBadge(!triggerBadge);
    }

    useEffect(() => {
        setEmployeeFilterList(employeeFilters);
    }, [employeeFilters]);

    useEffect(() => {
        setCustomerFilterList(customerFilters);
    }, [customerFilters]);

    useEffect(() => {
        setShipFromFilterList(shipFromFilters);
    }, [shipFromFilters]);

    useEffect(() => {
        setDeliveryLocationFilterList(deliveryLocationFilters);
    }, [deliveryLocationFilters]);

    useEffect(() => {
        setOrderStatusFilterList([...orderStatusFilters]);
    }, [orderStatusFilters]);

    useEffect(() => {
        setProductDescriptionFilterList([...productDescriptionFilters]);
    }, [productDescriptionFilters]);

    useEffect(() => {
        setPoDateFilterOptions([...poDateFilterData]);
    }, [poDateFilterData]);

    useEffect(() => {
        setPoDateFilterOptions([...requestedDateFilterData]);
    }, [requestedDateFilterData]);

    return (
        <>
            <div className="ecom-grid-filter-badges grid-bar-column">
                <div className="filter-badges">
                    <FilterBadges
                        filters={employeeFilters}
                        onDismiss={onBadgeDismiss}
                        title={"Employee Responsible"}
                    />
                    <FilterBadges
                        filters={customerFilters}
                        onDismiss={onBadgeDismiss}
                        title={"Customer"}
                    />
                    <FilterBadges
                        filters={poDateFilterData}
                        isDateRange
                        onDismiss={onBadgeDismiss}
                        title={"PO Date"}
                    />
                    <FilterBadges
                        filters={productDescriptionFilters}
                        onDismiss={onBadgeDismiss}
                        title={"Product Description"}
                    />
                    <FilterBadges
                        filters={shipFromFilters}
                        onDismiss={onBadgeDismiss}
                        title={"Ship From"}
                    />
                    <FilterBadges
                        filters={deliveryLocationFilters}
                        onDismiss={onBadgeDismiss}
                        title={"Delivery Location"}
                    />
                    <FilterBadges
                        filters={requestedDateFilterData}
                        isDateRange
                        onDismiss={onBadgeDismiss}
                        title={"Requested Delivery"}
                    />
                    <FilterBadges
                        filters={orderStatusFilters}
                        onDismiss={onBadgeDismiss}
                        title={"Order Status"}
                    />
                    {hasActiveFilter && (
                        <div className="clear-filter-badges">
                            <Button
                                text="Clear Filters"
                                size="medium"
                                variant="text"
                                color="blue"
                                onClick={ClearFilters}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className="ecom-grid-filters grid-bar-column"
                data-testid={testId}
            >
                <div className="grid-filter-button">
                    <Button
                        color="primary"
                        text="Filter"
                        onClick={openModal}
                        icon={<FontAwesomeIcon icon={faFilter} />}
                        testId={FilterElements.FilterButton}
                    />
                </div>
                <Drawer
                    className="ecom-drawer"
                    isOpen={modalState}
                    onRequestClose={closeModal}
                    title={"Filters"}
                >
                    <div className="ecom-filter-title" />
                    <AlertBannerProvider>
                        <DrawerContent className="ecom-filter-contents">
                            {
                                <div className="ecom-filter-contents-list">
                                    <FilterList
                                        title="Employee Responsible"
                                        showSearch
                                        filterUpdated={employeeListUpdated}
                                        filterList={employeeFilterList}
                                    />
                                    <FilterList
                                        title="Customer"
                                        showSearch
                                        filterUpdated={customerListUpdated}
                                        filterList={customerFilterList}
                                    />
                                    <FilterDateRangeGroup
                                        title={"PO Date"}
                                        group={"PO Date"}
                                        isFutureRange={false}
                                        onChange={poDateUpdated}
                                        dateRangeFilterOptions={
                                            poDateFilterOptions
                                        }
                                        onDateChange={updatePoDateChange}
                                    />
                                    <FilterList
                                        title="Product Description"
                                        showSearch
                                        filterUpdated={
                                            productDescriptionListUpdated
                                        }
                                        filterList={
                                            productDescriptionFilterList
                                        }
                                    />
                                    <FilterList
                                        title="Ship From"
                                        showSearch
                                        filterUpdated={shipFromListUpdated}
                                        filterList={shipFromFilterList}
                                    />
                                    <FilterList
                                        title="Delivery Location"
                                        showSearch
                                        filterUpdated={
                                            deliveryLocationListUpdated
                                        }
                                        filterList={deliveryLocationFilterList}
                                    />
                                    <FilterDateRangeGroup
                                        title={"Requested Delivery Date"}
                                        group={"Requested Date"}
                                        isFutureRange
                                        onChange={requestedDeliveryDateUpdated}
                                        dateRangeFilterOptions={
                                            requestedDateFilterOptions
                                        }
                                        onDateChange={
                                            updateRequestedDeliveryDateChange
                                        }
                                    />
                                    <FilterList
                                        title="Order Status"
                                        showSearch={false}
                                        filterUpdated={orderStatusListUpdated}
                                        filterList={orderStatusFilterList}
                                    />
                                </div>
                            }
                        </DrawerContent>
                    </AlertBannerProvider>
                    <DrawerActions>
                        <Button text="Clear Filters" onClick={ClearFilters} />
                        <Button
                            color="primary"
                            text="Apply Filters"
                            onClick={ApplyFilters}
                        />
                    </DrawerActions>
                </Drawer>
            </div>
        </>
    );
}
