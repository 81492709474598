import React, { useEffect } from "react";
import { Breadcrumb } from "@components/breadcrumbs";
import { ShipmentDetailsGridDisplay } from "@components/display/shipment-details/shipment-details-grid";
import {
    ShipmentDetailsHeader,
    ShipmentDetailsHeaderDisplayProps,
} from "@components/display/shipment-details/shipment-details-header";
import { Card, Divider } from "@cpchem/covalence-ui";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import { NotificationPage } from "@pages/error-page";
import {
    getShipmentDetailsContent,
    getShipmentDetailsRequestStatus,
    requestShipmentDetails,
} from "@stores/shipment-details";
import { TransformShipmentDetailsRaw } from "@utilities/data/shipment-details-mapping";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { ShipmentDetails } from "./elements";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { createPageViewEvent } from "@cpchem/logging";
import { SHIPMENT_DETAILS_LOADING } from "@utilities/log/log-events";
import { LoadingDisplay } from "@components/loading";

export function ShipmentDetailsPage(): JSX.Element {
    const dispatch = useAppDispatch();
    const shipmentDetailsRaw = useAppSelector(getShipmentDetailsContent);
    const shipmentDetails = TransformShipmentDetailsRaw(shipmentDetailsRaw);
    const shipmentDetailsRequestStatus = useAppSelector(
        getShipmentDetailsRequestStatus,
    );

    const { orderNumber, itemNumber } = useParams();

    const headerShipmentDetailsHeaderProps: ShipmentDetailsHeaderDisplayProps =
        {
            itemNumber: shipmentDetails.itemNumber,
            itemDescription: shipmentDetails.itemDescription,
            itemMaterialNumber: shipmentDetails.itemMaterialNumber,
            itemCustomerMaterialNumber:
                shipmentDetails.itemCustomerMaterialNumber,
            itemQuantity: shipmentDetails.orderedQuantity,
            itemConfirmedQuantity: shipmentDetails.totalConfirmedQuantity,
            shipFrom: shipmentDetails.shipFrom,
            shipments: shipmentDetails.shipmentsQuantity,
        };

    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("Shipment Details page"));
        ecomTrackEvent({
            type: SHIPMENT_DETAILS_LOADING,
            contents: JSON.stringify({ orderNumber, itemNumber }),
        });
        dispatch(
            requestShipmentDetails({
                orderNumber: orderNumber ?? "",
                itemNumber: itemNumber ?? "",
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ordersPageLink = "/orders";

    document.title = `Item # ${itemNumber} Shipments - CustomerConnect`;

    return (
        <div className="shipments-page">
            <div className="shipments-page-header">
                <div>
                    <Breadcrumb
                        title={`Item # ${itemNumber} Shipments` as string}
                        links={[
                            { display: "Your Orders", link: ordersPageLink },
                            {
                                display: `Order # ${orderNumber}`,
                                link: `/orders/${orderNumber}`,
                            },
                        ]}
                    />
                </div>
                <h1 className="header-title">{`Item # ${itemNumber} Shipments`}</h1>
            </div>
            <Divider />
            <div className="shipments-page-body">
                {shipmentDetailsRequestStatus === "error" ? (
                    <NotificationPage status={shipmentDetailsRequestStatus} />
                ) : (
                    <LoadingDisplay
                        className="body-container"
                        showLoading={shipmentDetailsRequestStatus === "loading"}
                    >
                        <ShipmentDetailsHeader
                            {...headerShipmentDetailsHeaderProps}
                        />
                        <div className="body-content">
                            <Card className="content-card no-border">
                                <div
                                    className="card-grid-filter-bar"
                                    id="card-grid-filter-bar"
                                />
                                <ShipmentDetailsGridDisplay
                                    shipmentDetails={shipmentDetails}
                                    testId={ShipmentDetails.LineItemsGrid}
                                />
                            </Card>
                        </div>
                    </LoadingDisplay>
                )}
            </div>
        </div>
    );
}
