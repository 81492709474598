import { AccountInformation } from "@cpchem/azure-auth";
export type UserTraits = {
    name: string;
    isGuest: boolean;
    company: string;
    email: string;
};
export function getUserTraits(userAcct: AccountInformation): UserTraits {
    const name = userAcct.name ? (userAcct.name as string) : userAcct.username;
    const company = userAcct.organizationalDetails?.company
        ? (userAcct?.organizationalDetails?.company as string)
        : userAcct.username;
    const email = userAcct.personalDetails?.email
        ? (userAcct.personalDetails?.email as string)
        : "unknown";
    return {
        name: name,
        isGuest: userAcct.isGuest,
        company: company,
        email: email,
    };
}
