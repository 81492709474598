import React from "react";
import "./styles.scss";
import { LoadingDisplay } from "@components/loading";

export function LoadingPage(): JSX.Element {
    return (
        <LoadingDisplay
            className="loading-page-content"
            loadingMessage=""
            showLoading
        />
    );
}
