import config from "@config";
import { OrderLineItemRaw } from "@models/shipment-details";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@stores";
import { ApplicationStatus } from "@models/data-store";
import { ApiOptionsService } from "@services/api-options";
import { ApiOptionsServiceKey, ServiceLocator } from "@services";

export type APIShipmentsQueryOptions = {
    itemNumber: string;
    orderNumber: string;
};

type ShipmentDetailsSliceState = {
    status: ApplicationStatus;
    error: string | null;
    content: OrderLineItemRaw | null;
};

const initialState: ShipmentDetailsSliceState = {
    content: null,
    error: null,
    status: "ready",
};

type payloadTypes = OrderLineItemRaw | undefined;

function isPayloadOrderLineItemRaw(
    payload: payloadTypes,
): payload is OrderLineItemRaw {
    return (payload as OrderLineItemRaw).salesDocumentItem !== undefined;
}

export const requestShipmentDetails = createAsyncThunk(
    "orderHistory/itemDetails/shipmentDetails",
    async (queryOptions: APIShipmentsQueryOptions) => {
        const apiOptionsService =
            ServiceLocator.get<ApiOptionsService>(ApiOptionsServiceKey);
        const options = await apiOptionsService.getApiOptions();
        const url = `${config.api.orderHistory.url}/${queryOptions.orderNumber}/shipments/${queryOptions.itemNumber}?$expand=ShipmentDetails($expand=LineItems)`;

        const res = await fetch(url, options);
        return res.json();
    },
);

export const ShipmentDetailsSlice = createSlice({
    name: "shipmentDetails",
    initialState,
    reducers: {
        updateShipmentDetails(state, action) {
            state.content = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestShipmentDetails.pending, (state) => {
            (state.status = "loading"), (state.error = null);
            state.content = null;
        });
        builder.addCase(
            requestShipmentDetails.fulfilled,
            (state, { payload }) => {
                if (isPayloadOrderLineItemRaw(payload)) {
                    state.content = payload;
                    state.status = "ready";
                } else {
                    state.status = "error";
                    state.error = `Error getting Shipmen Details. Error code: ${payload.statusCode}`;
                }
            },
        );
        builder.addCase(requestShipmentDetails.rejected, (state) => {
            state.status = "error";
            state.error = "Error getting Item Details";
        });
    },
});

export const { updateShipmentDetails } = ShipmentDetailsSlice.actions;

export const getShipmentDetailsRequestStatus = (state: RootState) =>
    state.ShipmentDetails.status;
export const getShipmentDetailsError = (state: RootState) =>
    state.ShipmentDetails.status;
export const getShipmentDetailsContent = (state: RootState) =>
    state.ShipmentDetails.content;

export default ShipmentDetailsSlice.reducer;
