export function buildOrderProductLineStringArray(
    productLines: string[],
): string[] {
    const productLineValues: string[] = [];
    productLines.forEach((productLineItem) => {
        productLineValues.push(`'${productLineItem}'`);
    });

    return productLineValues;
}
