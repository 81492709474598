import { Button } from "@cpchem/covalence-ui";
import { DeliveryLineItem } from "@models/shipment-details";
import React from "react";
import { ShipmentDetailsRow } from "../../elements";

export interface DeliveryNumberProps {
    deliveryNumber: number;
    lineItems: DeliveryLineItem[];
    openDeliveryModal: (
        deliveryNumber: number,
        lineItems: DeliveryLineItem[],
    ) => void;
}

export function DeliveryNumberDisplay(props: DeliveryNumberProps): JSX.Element {
    return props.lineItems && props.lineItems.length > 1 ? (
        <Button
            className="line-details-modal-button"
            variant="text"
            size="large"
            color="blue"
            text={props.deliveryNumber}
            onClick={() =>
                props.openDeliveryModal(props.deliveryNumber, props.lineItems)
            }
            testId={`${ShipmentDetailsRow.DeliveryButton}-${props.deliveryNumber}`}
        />
    ) : (
        <span
            data-testid={`${ShipmentDetailsRow.DeliverySpan}-${props.deliveryNumber}`}
        >
            {props.deliveryNumber}
        </span>
    );
}
