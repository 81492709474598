import { DateRange } from "@models/date-range";

export function getCurrentMonth(): DateRange {
    const currentDate = new Date();

    const currentMonthDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
    );

    const currentMonth = `0${currentMonthDate.getMonth() + 1}`;

    return {
        startDate: `${currentMonthDate.getFullYear()}-${currentMonth.slice(
            -2,
        )}-01`,
        endDate: `${currentMonthDate.getFullYear()}-${currentMonth.slice(
            -2,
        )}-${currentMonthDate.getDate()}`,
    };
}
