import React, { useState } from "react";
import "./styles.scss";

export interface FilterDateRangeProps {
    className?: string;
    startDate?: string;
    endDate?: string;
    onChange: (date: Date, startDateFlag: boolean) => void;
    isFutureRange?: boolean;
    testId?: string;
}

export function FilterDateRange({
    className,
    testId,
    onChange,
    isFutureRange,
    startDate,
    endDate,
}: FilterDateRangeProps) {
    const stringStartDate = new Date(startDate as string);
    const stringEndDate = new Date(endDate as string);
    const currentTime = new Date();
    const oneDay = 1000 * 60 * 60 * 24;

    const [startDateNew, setStartDate] = useState(stringStartDate);
    const [endDateNew, setEndDate] = useState(stringEndDate);

    function handleStartDateChange(date: Date) {
        onChange(date, true);
        setStartDate(date);
    }

    function handleEndDateChange(date: Date) {
        onChange(date, false);
        if (startDateNew) {
            if (startDateNew.valueOf() >= date.valueOf()) {
                const newStartDate = new Date();
                newStartDate.setTime(date.getTime() - oneDay);
                setStartDate(newStartDate);
            }
        }
        setEndDate(date);
    }
    const cnParts = ["date-range", "cui-boxed-input"];
    if (className) {
        cnParts.push(className);
    }

    const cn = cnParts.join(" ");
    return (
        <div className={cn} data-testid={testId}>
            <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                id="start-date-picker"
                value={startDateNew?.toISOString().split("T")[0]}
                max={endDateNew?.toISOString().split("T")[0]}
                onChange={(event) =>
                    handleStartDateChange(
                        new Date(`${event.target.value}T00:00:00`),
                    )
                }
            />
            <label className="to-label" htmlFor="to-date-picker">
                to
            </label>
            <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                id="end-date-picker"
                value={endDateNew?.toISOString().split("T")[0]}
                max={
                    isFutureRange
                        ? undefined
                        : currentTime.toISOString().split("T")[0]
                }
                onChange={(event) =>
                    handleEndDateChange(
                        new Date(`${event.target.value}T00:00:00`),
                    )
                }
            />
        </div>
    );
}
