import React, { useState, useEffect, createContext, useContext } from "react";

export interface BreakPointObject {
    [key: string]: string;
}

export interface MediaQueryListObjectType {
    [key: string]: MediaQueryList;
}

export interface MatchObjectType {
    [key: string]: boolean;
}
export interface BreakPointProps {
    children: JSX.Element;
    queries: BreakPointObject;
}

export type mediaType = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

const defaultValue: MatchObjectType = {};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = (breakPointProviderProps: BreakPointProps) => {
    const [queryMatch, setQueryMatch] = useState<MatchObjectType>({});
    const { children, queries } = breakPointProviderProps;
    useEffect(() => {
        const mediaQueryLists: MediaQueryListObjectType = {
            xs: window.matchMedia(""),
            sm: window.matchMedia(""),
            md: window.matchMedia(""),
            lg: window.matchMedia(""),
            xl: window.matchMedia(""),
            xxl: window.matchMedia(""),
        };
        const keys = Object.keys(queries);
        let isAttached = false;
        const matches: MatchObjectType = {};

        keys.forEach((media) => {
            mediaQueryLists[media] = window.matchMedia(
                queries[media as mediaType],
            );
            matches[media] = mediaQueryLists[media].matches;
        });

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce(
                (acc: MatchObjectType, media) => {
                    acc[media] = !!(
                        mediaQueryLists[media] && mediaQueryLists[media].matches
                    );
                    return acc;
                },
                {},
            );
            setQueryMatch(updatedMatches);
        };

        if (window && window.matchMedia) {
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media] = window.matchMedia(queries[media]);
                    matches[media] = mediaQueryLists[media].matches;
                } else {
                    matches[media] = false;
                }
            });
            setQueryMatch(matches);
            isAttached = true;
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media].addEventListener(
                        "change",
                        handleQueryListener,
                    );
                }
            });
        }

        return () => {
            if (isAttached) {
                keys.forEach((media) => {
                    if (typeof queries[media] === "string") {
                        mediaQueryLists[media].removeEventListener(
                            "change",
                            handleQueryListener,
                        );
                    }
                });
            }
        };
    }, [queries]);

    return (
        <BreakpointContext.Provider value={queryMatch}>
            {children}
        </BreakpointContext.Provider>
    );
};

function useBreakpoint() {
    const context = useContext(BreakpointContext);
    if (context === defaultValue) {
        throw new Error("useBreakpoint must be used within BreakpointProvider");
    }
    return context;
}
export { useBreakpoint, BreakpointProvider };
