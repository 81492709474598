import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition,
} from "@cpchem/covalence-ui";
import { mediaType } from "@hooks/breakpoint";
const baseColumns: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "PO #",
        accessor: "poInformation",
        alignment: "left",
        sortType: "string",
    },
    {
        Header: "Order #",
        accessor: "orderInformation",
        alignment: "left",
        sortType: "string",
    },
    {
        Header: "Order Create Date",
        accessor: "salesOrderCreateDate",
        alignment: "left",
        sortType: "datetime",
    },
    {
        Header: "Items",
        accessor: "items",
        alignment: "right",
        sortType: "string",
    },
    {
        Header: "Deliver to",
        accessor: "deliveryInformation",
        alignment: "left",
        sortType: "string",
    },
    {
        Header: "Status",
        accessor: "status",
        id: "status",
        alignment: "left",
        sortType: "string",
    },
];

const actionsColumn: DataGridColumnDefinition = {
    Header: "Actions",
    disableSortBy: true,
    alignment: "center",
};

const extraColumnsFull: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Requested Delivery Date",
        accessor: "requiredDeliveryDate",
        alignment: "left",
        sortType: "datetime",
    },
    {
        Header: "Incoterms",
        accessor: "incoterms",
        alignment: "left",
        sortType: "string",
    },
];

const extraColumnsMedium: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Incoterms",
        accessor: "incoterms",
        disableSortBy: false,
        alignment: "left",
        sortType: "string",
    },
];

export function GetColumnsByScreenSize(size: mediaType) {
    const columnsDefinition: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[] = [...baseColumns];

    if (size === "xxl") {
        columnsDefinition.push(...extraColumnsFull);
    }
    if (size === "xl") {
        columnsDefinition.push(...extraColumnsMedium);
    }

    columnsDefinition.push(actionsColumn);
    return columnsDefinition;
}
