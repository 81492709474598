import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import {
    selectCheckedUserProductLinesAsString,
    selectCheckedUserProductLines,
} from "@stores/product-lines";
import { Card, DisplayAmount, Divider } from "@cpchem/covalence-ui";
import {
    setSelectedFiltersFromDateOnLoad,
    setSelectedFiltersFromListOnLoad,
} from "../../utilities/filters";
import { APIQueryOptions } from "@models/orders";
import {
    selectOrderHistoryItemsStatus,
    selectOrderHistoryItems,
    selectOrderHistoryItemsCount,
    selectPageNumber,
    selectRowsToDisplay,
    selectOrderDateRange,
    getOrderHistoryItems,
    pageUpdate,
    pagePartial,
    setPageNumber,
    setRowsToDisplay,
    setOrderDateRange,
    selectOrderHistoryMode,
    selectSortValue,
    setSortValue,
} from "@stores/order-history";
import {
    selectFilters,
    selectHasActiveFilter,
    getProductDescriptionItems,
    getEmployeeFilterItems,
    getCustomerFilterItems,
    getShipFromFilterItems,
    getDeliveryLocationItems,
    updateFilters,
    resetAllFilters,
    resetNonPartialFilters,
    resetBadge,
} from "@stores/filters";
import { FilterQueryOptions, FilterListItem } from "@models/filters";
import {
    FilterDateSelect,
    FilterContainer,
    DateFilterItem,
    dateFilters,
} from "@components/filters";
import { DateRange } from "@models";
import { TransformOrderHistoryRawData } from "@utilities/data/orders-mapping";
import { DateRangeItem } from "@components/filters/types";
import "../data-grid-styles.scss";
import { OrdersDisplay } from "@components/display";
import { getNextSortValues, sortValue } from "@utilities/pages/order-history";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { createPageViewEvent } from "@cpchem/logging";
import { LOADING_FILTER_LIST, LOADING_ORDERS } from "@utilities/log/log-events";
import {
    CUSTOMER_FILTER_ID,
    EMPLOYEE_FILTER_ID,
    SHIPFROM_FILTER_ID,
    LOCATION_FILTER_ID,
    PRODUCT_FILTER_ID,
} from "@stores/filters/filter-names";
import { LoadingDisplay } from "@components/loading";

function GetOrderDateRange(selectedOrderDateRange: DateFilterItem): DateRange {
    const daterange: DateRange = {
        id: selectedOrderDateRange.id,
        startDate: selectedOrderDateRange.startDate,
        endDate: selectedOrderDateRange.endDate,
    };

    return daterange;
}

export interface OrderHistoryFilters {
    employees: string[];
    customers: string[];
    shipFrom: string[];
    locations: string[];
    statuses: string[];
    productDescriptions: string[];
    deliveryDate: DateRange;
    poDate: DateRange;
}

export function OrdersPage(): JSX.Element {
    const dispatch = useAppDispatch();

    const Filters = useAppSelector(selectFilters);
    const ProductLines = useAppSelector(selectCheckedUserProductLinesAsString);
    const ProductLinesArray = useAppSelector(selectCheckedUserProductLines);
    const GetOrdersStatus = useAppSelector(selectOrderHistoryItemsStatus);
    const GetPageMode = useAppSelector(selectOrderHistoryMode);
    const PageNumber = useAppSelector(selectPageNumber);
    const RowsToDisplay = useAppSelector(selectRowsToDisplay);
    const OrderDateRange = useAppSelector(selectOrderDateRange);
    const hasActiveFilter = useAppSelector(selectHasActiveFilter);
    const SortValue = useAppSelector(selectSortValue);

    const [selectedFilters, setSelectedFilters] = useState<OrderHistoryFilters>(
        {
            employees: setSelectedFiltersFromListOnLoad(
                Filters.employeeFilters,
            ),
            customers: setSelectedFiltersFromListOnLoad(
                Filters.customerFilters,
            ),
            shipFrom: setSelectedFiltersFromListOnLoad(Filters.shipFromFilters),
            locations: setSelectedFiltersFromListOnLoad(
                Filters.deliveryLocationFilters,
            ),
            statuses: setSelectedFiltersFromListOnLoad(Filters.statusFilters),
            productDescriptions: setSelectedFiltersFromListOnLoad(
                Filters.productDescriptionFilters,
            ),
            deliveryDate: setSelectedFiltersFromDateOnLoad(
                Filters.requestedDeliveryDateFilters,
            ),
            poDate: setSelectedFiltersFromDateOnLoad(Filters.poDateFilters),
        },
    );

    // orders
    const OrderHistoryItemsListRaw = useAppSelector(selectOrderHistoryItems);
    const OrderHistoryItemsList = TransformOrderHistoryRawData(
        OrderHistoryItemsListRaw,
    );
    const OrderHistoryItemsCount = useAppSelector(selectOrderHistoryItemsCount);

    const orderDateRangeChanged = (id: string) => {
        const selectedFilter = dateFilters.find((row) => row.id === id);
        if (selectedFilter !== undefined && selectedFilter !== null) {
            setOrderDateRange(selectedFilter);
        }

        // clear all selected filters on a date range change
        setSelectedFilters({
            ...selectedFilters,
            deliveryDate: { id: "All", startDate: "", endDate: "" },
            poDate: { id: "All", startDate: "", endDate: "" },
        });

        dispatch(setOrderDateRange(selectedFilter));
        dispatch(resetNonPartialFilters());
        dispatch(pagePartial());
    };

    const [filterBarHeight, setFilterBarHeight] = useState(
        getElementHeight("grid-filter-bar", 48),
    );

    function getElementHeight(id: string, defaultHeight: number) {
        const element = document.getElementById(id);
        if (element) {
            const elementHeight = element.getBoundingClientRect().height;

            return Math.floor(elementHeight - 1);
        }
        return defaultHeight;
    }

    const applyFiltersDismiss = (id: string, title: string) => {
        let selectedEmployees = selectedFilters.employees;
        let selectedCustomers = selectedFilters.customers;
        let selectedShipFrom = selectedFilters.shipFrom;
        let selectedLocations = selectedFilters.locations;
        let selectedStatuses = selectedFilters.statuses;
        let selectedProductDescriptions = selectedFilters.productDescriptions;
        let selectedPoDate = selectedFilters.poDate;
        let selectedRequestedDeliveryDate = selectedFilters.deliveryDate;

        switch (title) {
            case "Employee Responsible":
                selectedEmployees = selectedFilters.employees.filter(
                    (e) => e !== id,
                );
                break;

            case "Customer":
                selectedCustomers = selectedFilters.customers.filter(
                    (e) => e !== id,
                );
                break;

            case "Ship From":
                selectedShipFrom = selectedFilters.shipFrom.filter(
                    (e) => e !== id,
                );
                break;

            case "Delivery Location":
                selectedLocations = selectedFilters.locations.filter(
                    (e) => e !== id,
                );
                break;

            case "Order Status":
                selectedStatuses = selectedFilters.statuses.filter(
                    (e) => e !== id,
                );
                break;
            case "Product Description":
                selectedProductDescriptions =
                    selectedFilters.productDescriptions.filter((e) => e !== id);
                break;

            case "PO Date":
                selectedPoDate = {
                    id: "All",
                    startDate: "",
                    endDate: "",
                };
                break;

            case "Requested Delivery":
                selectedRequestedDeliveryDate = {
                    id: "All",
                    startDate: "",
                    endDate: "",
                };
                break;
        }

        setSelectedFilters((prevState) => ({
            employees: [...selectedEmployees],
            customers: [...selectedCustomers],
            shipFrom: [...selectedShipFrom],
            locations: [...selectedLocations],
            statuses: [...selectedStatuses],
            productDescriptions: [...selectedProductDescriptions],
            poDate: {
                ...prevState.poDate,
                id: selectedPoDate.id,
                startDate: selectedPoDate.startDate,
                endDate: selectedPoDate.endDate,
            },
            deliveryDate: {
                ...prevState.deliveryDate,
                id: selectedRequestedDeliveryDate.id,
                startDate: selectedRequestedDeliveryDate.startDate,
                endDate: selectedRequestedDeliveryDate.endDate,
            },
        }));

        dispatch(resetBadge({ id: id, title: title }));
        dispatch(setPageNumber(1));
        dispatch(pageUpdate());
    };

    const applyFilters = (
        employeeFilters: FilterListItem[],
        customerFilters: FilterListItem[],
        shipFromFilters: FilterListItem[],
        locationFilters: FilterListItem[],
        productFilters: FilterListItem[],
        statusFilters: FilterListItem[],
        poDateFilters: DateRangeItem[],
        requestedDeliveryDateFilters: DateRangeItem[],
        selectedEmployeeFilters: string[],
        selectedCustomerFilters: string[],
        selectedShipFromFilters: string[],
        selectedLocationFilters: string[],
        selectedStatusFilters: string[],
        selectedProductDescriptionFilters: string[],
        selectedPoFilter: DateRange,
        selectedRequestedDeliveryFilter: DateRange,
    ) => {
        setSelectedFilters((prevState) => ({
            employees: [...selectedEmployeeFilters],
            customers: [...selectedCustomerFilters],
            shipFrom: [...selectedShipFromFilters],
            locations: [...selectedLocationFilters],
            statuses: [...selectedStatusFilters],
            productDescriptions: [...selectedProductDescriptionFilters],
            poDate: {
                ...prevState.poDate,
                id: selectedPoFilter.id,
                startDate: selectedPoFilter.startDate,
                endDate: selectedPoFilter.endDate,
            },
            deliveryDate: {
                ...prevState.deliveryDate,
                id: selectedRequestedDeliveryFilter.id,
                startDate: selectedRequestedDeliveryFilter.startDate,
                endDate: selectedRequestedDeliveryFilter.endDate,
            },
        }));

        dispatch(
            updateFilters({
                employeeFilters: employeeFilters,
                customerFilters: customerFilters,
                shipFromFilters: shipFromFilters,
                deliveryLocationFilters: locationFilters,
                productDescriptionFilters: productFilters,
                statusFilters: statusFilters,
                poDateFilters: poDateFilters,
                requestedDeliveryDateFilters: requestedDeliveryDateFilters,
            }),
        );

        dispatch(setPageNumber(1));
        dispatch(pageUpdate());
    };

    const clearFilters = () => {
        setSelectedFilters((prevState) => ({
            employees: [...[]],
            customers: [...[]],
            shipFrom: [...[]],
            locations: [...[]],
            statuses: [...[]],
            productDescriptions: [...[]],
            deliveryDate: {
                ...prevState.deliveryDate,
                id: "All",
                startDate: "",
                endDate: "",
            },
            poDate: {
                ...prevState.poDate,
                id: "All",
                startDate: "",
                endDate: "",
            },
        }));

        dispatch(setPageNumber(1));
        dispatch(pageUpdate());
        dispatch(resetAllFilters());
    };

    const resetFilters = () => {
        const filterOptions: FilterQueryOptions = {
            dateSelection: GetOrderDateRange(OrderDateRange),
            userProducts: ProductLines.toString(),
        };
        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: EMPLOYEE_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(getEmployeeFilterItems(filterOptions));

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: CUSTOMER_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(getCustomerFilterItems(filterOptions));

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: SHIPFROM_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(getShipFromFilterItems(filterOptions));

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: LOCATION_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(getDeliveryLocationItems(filterOptions));

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: PRODUCT_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(getProductDescriptionItems(filterOptions));
        dispatch(resetAllFilters());
    };
    // Posibility of applying another status (partial) to leave other processes separate for the specific need of date change.
    const partialResetFilters = () => {
        const filterOptions: FilterQueryOptions = {
            dateSelection: GetOrderDateRange(OrderDateRange),
            userProducts: ProductLines.toString(),
        };
        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: EMPLOYEE_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(
            getEmployeeFilterItems({
                ...filterOptions,
                currentFilterList: Filters.employeeFilters,
            }),
        );

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: CUSTOMER_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(
            getCustomerFilterItems({
                ...filterOptions,
                currentFilterList: Filters.customerFilters,
            }),
        );

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: SHIPFROM_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(
            getShipFromFilterItems({
                ...filterOptions,
                currentFilterList: Filters.shipFromFilters,
            }),
        );

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: LOCATION_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(
            getDeliveryLocationItems({
                ...filterOptions,
                currentFilterList: Filters.deliveryLocationFilters,
            }),
        );

        ecomTrackEvent({
            type: LOADING_FILTER_LIST,
            contents: JSON.stringify({
                field: PRODUCT_FILTER_ID,
                ...filterOptions,
            }),
        });
        dispatch(
            getProductDescriptionItems({
                ...filterOptions,
                currentFilterList: Filters.productDescriptionFilters,
            }),
        );
    };

    const queryOrders = () => {
        const pageOptions: APIQueryOptions = {
            pageSize: RowsToDisplay,
            pageLocation: PageNumber,
            dateSelection: GetOrderDateRange(OrderDateRange),
            userProductLines: ProductLinesArray,
            employeeFilters: selectedFilters.employees,
            customerFilters: selectedFilters.customers,
            shipFromFilters: selectedFilters.shipFrom,
            locationFilters: selectedFilters.locations,
            orderStatusFilters: selectedFilters.statuses,
            productDescriptionFilters: selectedFilters.productDescriptions,
            poDateFilter: selectedFilters.poDate,
            requestedDeliveryDateFilter: selectedFilters.deliveryDate,
            sortValue: SortValue,
        };

        ecomTrackEvent({
            type: LOADING_ORDERS,
            contents: JSON.stringify({
                orderStatus: GetOrdersStatus,
                ...pageOptions,
            }),
        });

        setFilterBarHeight(getElementHeight("grid-filter-bar", 48));
        dispatch(getOrderHistoryItems(pageOptions));
    };

    useEffect(() => {
        if (GetOrdersStatus === "partial") {
            queryOrders();
            partialResetFilters();
        }

        if (GetOrdersStatus === "reset") {
            queryOrders();
            resetFilters();
        }

        if (GetOrdersStatus === "update") {
            queryOrders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GetOrdersStatus]);

    useEffect(() => {
        dispatch(pageUpdate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectOrderHistoryMode]);

    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("Order's Page"));
    }, []);

    const onPageChange = (value: number) => {
        if (PageNumber !== value) {
            dispatch(setPageNumber(value));
            dispatch(pageUpdate());
        }
    };

    const onRowsToDisplayChange = (value: string) => {
        if (RowsToDisplay !== (value as unknown as DisplayAmount)) {
            dispatch(setRowsToDisplay(Number(value)));
            dispatch(pageUpdate());
        }
    };

    const onSelectSortValue = (newSort: sortValue, currentSort: sortValue) => {
        const newSortValue = getNextSortValues(currentSort, newSort);
        dispatch(setSortValue(newSortValue));
        dispatch(pageUpdate());
    };

    if (GetPageMode) document.title = "Orders - CustomerConnect";

    const style: { [id: string]: string } = {
        "--top-filter-bar": `${filterBarHeight}px`,
    };

    return (
        <div className="orders-page" style={style}>
            <div className="orders-page-header">
                <h1 className="orders-page-title">Your Orders</h1>
            </div>

            <Divider />
            <div className="orders-page-content">
                <LoadingDisplay
                    showLoading={GetOrdersStatus === "loading"}
                    loadingMessage="This page is taking longer than usual to load. Please wait while your content is retrieved."
                    className="orders-grid"
                >
                    <Card className="orders-page-card">
                        <div className="grid-filter-bar" id="grid-filter-bar">
                            <div className="grid-drop-down grid-bar-column">
                                <div className="grid-message">
                                    <div>
                                        {GetOrdersStatus === "loading"
                                            ? "Loading"
                                            : OrderHistoryItemsCount}{" "}
                                        orders created in
                                    </div>
                                </div>
                                <div className="grid-date-filter">
                                    <FilterDateSelect
                                        selectedFilter={OrderDateRange}
                                        filters={dateFilters}
                                        onChange={orderDateRangeChanged}
                                    />
                                </div>
                            </div>
                            <FilterContainer
                                applyFiltersDismiss={applyFiltersDismiss}
                                applyFilters={applyFilters}
                                clearFilters={clearFilters}
                                hasActiveFilter={hasActiveFilter}
                                currentDateRange={OrderDateRange}
                                poDateFilterData={Filters.poDateFilters}
                                requestedDateFilterData={
                                    Filters.requestedDeliveryDateFilters
                                }
                                employeeFilters={Filters.employeeFilters}
                                customerFilters={Filters.customerFilters}
                                productDescriptionFilters={
                                    Filters.productDescriptionFilters
                                }
                                selectedEmployeeFilters={
                                    selectedFilters.employees
                                }
                                selectedCustomerFilters={
                                    selectedFilters.customers
                                }
                                selectedShipFromFilters={
                                    selectedFilters.shipFrom
                                }
                                selectedLocationFilters={
                                    selectedFilters.locations
                                }
                                selectedProductDescriptionFilters={
                                    selectedFilters.productDescriptions
                                }
                                selectedStatusFilters={selectedFilters.statuses}
                                selectedPoFilter={selectedFilters.poDate}
                                selectedRequestedDeliveryFilter={
                                    selectedFilters.deliveryDate
                                }
                                shipFromFilters={Filters.shipFromFilters}
                                deliveryLocationFilters={
                                    Filters.deliveryLocationFilters
                                }
                                orderStatusFilters={Filters.statusFilters}
                            />
                        </div>
                        {GetOrdersStatus === "loaded" && (
                            <>
                                <OrdersDisplay
                                    currentPage={PageNumber}
                                    orderCount={OrderHistoryItemsCount}
                                    rowsToDisplay={
                                        RowsToDisplay as DisplayAmount
                                    }
                                    orders={OrderHistoryItemsList}
                                    goToPage={onPageChange}
                                    onDisplayPageChange={onRowsToDisplayChange}
                                    onSelectSortValue={onSelectSortValue}
                                    currentSortValue={SortValue}
                                />
                            </>
                        )}
                    </Card>
                </LoadingDisplay>
            </div>
        </div>
    );
}
