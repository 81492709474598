import {
    DataGridColumnDefinition,
    FilteringDataGridColumnDefinition,
} from "@cpchem/covalence-ui";

const baseColumns: (
    | DataGridColumnDefinition
    | FilteringDataGridColumnDefinition
)[] = [
    {
        Header: "Confirmed Qty",
        accessor: "confirmedQuantity",
        id: "confirmedQuantity",
        alignment: "left",
    },
    {
        Header: "Est. Shipped Date",
        accessor: "date",
        id: "date",
        alignment: "left",
    },
];

export function GetScheduleLineColumns() {
    const columnsDefinition: (
        | DataGridColumnDefinition
        | FilteringDataGridColumnDefinition
    )[] = [...baseColumns];

    return columnsDefinition;
}
