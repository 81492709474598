import React, { useState } from "react";
import {
    Button,
    DataGrid,
    DataGridProps,
    DataGridRow,
    Divider,
    Modal,
    ModalContent,
    ModalFooter,
} from "@cpchem/covalence-ui";
import { LineItems } from "@models";
import { RenderPill } from "@utilities/status-helper";
import { useParams, NavigateFunction } from "react-router-dom";
import { GetScheduleLineColumns } from "../../content/schedule-line-columns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { ItemDetailsElements } from "../../../../../elements";
import "./styles.scss";
import { LineItemsRow } from "./elements";

function GetBaseColumnsRow(
    row: DataGridRow,
    navigate: NavigateFunction,
): JSX.Element {
    const {
        items,
        status,
        itemQuantity,
        totalConfirmedQuantity,
        estimatedShipmentDate,
        shipFrom,
        shipments,
        scheduleLines,
        totalLeftQuantity,
    } = row.values as LineItems;
    const { orderNumber } = useParams();
    function ShipmentTrackingClick(itemNumber: string) {
        navigate(`/orders/${orderNumber}/shipments/${itemNumber}`);
    }

    const gridProps: DataGridProps = {
        data: scheduleLines,
        columns: GetScheduleLineColumns(),
        isSortable: true,
    };

    const [modalOpen, setModalOpen] = useState(false);

    let showRemainingUnconfirmed = true;

    const splicedUnconfirmed = totalLeftQuantity.charAt(0);

    if (splicedUnconfirmed === "0") {
        showRemainingUnconfirmed = false;
    }

    function lineModalContent() {
        return (
            <div>
                <Divider />
                <DataGrid
                    {...gridProps}
                    testId={"line-detail-modal-popup-testid"}
                />
                {showRemainingUnconfirmed && (
                    <div className="line-detail-info">
                        <FontAwesomeIcon icon={faCircleInfo} />
                        <span>
                            Remaining Unconfirmed Qty: {totalLeftQuantity}
                        </span>
                    </div>
                )}
            </div>
        );
    }

    return (
        <>
            <td className="left items">
                <div>
                    <span className="bold">{`Item # ${items[0]}`}</span>
                    <br />
                    <span className="product">{items[1]}</span>
                    <br />
                    <span className="title">{`CPChem Mat. #: ${items[2]} | Customer Mat. #: ${items[3]}`}</span>
                </div>
            </td>
            <td className="left status">{RenderPill(status)}</td>
            <td className="right item-qty">
                <span className="item-quantity">{`${itemQuantity}`}</span>
            </td>
            <td className="right total-qty">
                {scheduleLines.length >= 2 ? (
                    <Button
                        className="line-details-modal-button"
                        variant="text"
                        size="large"
                        color="blue"
                        text={totalConfirmedQuantity}
                        onClick={() => setModalOpen(true)}
                        testId={`${LineItemsRow.LineItemsModalButton}-${items[0]}`}
                    />
                ) : (
                    <span>{`${totalConfirmedQuantity}`}</span>
                )}
            </td>
            <td className="left ship-date">
                {scheduleLines.length >= 2 ? (
                    <Button
                        className="line-details-modal-button"
                        variant="text"
                        size="large"
                        color="blue"
                        text={estimatedShipmentDate[0]}
                        onClick={() => setModalOpen(true)}
                    />
                ) : (
                    <span>{estimatedShipmentDate[0]}</span>
                )}

                <br />
                <span className="title">
                    {Number.parseInt(estimatedShipmentDate[1], 10) > 1
                        ? "Est. First Ship Date"
                        : ""}
                </span>
                <Modal
                    className="line-details-modal"
                    isOpen={modalOpen}
                    onRequestClose={() => setModalOpen(false)}
                    title={`Total Confirmed Qty: ${itemQuantity}`}
                >
                    <ModalContent>{lineModalContent()}</ModalContent>
                    <Divider className="line-details-modal-divider" />
                    <ModalFooter>
                        *Est. Ship Dates are tentative and subject to change.
                    </ModalFooter>
                </Modal>
            </td>
            <td className="left ship-from">
                <div>
                    <span className="bold-ship-from">{shipFrom[0]}</span>
                    <br />
                    <span className="title">{`${shipFrom[1]}, ${shipFrom[2]}`}</span>
                </div>
            </td>
            <td className="right shipments">
                <span>{shipments}</span>
            </td>
            <td className="center actions">
                <Button
                    className="shipment-details-button"
                    color="primary"
                    text="Shipment Details"
                    isDisabled={shipments === 0}
                    testId={`${ItemDetailsElements.ShipmentDetailsButton}-${items[0]}`}
                    onClick={() => ShipmentTrackingClick(items[0])}
                />
            </td>
        </>
    );
}

export function ResultRowFull(
    row: DataGridRow,
    navigate: NavigateFunction,
): JSX.Element {
    const { items } = row.values as LineItems;

    return (
        <tr key={`${items[0]}-${items[1]}`}>
            {GetBaseColumnsRow(row, navigate)}
        </tr>
    );
}
