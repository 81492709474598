import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@hooks/useTypedSelector";
import { selectCheckedUserProductLines } from "@stores/product-lines";
import { Card, DisplayAmount, Divider } from "@cpchem/covalence-ui";

import {
    removeWhiteSpace,
    sanitizeString,
} from "../../utilities/string-utilities";
import { APISearchQueryOptions } from "@models/orders";
import {
    selectOrderHistoryItemsStatus,
    selectOrderHistoryItems,
    selectOrderHistoryItemsCount,
    selectPageNumber,
    selectRowsToDisplay,
    pageUpdate,
    pageSearchMode,
    setSearchTerm,
    setPageNumber,
    setRowsToDisplay,
    selectOrderHistoryMode,
    getOrderHistorySearchResults,
    selectSortValue,
    setSortValue,
} from "@stores/order-history";
import { TransformOrderHistoryRawData } from "@utilities/data/orders-mapping";
import { NoSearchResults } from "./empty-search/empty-search";
import "../data-grid-styles.scss";

import { OrdersDisplay } from "@components/display";
import { useLocation } from "react-router-dom";
import { getNextSortValues, sortValue } from "@utilities/pages/order-history";
import { SearchCountResultTitle } from "@components/display/orders/content/search-count-result-title";
import { SearchElements } from "./elements";
import { ecomTrackEvent } from "@utilities/log/ecom-track-event";
import { createPageViewEvent } from "@cpchem/logging";
import { LOADING_SEARCH_ORDERS } from "@utilities/log/log-events";
import { LoadingDisplay } from "@components/loading";

export function SearchPage(): JSX.Element {
    const dispatch = useAppDispatch();

    const ProductLinesArray = useAppSelector(selectCheckedUserProductLines);
    const GetOrdersStatus = useAppSelector(selectOrderHistoryItemsStatus);
    const GetPageMode = useAppSelector(selectOrderHistoryMode);
    const PageNumber = useAppSelector(selectPageNumber);
    const RowsToDisplay = useAppSelector(selectRowsToDisplay);
    const SortValue = useAppSelector(selectSortValue);

    const { search } = useLocation();
    const searchTerm = new URLSearchParams(search).get("q");

    const sanitizedSearchTerm = sanitizeString(searchTerm);

    const SearchTermNoSpaces = removeWhiteSpace(sanitizedSearchTerm);

    // orders
    const OrderHistoryItemsListRaw = useAppSelector(selectOrderHistoryItems);
    const OrderHistoryItemsList = TransformOrderHistoryRawData(
        OrderHistoryItemsListRaw,
    );
    const OrderHistoryItemsCount = useAppSelector(selectOrderHistoryItemsCount);

    const [filterBarHeight, setFilterBarHeight] = useState(
        getElementHeight("grid-filter-bar", 48),
    );

    function getElementHeight(id: string, defaultHeight: number) {
        const element = document.getElementById(id);
        if (element) {
            const elementHeight = element.getBoundingClientRect().height;

            return Math.floor(elementHeight - 1);
        }
        return defaultHeight;
    }

    const querySearchResults = () => {
        const pageOptions: APISearchQueryOptions = {
            pageSize: RowsToDisplay,
            pageLocation: PageNumber,
            userProductLines: ProductLinesArray,
            searchValue: SearchTermNoSpaces || "",
            sortValue: SortValue,
        };
        ecomTrackEvent({
            type: LOADING_SEARCH_ORDERS,
            contents: JSON.stringify({ ...pageOptions }),
        });

        setFilterBarHeight(getElementHeight("grid-filter-bar", 48));
        dispatch(getOrderHistorySearchResults(pageOptions));
    };

    useEffect(() => {
        if (
            GetOrdersStatus === "partial" ||
            GetOrdersStatus === "reset" ||
            GetOrdersStatus === "update"
        ) {
            querySearchResults();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GetOrdersStatus]);

    useEffect(() => {
        querySearchResults();
        dispatch(pageSearchMode());
        dispatch(setSearchTerm(sanitizedSearchTerm));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        ecomTrackEvent(createPageViewEvent("Loading Search Orders"));
    }, []);

    const onPageChange = (value: number) => {
        if (PageNumber !== value) {
            dispatch(setPageNumber(value));
            dispatch(pageUpdate());
        }
    };

    const onRowsToDisplayChange = (value: string) => {
        if (RowsToDisplay !== (value as unknown as DisplayAmount)) {
            dispatch(setRowsToDisplay(Number(value)));
            dispatch(pageUpdate());
        }
    };

    const onSelectSortValue = (newSort: sortValue, currentSort: sortValue) => {
        const newSortValue = getNextSortValues(currentSort, newSort);
        dispatch(setSortValue(newSortValue));
        dispatch(pageUpdate());
    };

    if (GetPageMode) document.title = `Search for ${searchTerm}`;

    const style: { [id: string]: string } = {
        "--top-filter-bar": `${filterBarHeight}px`,
    };

    const searchTitle = `Search for "${searchTerm}"`;

    return (
        <div className="search-page" style={style}>
            <div className="search-page-header">
                <h1
                    className="search-page-title"
                    data-testid={SearchElements.SearchHeader}
                >
                    {searchTitle}
                </h1>
            </div>
            <Divider />
            <div className="search-page-content">
                <LoadingDisplay
                    showLoading={GetOrdersStatus === "loading"}
                    loadingMessage="This page is taking longer than usual to load. Please wait while your content is retrieved."
                    className="orders-grid"
                >
                    <Card className="search-page-card">
                        <div className="grid-filter-bar" id="grid-filter-bar">
                            <div className="grid-drop-down grid-bar-column">
                                <div className="grid-message">
                                    {OrderHistoryItemsCount !== 0 && (
                                        <SearchCountResultTitle
                                            appStatus={GetOrdersStatus}
                                            itemCount={OrderHistoryItemsCount}
                                            searchTerm={searchTerm}
                                            testId={
                                                SearchElements.TotalOrdersFound
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {GetOrdersStatus === "loaded" && (
                            <>
                                {OrderHistoryItemsCount !== 0 ? (
                                    <OrdersDisplay
                                        currentPage={PageNumber}
                                        orderCount={OrderHistoryItemsCount}
                                        rowsToDisplay={
                                            RowsToDisplay as DisplayAmount
                                        }
                                        orders={OrderHistoryItemsList}
                                        goToPage={onPageChange}
                                        onDisplayPageChange={
                                            onRowsToDisplayChange
                                        }
                                        onSelectSortValue={onSelectSortValue}
                                        currentSortValue={SortValue}
                                    />
                                ) : (
                                    <>
                                        {NoSearchResults(
                                            searchTerm,
                                            SearchElements.EmptySearchResults,
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Card>
                </LoadingDisplay>
            </div>
        </div>
    );
}
