import { Toast, ToastId, ToastMessage, ToastOptions } from "./model";
import { createToast, removeToastById, requestDismissToast } from "./utils";

interface ToastEngineProps {
    onUpdateToasts: (toasts: Toast[]) => void;
}

interface ToastTimeouts {
    [key: string]: ReturnType<typeof setTimeout>;
}

export class ToastEngine {
    private readonly onUpdateToasts: (toasts: Toast[]) => void;

    private _toasts: Toast[] = [];

    private _timeouts: ToastTimeouts = {};

    private get toasts() {
        return this._toasts;
    }

    private set toasts(toasts: Toast[]) {
        this._toasts = toasts;
        this.onUpdateToasts(toasts);
    }

    private get timeouts() {
        return this._timeouts;
    }

    private set timeouts(timeouts: ToastTimeouts) {
        this._timeouts = timeouts;
    }

    constructor({ onUpdateToasts }: ToastEngineProps) {
        this.onUpdateToasts = onUpdateToasts;
    }

    public createToast(message: ToastMessage, options?: ToastOptions): void {
        const newToast = createToast(message, options);
        this.createToastTimeout(newToast);
        this.toasts = [...this.toasts, newToast];
    }

    public removeToast(id: ToastId) {
        this.clearToastTimeout(id);
        this.toasts = removeToastById(this.toasts, id);
    }

    public onRequestDimissToast(id: ToastId) {
        this.toasts = requestDismissToast(this.toasts, id);
    }

    private createToastTimeout(toast: Toast) {
        this.timeouts[toast.id] = setTimeout(
            () => this.onRequestDimissToast(toast.id),
            toast.autoDismissDelay,
        );
    }

    private clearToastTimeout(id: ToastId) {
        const timeoutToRemove = this.timeouts[id];
        clearTimeout(timeoutToRemove);
    }

    public removeAllToasts() {
        this.toasts = [];
    }

    public clearTimeouts() {
        const timeoutKeys = Object.keys(this.timeouts);
        timeoutKeys.forEach((key) => clearTimeout(this.timeouts[key]));
        this.timeouts = {};
    }
}
