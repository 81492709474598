import {
    ShipmentTracking,
    ShipmentTrackingHistoryItem,
    ShipmentTrackingHistoryItemsRaw,
    ShipmentTrackingRaw,
} from "@models";
import { ConvertDateTimeToShortDateTimeWithTimeZone } from "@utilities/dates/date-format-helper";

export function TransformShipmentTrackingDetailsRaw(
    rawContent: ShipmentTrackingRaw | null,
): ShipmentTracking | null {
    const contentConverted: ShipmentTracking | null =
        rawContent !== null
            ? {
                  actualDelivery: `${ConvertDateTimeToShortDateTimeWithTimeZone(
                      rawContent.actualArrivalDate.timestampUtc,
                      rawContent.actualArrivalDate.timezone,
                      "date-short",
                  )} ${ConvertDateTimeToShortDateTimeWithTimeZone(
                      rawContent.actualArrivalDate.timestampUtc,
                      rawContent.actualArrivalDate.timezone,
                      "time-short",
                  )}`,
                  estimatedDelivery: `${ConvertDateTimeToShortDateTimeWithTimeZone(
                      rawContent.eta.timestampUtc,
                      rawContent.eta.timezone,
                      "date-short",
                  )} ${ConvertDateTimeToShortDateTimeWithTimeZone(
                      rawContent.eta.timestampUtc,
                      rawContent.eta.timezone,
                      "time-short",
                  )}`,
                  destination: {
                      city: titleCase(rawContent.destination.city),
                      state: rawContent.destination.state,
                  },
                  carrier: rawContent.carrierName,
                  trackingNumber: rawContent.trackingNumber,
                  status: rawContent.statusOfShipment,
                  latestLocation: {
                      city: rawContent.latestLocation
                          ? titleCase(rawContent.latestLocation.city)
                          : "",
                      state: rawContent.latestLocation
                          ? rawContent.latestLocation.state
                          : "",
                  },
                  latestTimeStamp: {
                      time: rawContent.latestLocationTimestamp
                          ? ConvertDateTimeToShortDateTimeWithTimeZone(
                                rawContent.latestLocationTimestamp.timestampUtc,
                                rawContent.latestLocationTimestamp.timezone,
                                "time-short",
                            )
                          : "",
                      date: rawContent.latestLocationTimestamp
                          ? ConvertDateTimeToShortDateTimeWithTimeZone(
                                rawContent.latestLocationTimestamp.timestampUtc,
                                rawContent.latestLocationTimestamp.timezone,
                                "date-short",
                            )
                          : "",
                  },
                  trackingUrl: rawContent.trackingUrl,
              }
            : null;
    return contentConverted;
}

export function TransformShipmentTrackingHistoryRaw(
    shippingHistoryItems: ShipmentTrackingHistoryItemsRaw | null,
): ShipmentTrackingHistoryItem[] {
    const convertedHistoryItems: ShipmentTrackingHistoryItem[] = [];
    if (shippingHistoryItems !== null)
        shippingHistoryItems.locations.forEach((item) => {
            convertedHistoryItems.push({
                date: ConvertDateTimeToShortDateTimeWithTimeZone(
                    item.timestamp.timestampUtc,
                    item.timestamp.timezone,
                    "date-short",
                ),
                time: ConvertDateTimeToShortDateTimeWithTimeZone(
                    item.timestamp.timestampUtc,
                    item.timestamp.timezone,
                    "time-short",
                ),
                location: `${titleCase(item.location.city)}, ${
                    item.location.state
                }`,
            });
        });
    return convertedHistoryItems;
}

function titleCase(textToFormat: string): string {
    if (textToFormat !== undefined && textToFormat !== null) {
        const splitStr = textToFormat.toLowerCase().split(" ");
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
    }

    return "";
}
