export function buildOrderLineItemQuery(
    fieldName: string,
    items: string[],
): string {
    if (items.length === 0) {
        return "";
    }
    const filterValues: string[] = [];
    items.forEach((item) => {
        filterValues.push(`'${item.replaceAll("'", "''")}'`);
    });

    let filterString = ` LineItems/any(o: o/${fieldName} in (`;
    filterString += filterValues.join(",");
    filterString += ")) and  ";

    return encodeURIComponent(filterString);
}
