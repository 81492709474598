import React from "react";
import { FilterValue } from "../../types";
import "./styles.scss";

export interface RadioButtonProps {
    /** Class name applied to the root element. */
    className?: string;
    /** The label element at the end the radio. */
    label: string;
    /** Assigns the proper group name for the radio button */
    group?: string;
    /** Assigns Value to the label */
    value: FilterValue;
    /** Used to give an id to the radio button */
    id?: string;
    /** Used to determine if a radio button is checked */
    isChecked?: boolean;
    /** Determines if the radio Button is disabled */
    isDisabled?: boolean;
    /** Renders Children for */
    children?: React.ReactNode;
    /** onChange for setting the value */
    onChange: () => void;
    /** Used for testing the component */
    testId?: string;
}

export function RadioButton({
    className,
    label,
    group,
    id,
    isChecked,
    isDisabled,
    children,
    value,
    onChange,
    testId,
}: RadioButtonProps) {
    const cnParts = ["radio-button"];
    if (className) {
        cnParts.push(className);
    }
    if (isDisabled) {
        cnParts.push(`is-disabled`);
    }
    if (isChecked) {
        cnParts.push(`is-checked`);
    }

    const inputTestId = testId ? `${testId}-input` : undefined;
    const checkedTestId = isChecked ? `${testId}-checked-input` : undefined;

    const cn = cnParts.join(" ");
    return (
        <div className={cn} data-testid={testId}>
            <label
                htmlFor={id}
                className="radio-label"
                aria-disabled={isDisabled}
            >
                <input
                    type="radio"
                    name={group}
                    id={id}
                    className="radio-input"
                    disabled={isDisabled}
                    checked={isChecked}
                    value={value as string}
                    data-testid={checkedTestId || inputTestId}
                    onChange={onChange}
                />
                {label}
                {children}
            </label>
        </div>
    );
}
