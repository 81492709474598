import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faTags,
    faFileCheck,
    faFileInvoiceDollar,
    faFilePdf,
    faList,
    faFileInvoice,
    faStamp,
    faFile,
    faBadgeCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { DocumentDetail } from "@models";
import config from "@config";
import { MenuItem } from "@cpchem/covalence-ui";

const iconMap: Record<string, IconProp> = {
    ZQT: faTags,
    ZA4O: faTags,
    ZORD: faFileCheck,
    ZOA4: faFileCheck,
    ZCPS: faFilePdf,
    ZLD0: faList,
    ZBL1: faFile,
    ZBP1: faFile,
    ZCAN: faFileInvoice,
    ZQCP: faStamp,
    ZQCG: faStamp,
    ZQBP: faStamp,
    ZQPI: faStamp,
    ZCRT: faBadgeCheck,
    ZD01: faFileInvoiceDollar,
    ZS01: faFileInvoiceDollar,
    ZDAR: faFileInvoiceDollar,
    ZIA4: faFileInvoiceDollar,
    ZA4I: faFileInvoiceDollar,
};

export function getDocumentIcon(code: string) {
    return (
        <FontAwesomeIcon className="icon" icon={iconMap[code] ?? faFilePdf} />
    );
}

export const buildDocumentUrl = (doc: DocumentDetail) => {
    return `${config.api.documents.url}?objectKey=${doc.objectKey}&messageType=${doc.messageType}&application=${doc.application}`;
};

export const buildDocumentFileName = (doc: DocumentDetail, id: string) => {
    if (doc.fileName === undefined || doc.fileName === "") {
        return `${doc.documentDescription.split(" ").join("_")}_${id}.pdf`;
    }
    return `${doc.fileName}.pdf`;
};

export const buildDocumentList = (
    documents: DocumentDetail[],
    isDisabled: boolean,
    onDocumentClick: (doc: DocumentDetail) => void,
    testid?: string,
): MenuItem[] => {
    if (documents.length === 0) {
        return [
            {
                key: "empty",
                content: "No documents available",
            },
        ];
    }

    const menuItems: MenuItem[] = documents.map((doc) =>
        buildMenuItem(doc, isDisabled, onDocumentClick, testid),
    );

    return menuItems;
};

const buildMenuItem = (
    doc: DocumentDetail,
    isDisabled: boolean,
    onDocumentClick: (doc: DocumentDetail) => void,
    testid?: string,
): MenuItem => {
    const url = buildDocumentUrl(doc);

    return {
        key: url,
        className: isDisabled ? "disabled" : "",
        content: doc.documentDescription,
        leftIcon: getDocumentIcon(doc.messageType),
        persistOpenMenu: true,
        onClick: () => onDocumentClick(doc),
        testId: `${testid}-${doc.objectKey}`,
    };
};
